import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
// import { constants } from '../../../pages/login/store';
import { GetParams } from '../../../components/params/params';
import { ConfirmModal } from '../../../components/confirmModal/confirmModal';
import { Modal } from 'antd-mobile';
import Api from '../../../utils/Api';
import moment from 'moment';
const getApi = Api.getInstance();

class SharedPosition extends Component {

    constructor(props) {
        super(props);
        this.state = {
            id: 0,
            fkUserId: "",
            recommendUser: {},
            maxPositionList: [],
            sharedPositionInfo: {},
            loading: false,
            isRefresh: true,
        }
    }

    componentDidMount() {
        let id = GetParams(this.props.history.location.search, "id");
        let fkUserId = GetParams(this.props.history.location.search, "fkUserId");
        if (id && fkUserId) {
            this.setState({
                id: id,
                fkUserId: fkUserId,
            }, () => {
                this.requestForSharedPositionInfo();
                getApi.doPublishViewLog({
                    token: this.props.userInfo.token,
                    userId: this.props.userInfo.userId,
                    action: 0,
                    actionName: "浏览页面",
                    memo: "sharedPosition",
                    positionId:id,
                    clientType:7
                }).then((res) => {
                }).finally((res) => {
                });
            })
        }
    }

    render() {
        let { sharedPositionInfo, loading } = this.state;
        return (
            <div className="bg-fff">
                {loading &&
                    <div>
                        <div className="share-position wrap">
                            <div className="banner">
                                {/* <LazyLoad height={375}> */}
                                < img src={'https://cdn.huizhuanvip.com/jianzhi/app/images/share_banner1.png'} className="img" alt=""/>
                                {/* </LazyLoad> */}
                            </div>
                            <div className="top">
                                <div className="head-item">
                                    <div className="item">
                                        <div className="h1">{sharedPositionInfo.positionName}</div>
                                        {/* <div className="price">{sharedPositionInfo.salary}元/{sharedPositionInfo.salaryPerName} {sharedPositionInfo.salaryPaidTypeName}</div> */}
                                    </div>
                                    <div className="item">【薪资】{sharedPositionInfo.salary}元/{sharedPositionInfo.salaryPerName} {sharedPositionInfo.salaryPaidTypeName}</div>
                                    <div className="item">【时间】{sharedPositionInfo.workDateStart || '现在'} 至 {sharedPositionInfo.workDateEnd|| '长期'}</div>
                                    <div className="item">【地点】{sharedPositionInfo.workDetailAddress}</div>
                                    <div className="item">【内容】{sharedPositionInfo.introduction}</div>
                                    {/* <div className="btn" onClick={() => this.props.history.push(
                                        '/positionDetails?id=' + id + '&recommendId=' + sharedPositionInfo.fkUserId)}>查看职位详情</div> */}
                                </div>
                            </div>

                            <div className="share-user">
                                <Fragment>
                                    <div className="share-txt">你的好友“{sharedPositionInfo.userName || sharedPositionInfo.mobile}”给你分享了一份高薪兼职</div>
                                    {/* <div className="btns">
                                        <a className="btn" onClick={this.publishSignUp.bind(this)}>立即报名</a>
                                    </div> */}
                                </Fragment>
                            </div>

                            <div className="guanzhu">
                                长按下方二维码，进入官方小程序即可报名<br />······↓↓↓↓↓↓······
                                <div className="guanzhu-bg">
                                    <div className="erweima">
                                        {/* <img src={require('../../../assets/img/icon_erweima.jpg')} /> */}
                                        <img src={sharedPositionInfo.acode} alt=""/>
                                    </div>
                                </div>


                            </div>

                            <div className="job-list">
                                <div className="tit">更多高薪兼职</div>
                                {sharedPositionInfo.maxPositionList && sharedPositionInfo.maxPositionList.map((position, index) => {
                                    return <React.Fragment key={index}>
                                        <div className="item">
                                            <div className="t">{position.positionName}</div>
                                            <div className="txt">【薪资】{position.salary}元/{position.salaryPerName} {position.salaryPaidTypeName}</div>
                                            <div className="txt">【时间】{position.workDateStart?moment(position.workDateStart).format('YYYY-MM-DD'):'现在'} 至 {position.workDateEnd?moment(position.workDateEnd).format('YYYY-MM-DD'):'长期'} </div>
                                            <div className="txt">【地点】{position.workDetailAddress}</div>
                                            <div className="txt">【内容】{position.introduction}</div>
                                            {position.salaryWelfareName && <div className="txt">【福利】{position.salaryWelfareName}</div>}
                                        </div>
                                    </React.Fragment>
                                })
                                }
                            </div>
                        </div>
                    </div>
                }
            </div>
        )
    }
    requestForSharedPositionInfo() {
        let {userInfo,shareFromPosition, clientType} = this.props;
        let loading = document.getElementById('i-loading');
        loading.style.display = 'inline';
        getApi.getPositionSharedInfo({
            fkPositionId: this.state.id,
            recommend: this.state.fkUserId,
            token: userInfo.token,
            userId: userInfo.userId,
        }).then((res) => {
            if (res.success) {
                if (res.module.mySignUpId > 0) {
                    this.props.history.replace("/sharePosition?id="+this.state.id);
                    return;
                }
                res.module.acode = "https://merchant.level-job.com/api/share/getXchAcode.action?recommend="+this.state.fkUserId+"&fkPositionId="+this.state.id+"&clientType=8";
                this.setState({
                    sharedPositionInfo: res.module,
                    loading: true,
                });
                
                if (userInfo.mobile) {
                    let params = res.module;
                    params.userId = userInfo.userId;
                    params.title = shareFromPosition.title;
                    params.clientType = clientType;
                    document.addEventListener('WeixinJSBridgeReady', getApi.doSharePosition(params), false);
                } else {
                    let params = { userId: '',title:this.props.shareGongZhongHao.title, clientType: clientType }
                    document.addEventListener('WeixinJSBridgeReady', getApi.doShareWap(params), false);
                }
            }
        }).catch((res) => {
            console.log(res);
        }).finally((res) => {
            loading.style.display = 'none';
        });
    }
    publishSignUp() {
        let userInfo = this.props.userInfo;
        var mobile = userInfo.mobile;
        let { sharedPositionInfo } = this.state;
        if (!mobile) {
            this.props.history.push('login?userId=' + this.state.fkUserId);
            return;
        }
		// 是否确认报名？
		ConfirmModal(" ", "确定要报名该职位吗？", () => {
            if (sharedPositionInfo.margin > 0) {
                let params = {};
                params.margin = sharedPositionInfo.margin;
                params.id = sharedPositionInfo.id;
                this.props.setMarginParams(params);
                this.props.history.push('/payMargin');
                return;
            }
            this.setState({
                loadingState: true,
            }, () => {
                getApi.doPublishSignUp({
                    fkPositionId: this.state.id,
                    jobType: 0,
                    token: userInfo.token,
                    userId: userInfo.userId,
                    recommendId: this.state.fkUserId,
                }).then((res) => {
                    if (res.error) {
                        return;
                    }
                    Modal.alert('提示', '报名成功!再邀请一人，可获得职位加薪' + sharedPositionInfo.shareSalary + '元/' + sharedPositionInfo.salaryPerName + sharedPositionInfo.salaryPaidTypeName, [
                        { text: '偏偏不要', onPress: () => console.log('cancel') },
                        {
                            text: '去加薪', onPress: () => {
                                this.props.history.push('/sharePosition?id=' + this.state.id + '&recommendId=' + sharedPositionInfo.fkUserId);
                            }
                        }]);
                })
                    .catch((res) => {
                        console.log(res);
                    }).finally((res) => {
                        this.setState({
                            loadingState: false
                        });
                    });
            })
        });
    }
}

const mapState = (state) => ({
    userInfo: state.login.userInfo,
    shareFromPosition: state.login.shareFromPosition,
    shareGongZhongHao:state.login.shareGongZhongHao,
    clientType: state.login.clientType,
})

const mapDispatch = (dispatch) => ({
})

export default connect(mapState, mapDispatch)(SharedPosition);