import { Modal, WingBlank} from 'antd-mobile';
import React, { Component } from 'react';
import { chkSearch } from '../../components/params/params';
import { connect } from 'react-redux';

class MyDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchName: this.props.searchName || ""
        };
    }

    componentDidMount() {
    }

    render() {
        let {searchName} = this.state;
        let { modal, dataList, chooseList, name, city, selectedId, location, searchNameList, hotArray, maxPrice, shareFlag } = this.props;
        return (
            <WingBlank>
                <Modal
                    popup
                    visible={modal}
                    onClose={this.props.onClose}
                    animationType="slide-up"
                    afterClose={this.props.refresh}
                >
                    {name === "workExpect" &&
                        <div className="bg-fff h100">
                            <div className="view word-expect">
                                <div className="scrollview">
                                    <div className="isChoosed">
                                        <div className="tags">
                                            {(chooseList && chooseList.length > 0) && chooseList.map((item, index) => {
                                                return (
                                                    <div className="chooseTag" key={index} onClick={() => this.props.click(item)}>{item.translate}</div>
                                                )
                                            })}
                                        </div>
                                        <div className="tagNum">{chooseList.length}/3</div>
                                    </div>
                                    <div className="scroll-wrap">
                                        <div className="areas-wrap">
                                            <div className="areas-box">
                                                <div className="areas-items">
                                                    <div className="items-list">
                                                        {(dataList && dataList.length > 0) && dataList.map((item, index) => {
                                                            return (
                                                                <div key={index} className={item.selected === 1 ? "itemC item item-hover" : "itemC item"}
                                                                    onClick={this.click.bind(this, item)}
                                                                >
                                                                    <div className="tagName">{item.translate}</div>
                                                                    <div className="ico"></div>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div >
                    }
                    {name === "location" &&
                        <div className="bg-fff h100">
                            <div className="view address">
                                <div className="scrollview">
                                    <div className="isChoosed">
                                        <div className="tags1">
                                            当前开放城市:
                                        </div>
                                        <div className="tagNum">{location}</div>
                                    </div>
                                    <div className="scroll-wrap">
                                        <div className="areas-wrap">
                                            <div className="areas-box first-nav">
                                                <div className="areas-items">
                                                    <div className="items-list">
                                                        {(dataList && dataList.length > 0) && dataList.map((item, index) => {
                                                            return (
                                                                <div key={index}>
                                                                    {item.parentId === 0 &&
                                                                        <div className="itemC item item-hover" onClick={this.jumpToIndustry.bind(this, item)}>
                                                                            <div className="tagName">{item.translate}</div>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="areas-box">
                                                <div className="areas-items">
                                                    <div className="items-list">
                                                        {(dataList && dataList.length > 0) && dataList.map((item, index) => {
                                                            return (
                                                                <div key={index}>
                                                                    {item.parentId === selectedId &&
                                                                        <div className={item.id === city ? "itemC item item-hover" : "itemC item"}
                                                                            onClick={this.changeLocation.bind(this, item)}
                                                                        >
                                                                            <div className="tagName">{item.translate}</div>
                                                                            <div className="ico"></div>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    {name === "search" &&
                        <div className="bg-fff">

                            <div className='search'>
                                <div className='search-ipt'>
                                    <div className='ipt'><input className='sousuo' placeholder="请输入职位名称进行搜索" value={searchName || ""} onChange={(e) => { this.setState({ searchName: chkSearch(e.target.value) }) }}></input></div>
                                    <div className='sure' onClick={this.sure.bind(this)}>确定</div>
                                    <div className='cancel' onClick={this.cancel.bind(this)}>取消</div>
                                </div>

                                <div className='search-his'>
                                    <div className='tittle'>历史搜索</div>
                                    {(searchNameList && searchNameList.length > 0) &&
                                        <div className='items'>
                                            {searchNameList.map((item, index) => {
                                                return (
                                                    <div className='item' key={index} onClick={this.clickSearch.bind(this, item)}>{item}</div>
                                                )
                                            })}
                                        </div>
                                    }
                                </div>

                                <div className='search-hot'>
                                    <div className='tittle'>热门搜索</div>
                                    <div className='items'>
                                        {(hotArray && hotArray.length > 0) && hotArray.map((item, index) => {
                                            return (
                                                <div className='item' key={index} onClick={this.clickSearch.bind(this, item)}>{item}</div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    {name === "signUp" &&
                        <div className="bg-pop">
                            <div className='pop'>
                                <div className="pop-img" onClick={this.cancel.bind(this)}><img src={'https://cdn.huizhuanvip.com/jianzhi/app/images/icon_tc_close.png'} className="" alt=""/></div>
                                <div className='pop-ipt'>{shareFlag === 1 ? '将职位分享给好友最高可赚' + maxPrice + '元' : '稍后客服将与您确认报名信息请保持联系畅通哟~'}</div>
                                {/* <div className='pop-ipt'>{shareFlag !== 1 ? '将职位分享给好友最高可赚' + maxPrice + '元' : ''}</div> */}
                                <div className='pop-btn'>
                                    <div className='sure' onClick={() => {this.props.toSignUpList();}} >报名详情</div>
                                    {shareFlag !== 1 && <div className='cancel' onClick={this.cancel.bind(this)} >知道了</div>}
                                    {shareFlag === 1 && <div className='sure' onClick={() => { this.props.sure(); }}>去加薪</div>}
                                </div>
                            </div>
                        </div>
                    }
                </Modal>
            </WingBlank >
        );

    }

    click(value) {
        this.props.chooseOrCance(value);
    };
    jumpToIndustry(value) {
        this.setState({
            selectedId: value.id
        })
    };
    changeLocation(value) {
        this.props.chooseOrCance(value);
    };
    sure() {
        this.props.sure(this.state.searchName);
    };
    clickSearch(value) {
        this.props.sure(value);
    };
    cancel() {
        this.props.onClose();
    };
}
const mapState = (state) => ({
    city: state.login.city,
    location: state.login.location,
    searchNameList: state.login.searchNameList
})

const mapDispatch = (dispatch) => ({
});

export default connect(mapState, mapDispatch)(MyDialog);