import React from 'react';
import { ListView, PullToRefresh } from 'antd-mobile';

export default class MyListView extends React.Component {
    constructor(props) {
        super(props);

        const dataSource = new ListView.DataSource({
            rowHasChanged: (row1, row2) => row1 !== row2,
        });
        let height = document.documentElement.clientHeight * 3 / 4;
        if (props.fromPage === 'signUpList') {
            height = document.documentElement.clientHeight * 3.3047 / 4;
        }
        this.state = {
            data: [],
            dataSource,
            isLoading: true,
            height: height,
            page: 1,
            noMore: false,
            refreshing: false
        };
    }

    componentDidMount() {
        this.props.onRef(this);
        this.initLoading();

    }

    initLoading() {
        this.setState({
            page: 1,
            data: [],
            noMore: false,
            refreshing: false
        }, () => {
            this.props.loadData(1);
        });
    }
    setData(res) {
        let { data } = this.state;
        if (res && res.length > 0) {
            data = [...data, ...res];
        }
        this.setState({
            data: data,
            page: this.state.page + 1,
            dataSource: this.state.dataSource.cloneWithRows(data),
            isLoading: false,
            noMore: res.length < 30,
        });


    }
    onEndReached() {
        if (this.state.noMore) {
            return;
        }
        this.props.loadData(this.state.page);
    }
    onRefresh() {
        this.setState({
            refreshing: true
        })
        setTimeout(() => {
            this.initLoading();
        }, 1000);
    }

    render() {
        let { createListItem, createCarousel } = this.props;
        return (
            <ListView
                ref={el => this.lv = el}
                dataSource={this.state.dataSource}
                renderFooter={() => (<div style={{ padding: 10, textAlign: 'center' }}>
                    {this.state.isLoading ? 'Loading...' : '—这是我的底线—'}
                </div>)}
                renderHeader={createCarousel}
                renderRow={createListItem}
                style={{
                    height: this.state.height,
                }}
                pullToRefresh={
                    <PullToRefresh
                        direction={"down"}
                        refreshing={this.state.refreshing}
                        onRefresh={this.onRefresh.bind(this)}
                    />}
                onEndReached={this.onEndReached.bind(this)}
                pageSize={30}
                scrollRenderAheadDistance={500}
                onEndReachedThreshold={10}
            />
        );
    }
}
