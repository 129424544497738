// import * as constants from './constants';
import {DICTIONARYLIST } from './constants';
import Api from '../../../utils/Api';
const getApi = Api.getInstance;
export const doUpdateDictionaryList = () => {

    return async (dispatch) => {
        let newdictionaryList = [];
        let dataList = await getApi()
            .doUserDictionary({});
        if(dataList.success){
            dataList = dataList.module;
            if (dataList) {
                dataList.sort(function (a, b) {
                    return a.id - b.id;
                });
                for (let data of dataList) {
                    let arr = newdictionaryList[data.attribute];
                    if (!arr) {
                        arr = [];
                        newdictionaryList[data.attribute] = arr;
                    }
                    arr.push(data);
                }
                var timer = setInterval(() => {
                    dispatch({
                        type: DICTIONARYLIST,
                        payload: newdictionaryList
                    })
                    clearInterval(timer);
                }, 3000);
            }
        }
    }
}