import React, { Component } from 'react';
import { connect } from 'react-redux';
import { constants } from '../../pages/login/store';
import { GetParams } from '../../components/params/params';
import { Dictionary, dictionaryList } from '../../utils/Dictionary';
import { ConfirmModal } from '../../components/confirmModal/confirmModal';
import { mapNumberUtil } from '../../utils/Util';
import Navigator from '../../components/navigator/navigator';
import SinglePicker from '../../components/single-picker/singlePicker';
import FilterPicker from '../../components/filter-picker/filterPicker';
import MyListView from '../../components/list-view/myListView';
// import MyCarousel from '../../components/myCarousel/myCarousel';
import MyDialog from '../../components/myDialog/myDialog';
import Api from '../../utils/Api';
import wx from 'weixin-js-sdk';
const getApi = Api.getInstance();
const getDictionary = Dictionary.getInstance;

class Index extends Component {
	constructor(props) {
		super(props);
		this.state = {
			array: [
				{
					value: 0,
					label: '推荐排序'
				},
				{
					value: 1,
					label: '离我最近'
				},
				{
					value: 2,
					label: '工资最高'
				},
				{
					value: 3,
					label: '热度最高'
				}
			],
			orderBy: null,
			jobType: null,
			region: null,
			searchName: "",
			modal: false,
			search: false,
			selectedId: [],
			dataList: [{
				id: 891,
				parentId: 0,
				translate: '江苏省'
			},
			{
				id: 892,
				parentId: 891,
				translate: '南京市'
			},
			{
				id: 930,
				parentId: 891,
				translate: '苏州市'
			},
			],
			hotArray: ['服务员', '话务员', '传单派发', '打包分拣'],
			imgUrls: [
				'https://cdn.huizhuanvip.com/jianzhi/app/images/banner1.png',
				'https://cdn.huizhuanvip.com/jianzhi/app/images/banner2.png',
				'https://cdn.huizhuanvip.com/jianzhi/app/images/banner3.png',
			],
		}
	}


	render() {
		let { search, modal, selectedId, dataList, searchName, hotArray } = this.state;
		let { location, searchNameList } = this.props;
		return (
			<div className="bg-fff">
				<div className="homepage">
					<div className="top">
						<div className="location" onClick={this.getLocation.bind(this)} >
							<i className="location-img"></i>
							<div className="location-txt">{location}</div>
						</div>
						<div className="search">
							<input type="span" readOnly="readOnly" className="ipt" placeholder="请输入职位名称进行搜索" value={searchName} onClick={() => { this.setState({ search: true }) }} />
							{searchName &&
								<div className='clean' onClick={() => { this.setState({ searchName: "" }, () => { this.listView.initLoading(); }) }}>清空</div>
							}
							<i className="search-img"></i>
						</div>
					</div>
					<div className="scrollWrap">



						{/* <div className="fromindex">

							<MyCarousel maxRecommendList={imgUrls} tag={"index"} ></MyCarousel>

							<div className="filter">
								<div className="addFind">
									<div className="findPosition">找优职</div>
									<div className="line"></div>
								</div>
								<SinglePicker theme={"index"} attribute={"city"} placeholder={"工作地点"} translate={null} parentId={city} onChange={this.changeCity.bind(this)}></SinglePicker>
								<SinglePicker theme={"index"} attribute={"jobType"} placeholder={"职位类型"} translate={null} onChange={this.changeType.bind(this)}></SinglePicker>
								<FilterPicker theme={"index"} array={array} placeholder={"推荐排序"} translate={null} onChange={this.changeOrder.bind(this)}></FilterPicker>
							</div>
						</div> */}
						<MyDialog name={"search"} modal={search} searchName={searchName} onClose={() => { this.setState({ search: false }) }} sure={this.changeSearchName.bind(this)} dataList={searchNameList} hotArray={hotArray} refresh={() => { this.listView.initLoading(); }}></MyDialog>
						<MyDialog name={"location"} modal={modal} onClose={() => { this.setState({ modal: false }) }} selectedId={selectedId} dataList={dataList} chooseOrCance={this.chooseOrCancel.bind(this)} refresh={() => { this.listView.initLoading(); }}></MyDialog>
						<MyListView onRef={(ref) => { this.listView = ref }} loadData={(page) => this.requestForList(page)} createListItem={(value) => { return this._createListItem(value) }} createCarousel={() => { return this._createCarousel() }}></MyListView>
					</div>
					<Navigator tag={"index"}></Navigator>
				</div>
			</div>
		)
	}
	componentDidMount() {
		let recommendId = GetParams(this.props.history.location.search, "recommendId");
		if (recommendId) {
			this.setState({
				recommendId
			})
		}
		if (dictionaryList.length === 0) {
			getDictionary().setDictionaryList();
		}
		let list = JSON.parse(JSON.stringify(dictionaryList["city"])) || [];
		if (list.length === 0) {
			return;
		}
		let oldcity = this.props.city;
		let city = null;
		for (let item of list) {
			if (item.id === oldcity) {
				city = item;
			}
		}
		this.setState({
			selectedId: city.parentId
		});
		let params = { userId: this.props.userInfo.userId, title: this.props.shareGongZhongHao.title, clientType: this.props.clientType }
		document.addEventListener('WeixinJSBridgeReady', getApi.doShareWap(params), false);
		let { gpsLimit } = this.props;
		if (!gpsLimit || !gpsLimit.lat || !gpsLimit.lng) {
			this.applyGpsLimit();
			return;
		}
		getApi.doPublishViewLog({
			token: this.props.userInfo.token,
			userId: this.props.userInfo.userId,
			action: 0,
			actionName: "浏览页面",
			memo: "index",
			clientType:7
		}).then((res) => {
		}).finally((res) => {
		});
	};
	applyGpsLimit() {
		let { userInfo } = this.props;
		if (!userInfo.token || !userInfo.userId) {
			return;
		}
		ConfirmModal(" ", "需要获取您的地理位置，请确认授权", () => {
			let that = this;
			wx.getLocation({
				type: 'gcj02',
				success: function (res) {
					let lat = res.latitude;
					let lng = res.longitude;
					if (lat && lat !== 0 && lng && lng !== 0) {
						getApi.doModifyLongAndLat({
							latitude: res.latitude,
							longitude: res.longitude,
							token: userInfo.token,
							userId: userInfo.userId || 'null',
						}).then((res) => {
							if (res.success) {
								let gpsLimit = { lat: lat, lng: lng }
								that.props.setGpsLimit(gpsLimit);
								that.listView.initLoading();
							}
						}).catch((res) => {
						}).finally((res) => {
						});
					}
				}
			})
		});
	}

	getLocation() {
		// let { gpsLimit } = this.props;
		// if (!gpsLimit || !gpsLimit.lat || !gpsLimit.lng) {
		// 	this.applyGpsLimit();
		// 	return;
		// }
		this.setState({ modal: true })
	}

	async changeSearchName(value) {
		this.setState({
			searchName: value
		})
		let historyArray = this.props.searchNameList;
		let key = value;
		for (let i in historyArray) {
			let item = historyArray[i];
			if (item === key) {
				historyArray.splice(i, 1);
				break;
			}
		}
		if (historyArray.length === 20) {
			historyArray.splice(historyArray.length - 1, 1);
		}
		if (key.length > 0) {
			historyArray.splice(0, 0, key);
		}

		await this.props.setSearchName(historyArray);
		this.listView.initLoading();
		this.setState({ search: false })
	}
	changeType(value) {
		this.setState({
			jobType: value
		}, () => {
			this.listView.initLoading();
		})
	}
	changeCity(value) {
		this.setState({
			region: value
		}, () => {
			this.listView.initLoading();
		})
	}
	changeOrder(value) {
		let { gpsLimit } = this.props;
		if (value === 1 && (!gpsLimit || !gpsLimit.lat || !gpsLimit.lng)) {
			this.applyGpsLimit();
			return;
		}
		this.setState({
			orderBy: value
		}, () => {
			this.listView.initLoading();
		})
	}
	async chooseOrCancel(value) {
		var id = value.id;
		let list = this.state.dataList;
		let city = null;
		for (let item of list) {
			if (item.id === id) {
				city = item;
			}
		}
		await this.props.setCity(city);
		this.setState({ modal: false })
	}
	requestForList(page) {
		let { searchName, region, jobType, orderBy } = this.state;
		let { city, userInfo, gpsLimit } = this.props;
		getApi.doNewPositionList({
			page: page,
			workCity: city ? city : 892,
			positionOrMerchantName: searchName.length > 0 ? searchName : null,
			workRegion: region > 0 ? region : null,
			positionType: jobType > 0 ? jobType : null,
			orderBy: orderBy,
			token: userInfo.token,
			userId: userInfo.userId,
		}).then(res => {
			if (res.success) {
				let positionList = res.module;
				if (gpsLimit && (gpsLimit.lat > 0 || gpsLimit.lng > 0)) {
					for (let item of positionList) {
						item.distance = mapNumberUtil(gpsLimit.lat, gpsLimit.lng, item.workLatitude, item.workLongitude);
					}
				}
				this.listView.setData(positionList);
			}
		})
	};
	_createCarousel() {
		let { array, /* imgUrls */ } = this.state;
		let { city, userInfo } = this.props;
		return (
			<div className="fromindex">

				{/* banner轮播图，先用暑假工替换 <MyCarousel maxRecommendList={imgUrls} tag={"index"} verticalFlag={'0'}></MyCarousel> */}
				<div className='swiperC'>
					<div onClick={() => {
						if (!userInfo.mobile) {
                            this.props.history.push("/login");
                            return;
						}
						this.props.history.push('/moduleJob')}}>
						<img className="slide-img" src="https://cdn.huizhuanvip.com/jianzhi/app/images/banner-summer.png" alt=""></img>
					</div>
				</div>

				<div className="filter">
					<div className="addFind">
						<div className="findPosition">找优职</div>
						<div className="line"></div>
					</div>
					<SinglePicker theme={"index"} attribute={"city"} placeholder={"工作地点"} translate={null} parentId={city} onChange={this.changeCity.bind(this)}></SinglePicker>
					<SinglePicker theme={"index"} attribute={"jobType"} placeholder={"职位类型"} translate={null} onChange={this.changeType.bind(this)}></SinglePicker>
					<FilterPicker theme={"index"} array={array} placeholder={"推荐排序"} translate={null} onChange={this.changeOrder.bind(this)}></FilterPicker>
				</div>
			</div>
		)
	}
	_createListItem(value) {
		let { recommendId } = this.state;
		let { gpsLimit } = this.props;
		let url = '/positionDetails?id=' + value.id;
		if (recommendId) {
			url = url + '&recommendId=' + recommendId;
		}
		return (
			<div className="position-list" onClick={() => this.props.history.push(url,
			)} >
				<div className="lists">
					<div className="item">
						<div className="ml">
							<div className="ht">
								<div className="name">{value.positionName}</div>
								{value.margin > 0 &&
									<div className="label"><span className="label-ico bao-ico"></span></div>
								}
							</div>
							<div className="tags">
								<div className="tag">{value.workRegion}</div>
								<div className="tag">{value.qualificationName || '学历不限'}</div>
								<div className="tag">{value.positionTypeName || '其他兼职'}</div>
								{(value.distance >= 1 && gpsLimit.lat > 0 && gpsLimit.lng > 0) &&
									<div className="tag">{value.distance}km</div>
								}
								{(value.distance < 1 && value.distance > 0 && gpsLimit.lat > 0 && gpsLimit.lng > 0) &&
									<div className="tag">{value.distance * 1000}m</div>
								}
							</div>
						</div>
						<div className="mr">
							<div className="salary">
								<span className="money">{value.salary}</span><span className="unit">元/{value.salaryPer}</span>
							</div>
							<div className="date"><div className="txt">{value.salaryPaidType}</div></div>
						</div>
					</div>
				</div>
			</ div>
		)
	};
	componentWillMount() {
		let loading = document.getElementById('i-loading');
		if (loading) {
			loading.style.display = 'none'
		}
	}
}

const mapState = (state) => ({
	userInfo: state.login.userInfo,
	city: state.login.city,
	location: state.login.location,
	searchNameList: state.login.searchNameList,
	shareGongZhongHao: state.login.shareGongZhongHao,
	clientType: state.login.clientType,
	gpsLimit: state.login.gpsLimit
})

const mapDispatch = (dispatch) => ({
	setCity(value) {
		dispatch({
			type: constants.CITY,
			payload: value
		})
	},
	setSearchName(value) {
		dispatch({
			type: constants.SEARCHNAME,
			payload: value
		})
	},
	setGpsLimit(value) {
		dispatch({
			type: constants.GPSLIMIT,
			payload: value
		})
	}
});

export default connect(mapState, mapDispatch)(Index);