import { DatePicker } from 'antd-mobile';
import React, { Component } from 'react';
import moment from 'moment';
const nowTimeStamp = Date.now();

// Make sure that in `time` mode, the maxDate and minDate are within one day.
let minDate = new Date(nowTimeStamp - 1e7);
const maxDate = new Date(nowTimeStamp + 1e7);
// console.log(minDate, maxDate);
if (minDate.getDate() !== maxDate.getDate()) {
  // set the minDate to the 0 of maxDate
  minDate = new Date(maxDate.getFullYear(), maxDate.getMonth(), maxDate.getDate());
}

export default class MyDatePicker extends Component {
  state = {
    date: null,
    mode: this.props.mode || null,
    translate: this.props.translate,
    placeholder: this.props.placeholder || null,
    className: this.props.className || null,
    placeholderClass: this.props.placeholderClass || null,
    theme: this.props.theme || null,
  }
  componentDidMount() {
  }
  render() {
    const CustomChildren = props => (
      <div>
        {theme === "wallet" ?
          <div
            onClick={props.onClick}
            className={props.placeholderClass}
          >
            <div className="item">
              {props.extra === '请选择' ? (props.translate || props.placeholder) : moment(props.extra).format("YYYY-MM")}
              <i className="arrow"></i></div>
          </div>
          :
          <div
            onClick={props.onClick}
            className={props.placeholderClass}
          >
            {props.extra === '请选择' ? (props.translate || props.placeholder) : props.extra}
          </div>
        }

      </div>
    );
    let { theme, mode, translate, placeholder, placeholderClass } = this.state;

    return (
      <DatePicker
        minDate={new Date(1970,1,1,0,0)}
        mode={mode}
        title="选择日期"
        format={'YYYY-MM-DD'}
        value={this.state.date}
        extra={translate || placeholder}
        onChange={this.change.bind(this)}
        >
        {/* extra="Optional" */}
        <CustomChildren theme={theme} placeholder={placeholder} placeholderClass={placeholderClass} translate={translate} />

      </DatePicker>
    );
  };
  change(date) {
    this.setState({ date });
    this.props.onChange(moment(date).format("YYYY-MM-DD"));
  }
}