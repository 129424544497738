import React, { Component } from 'react';
import { connect } from 'react-redux';
// import { Modal } from 'antd-mobile';
// import { constants } from '../../../pages/login/store';
import { GetParams } from '../../../components/params/params';
// import { ConfirmModal, ShowAlert } from '../../../components/confirmModal/confirmModal';
import MyCarousel from '../../../components/myCarousel/myCarousel';
import Api from '../../../utils/Api';
const getApi = Api.getInstance();

class SharedModuleJob extends Component {

    constructor(props) {
        super(props);
        this.state = {
            recommendId: "",
            recommendUser: {},
            maxPositionList: [],
            sharedModuleJobInfo: {},
            loading: false,
            isRefresh: true,
        }
    }

    componentDidMount() {
        let recommendId = GetParams(this.props.history.location.search, "recommendId");
        console.log('来自：' + recommendId);
        if (recommendId) {
            this.setState({
                recommendId: recommendId,
                fkModuleId: 1,
            }, () => {
                this.requestForSharedModuleJobInfo();
                getApi.doPublishViewLog({
                    token: this.props.userInfo.token,
                    userId: this.props.userInfo.userId,
                    action: 0,
                    actionName: "浏览页面",
                    memo: "sharedApp",
                    recommendId:recommendId,
                    clientType:7
                }).then((res) => {
                }).finally((res) => {
                });
            })
        }
    }

    render() {
        let { sharedModuleJobInfo, loading, maxPositionList } = this.state;
        return (
            <div className="bg-ffc03a sjjz-page">
                {loading &&
                    <div className="sjjz">

                        <div className="user-items">
                            <div className="item">
                                <div className="pic">
                                    {sharedModuleJobInfo.photo ?
                                        <img className="avatar" src={sharedModuleJobInfo.photo} alt=""/> :
                                        <img className="avatar" src={require('../../../assets/img/icon_touxiang.png')} alt=""/>
                                    }
                                </div>
                                <div className="txt">#{sharedModuleJobInfo.userName || '你的好友'}#给你推荐暑期兼职啦！</div>
                            </div>
                        </div>

                        <div className="jobs-item">
                            <div className="slide">
                                <MyCarousel maxRecommendList={maxPositionList || []} tag={"sharedModuleJob"} verticalFlag={'0'} showDotsFlag={true}></MyCarousel> 
                            </div>
                        </div>

                        <div className="tips">
                            <div className="qr"><img src={sharedModuleJobInfo.acode} alt=""/></div>
                            <div className="txt">长按识别二维码<br/>进入官方小程序<br/>报名并查看更多暑期职位</div>
                        </div>

                    </div>
                }
            </div>
        )
    }
    requestForSharedModuleJobInfo() {
        let {userInfo/* ,shareModule, clientType */} = this.props;
        let loading = document.getElementById('i-loading');
        loading.style.display = 'inline';
        getApi.getSharedModuleJobInfo({
            page: 1,
            pageSize: 10,
            recommend: this.state.recommendId,
            fkModuleId: this.state.fkModuleId,
            token: userInfo.token,
            userId: userInfo.userId,
        }).then((res) => {
            if (res.success) {
                res.module.acode = getApi.doServiceUrl() + "/api/share/getXchAcodeForModuleJob.action?recommend="+this.state.recommendId+"&clientType=8";
                this.setState({
                    sharedModuleJobInfo: res.module,
                    maxPositionList: res.module.positionList,
                    loading: true,
                });
            }
        }).catch((res) => {
            console.log(res);
        }).finally((res) => {
            loading.style.display = 'none';
            // 禁止分享按钮
            if (typeof WeixinJSBridge == "undefined"){
                if( document.addEventListener ){
                    document.addEventListener('WeixinJSBridgeReady', this.onBridgeReady('wxParams'), false);
                }else if (document.attachEvent){
                    document.attachEvent('WeixinJSBridgeReady', this.onBridgeReady('wxParams')); 
                    document.attachEvent('onWeixinJSBridgeReady', this.onBridgeReady('wxParams'));
                }
            }else{
                this.onBridgeReady('wxParams');
            }
        });
    }
    onBridgeReady(){
        window.WeixinJSBridge.call('hideOptionMenu');
    }
}

const mapState = (state) => ({
    userInfo: state.login.userInfo,
    shareModule: state.login.shareModule,
    shareGongZhongHao:state.login.shareGongZhongHao,
    clientType: state.login.clientType,
})

const mapDispatch = (dispatch) => ({
})

export default connect(mapState, mapDispatch)(SharedModuleJob);