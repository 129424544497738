import React, { Component } from 'react';
import { connect } from 'react-redux';
import { constants } from './store';
import Api from '../../utils/Api';
import { Button, Toast } from 'antd-mobile';
import { BUTTON_SECOND } from '../../assets/style/common';
import Validate from '../../utils/Validate';
import { GetParams, ParseParams } from '../../components/params/params';
import LazyLoad from 'react-lazyload';
const getApi = Api.getInstance();

class Login extends Component {

	constructor(props) {
		super(props);
		this.state = {
			mobile: null,
			code: null,
			codeloadingState: false,
			time: null,
			loading: false
		}
		this.validate = this.initValidate();
	}

	render() {
		let { mobile, code, codeloadingState, time, loading } = this.state;
		return (
			<div className="bg-fff">
				<div className="signup">
					<div className="signup-logo">
						<LazyLoad>
							<img src={require('../../assets/img/WechatIMG20.png')} alt="" />
						</LazyLoad>
						<span>登录/注册</span>
					</div>
					<div className="sign-from">
						<div className="signup-number">
							<input type="tel" placeholder="请输入手机号" maxLength="11" value={mobile || ""} onChange={(e) => { this.setState({ mobile: e.target.value }) }} />
						</div>
						<div className="signup-account">
							<input type="tel" placeholder="请输入验证码" maxLength="6" value={code || ""} onChange={(e) => { this.setState({ code: e.target.value }) }} />
							{!codeloadingState ?
								<div className="signup-account-num" onClick={this.getCode.bind(this)}>获取验证码</div>
								:
								<div className="signup-account-num">{time}s后重新获取</div>
							}
						</div>
						<div className="signup-load">
							<Button loading={loading} style={BUTTON_SECOND} onClick={this.submit.bind(this)}>登录</Button>
						</div>
						<div className="signup-back" onClick={() => { this.props.history.replace("/index") }}>
							<button>返回</button>
						</div>
					</div>

					<div className="signup-tips">
						<p>没有账号的用户登录将自动创建账号</p>
						<p>我们不会在其他地方泄露您的号码，请放心填写</p>
						<p>为保障信息同步，用户账号需每隔6个月验证一次，如已更换手机号码，请填写最新手机号码进行验证</p>
						<p>如未收到验证码请联系客服，或致电025-58851251</p>
					</div>
					<div className="signup-foot"> 登录/注册即表示同意LEVEL实习<a className="loginyszc" onClick={() => { this.props.history.push("/privacyPolicy") }} href="javacript:void(0);">《用户协议和隐私政策》</a> </div>
				</div>
			</div>
		)
	};
	getCode() {
		var endTime = 60;
		if (!this.state.mobile || this.state.mobile.length !== 11) {
			Toast.fail("手机号格式错误", 1);
			return false;
		}
		this.setState({
			codeloadingState: true,
			time: endTime,
		}, () => {
			getApi.doGetVeryCode({
				mobile: this.state.mobile,
			}).then((res) => {
				if (res.success) {
					var timer = setInterval(() => {
						endTime--;
						this.setState({
							time: endTime,
						})
						if (endTime <= 0) {
							this.setState({
								codeloadingState: false
							})
							clearInterval(timer);
						};
					}, 1000);
				}
			}).catch(function (reason) {
				console.log(reason);
			});
		});
	}
	submit() {
		let res = GetParams(this.props.history.location.search, "userId");
		let mallParams = ParseParams(this.props.history.location.search);
		let { type } = mallParams;
		console.log(type);
		const params = {};
		params.token = this.props.userInfo && this.props.userInfo.token ? this.props.userInfo.token : null;
		params.userId = this.props.userInfo && this.props.userInfo.userId ? this.props.userInfo.userId : null;
		params.mobile = this.state.mobile;
		params.veryCode = this.state.code;
		params.uuid = 'subscription';
		params.clientType = this.props.clientType;
		params.recommend = (res && res !== "null") ? res : null;
		if (!this.validate.checkForm(params)) {
			const error = this.validate.errorList[0];
			Toast.fail(error.msg, 1);
			return false;
		}
		this.setState({
			loading: true,
		}, () => {
			// 如果有token则是绑定手机号，否则是手机号登录
			if (params.token) {
				// getApi.mobileLogin(
				getApi.bindMobile(
					params
				).then((res) => {
					if (res.success) {
						console.log(type);
						this.props.setUserInfo(res.module);
						if (type === "mall") {
							window.location = "https://mall.level-job.com/index.html#start?state=" + type.substr(5) + "&token=" + res.module.token + "&userId=" + res.module.userId;
							// window.location = "https://mall.level-job.com/index.html#start?state="+state.substr(5)+"&token="+res.module.token+"&userId="+res.module.userId;
							return;
						}
						else if (type === "poster" || type === "expresser") {
							// 寄快递
							window.location = "http://mall.huitao560.top/index.html#start?state=" + type + "&token=" +  res.module.token + "&userId=" +  res.module.userId;
						}
						else {
							this.props.history.goBack();
						}

					}
				}).catch((reason) => {
					console.log(reason);
				}).finally((res) => {
					this.setState({
						loading: false,
					});
				});
			} else {
				// getApi.doMobileLogin(
				getApi.mobileLogin(
					params
				).then((res) => {
					if (res.success) {
						this.props.setUserInfo(res.module);
						if (type === "mall") {
							window.location = "https://mall.level-job.com/index.html#start?state=" + type.substr(5) + "&token=" + res.module.token + "&userId=" + res.module.userId;
							// window.location = "https://mall.level-job.com/index.html#start?state="+state.substr(5)+"&token="+res.module.token+"&userId="+res.module.userId;
							return;
						}
						else if (type === "poster" || type === "expresser") {
							// 寄快递
							window.location = "http://mall.huitao560.top/index.html#start?state=" + type + "&token=" +  res.module.token + "&userId=" +  res.module.userId;
						}
						else {
							this.props.history.goBack();
						}
					}
				}).catch((reason) => {
					console.log(reason);
				}).finally((res) => {
					this.setState({
						loading: false,
					});
				});
			}
		});
	};
	initValidate() {
		const rules = {
			mobile: {
				required: true,
				tel: true,
			},
			veryCode: {
				required: true,
			}
		}

		const messages = {
			mobile: {
				required: '请输入手机号码',
				tel: '手机号需11位',
			},
			veryCode: {
				required: '请输入验证码'
			}
		}

		return new Validate(rules, messages);
	};

	componentWillMount() {
		let loading = document.getElementById('i-loading')
		if (loading) {
			loading.style.display = 'none'
		}
	}
}

const mapState = (state) => ({
	userInfo: state.login.userInfo,
	clientType: state.login.clientType,
})

const mapDispatch = (dispatch) => ({
	setUserInfo(value) {
		dispatch({
			type: constants.USERINFO,
			payload: value
		})
	}
})

export default connect(mapState, mapDispatch)(Login);