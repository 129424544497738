import React, { Component } from 'react';
import { connect } from 'react-redux';
import MyListView from '../../../components/list-view/myListView';
import Api from '../../../utils/Api';
import FilterPicker from '../../../components/filter-picker/filterPicker';
import MyDatePicker from '../../../components/date-picker/myDatePicker';
import moment from 'moment';
const getApi = Api.getInstance();

class MyWallet extends Component {

    constructor(props) {
        super(props);
        this.state = {
            monthAdd: 0,
            monthSubtract: 0,
            totalMoney: 0,
            loading: false,
            timeFrom: null,
            balance: 0,
            typeArray: [{
                value: null,
                label: '全部'
            },
            {
                value: 1,
                label: '支付工资'
            },
            {
                value: 2,
                label: '充值'
            },
            {
                value: 3,
                label: '提现'
            },
            {
                value: 4,
                label: '发放奖励'
            },
            {
                value: 5,
                label: '邀请收入'
            },
            {
                value: 7,
                label: '推广职位'
            }
            ],
        }
    }

    componentDidMount() {
		// 分享公众号
        let params = { userId: this.props.userInfo.userId,title:this.props.shareGongZhongHao.title, clientType: this.props.clientType }
        document.addEventListener('WeixinJSBridgeReady', getApi.doShareWap(params), false);
    }
    render() {
        let { monthAdd, monthSubtract, totalMoney, typeArray, balance } = this.state;
        return (
            <div className="bg-fff">

                <div className="my-wallet">
                    <div className="wallet-top">
                        <div className="top" onClick={() => { this.props.history.goBack(); }}>
                            <div className="goback"></div>
                            <div className="h1">我的钱包</div>
                        </div>
                        <div className="infos">
                            <div className="item">
                                <div className="mt">累计收入（元）</div>
                                <div className="mc">{totalMoney}</div>
                            </div>
                            <div className="item">
                                <div className="mt">余额（元）</div>
                                <div className="mc">{balance}</div>
                            </div>
                            <div className="btns"><a onClick={()=>{this.props.history.push("/takeCash")}} className="btn" href="javacript:void(0);">提现</a></div>
                        </div>
                    </div>
                    <div className="main-head">
                        <div className="title">账户明细</div>
                        <div className="items">
                            {/* <MyDatePicker theme={"wallet"} mode={"month"} placeholderClass={"picker"} placeholder={new Date().getFullYear()+"-"+(new Date().getMonth()+1)} translate={null} onChange={this.changeDate.bind(this)}></MyDatePicker> */}
                            <MyDatePicker theme={"wallet"} mode={"month"} placeholderClass={"picker"} placeholder={moment().format('YYYY-MM')} translate={null} onChange={this.changeDate.bind(this)}></MyDatePicker>
                            <FilterPicker theme={"wallet"} array={typeArray} placeholder={"状态"} translate={null} onChange={this.changeOrder.bind(this)}></FilterPicker>
                        </div>
                        <div className="total-infos">
                            <div className="txt df">收入：<span className="num color-orange">{monthAdd}</span></div>
                            <div className="txt">支出：<span className="num">{monthSubtract}</span></div>
                        </div>
                    </div>


                </div>

                <MyListView onRef={(ref) => { this.listView = ref }} loadData={(page) => this.requestForAccount(page)} createListItem={(value) => { return this._createListItem(value) }}></MyListView>

            </div>
        )
    }
    changeOrder(value) {
        this.setState({
            type: value
        }, () => {
            this.listView.initLoading();
        })
    };
    changeDate(value){
        this.setState({
            timeFrom:value
        },()=>{
            this.listView.initLoading();
        })
    }

    requestForAccount(page) {
        let userInfo = this.props.userInfo;
        getApi.doAccountDetailList({
            page: page,
            timeFrom: this.state.timeFrom,
            type: this.state.type,
            status: null,
            token: userInfo.token,
            userId: userInfo.userId,
        }).then((res) => {
            if (res.success) {
                res = res.module;
                let monthAdd = 0;
                let monthSubtract = 0;
                let totalMoney = 0;
                if (res.monthAdd === 0) {
                    monthAdd = res.monthAdd;
                } else {
                    monthAdd = parseFloat(res.monthAdd).toFixed(2);
                }
                if (res.monthSubtract === 0) {
                    monthSubtract = res.monthSubtract;
                } else {
                    monthSubtract = parseFloat(0 - res.monthSubtract).toFixed(2);
                }
                if (res.totalMoney === 0) {
                    totalMoney = res.totalMoney + ".00";
                } else {
                    totalMoney = parseFloat(res.totalMoney).toFixed(2);
                }
                this.setState({
                    monthAdd: monthAdd,
                    monthSubtract: monthSubtract,
                    totalMoney: totalMoney,
                    balance: (this.props.userInfo.balance !== 0 && (this.props.userInfo.balance.toString()).indexOf(".") !== -1) ? this.props.userInfo.balance.toString().substring(0, (this.props.userInfo.balance.toString()).indexOf(".") + 3) : this.props.userInfo.balance + ".00",
                })
                this.listView.setData(res.list);
            }
        });

    };
    _createListItem(value) {
        return (
            <div className="my-wallet">
                <div className="list-items">
                    <div className="item">
                        <div className="mt">
                            <div className="txt">{value.memo}</div>
                            <div className={value.money > 0 ? "txt add" : "txt reduce"}>{value.money > 0 ? "+" + value.money : value.money}元</div>
                        </div>
                        <div className="mb">
                            <div className="label">{value.createdTime}</div>
                            {value.status === 0 ?
                                <div className="label">待支付</div>
                                :
                                <div className="label">已到账</div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    };
    componentWillMount() {
        let loading = document.getElementById('i-loading');
        if (loading) {
            setTimeout(() => {
                loading.style.display = 'none'
            }, 1000)
        }
    }
}

const mapState = (state) => ({
    resume: state.login.resume,
    userInfo: state.login.userInfo,
    shareGongZhongHao:state.login.shareGongZhongHao,
    clientType: state.login.clientType,
})

const mapDispatch = (dispatch) => ({

})

export default connect(mapState, mapDispatch)(MyWallet);