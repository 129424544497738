import React, { Component } from 'react';
import { connect } from 'react-redux';
import { constants } from '../../../login/store';
import { Button, Toast } from 'antd-mobile';
import { BUTTON_PRIMARY } from '../../../../assets/style/common';
import Validate from '../../../../utils/Validate';
import Api from '../../../../utils/Api';
import TopBar from '../../../../components/top-bar/TopBar';
import { chkPrice } from '../../../../components/params/params';
const getApi = Api.getInstance();

class TakeCash extends Component {
    constructor(props) {
        super(props);
        this.state = {
            balanceOld: this.props.userInfo.balance > 0 ? JSON.parse(JSON.stringify(this.props.userInfo.balance)) : 0,
            balance: this.props.userInfo.balance > 0 ? JSON.parse(JSON.stringify(this.props.userInfo.balance)) : 0,
            loadingState: false,
        }
        this.validate = this.initValidate();
    }

    render() {
        let { balanceOld, balance, loadingState } = this.state;
        let { userInfo } = this.props;
        return (
            <div className="bg-f4f5f7">
                <TopBar title={"提现"}></TopBar>

                <div className="tixian">
                    {userInfo.authenticateStatus !== 2 &&
                        <div className="notice">实名认证用户可享提现急速到账，无需等待审核<a onClick={() => { this.props.history.push("/Authentication") }} href="javacript:void(0);">快去认证</a></div>
                    }   <div className="tixian-form">
                        <div className="item">
                            <div className="label">提现到</div>
                            <div className="cont">
                                <div className="tab-item ">微信钱包</div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="label">钱包余额</div>
                            <div className="cont">
                                <div>{balanceOld}</div>
                            </div>
                        </div>
                        <div className="item other">
                            <div className="label">提现金额</div>
                            <div className="mc num-box">
                                <div className="cny">￥</div>
                                <div className="num"><input value={balance} type="text" className="num-ipt" onChange={(e) => { this.setState({ balance: chkPrice(e.target.value) }) }} /></div>
                            </div>
                        </div>
                        <Button loading={loadingState} style={BUTTON_PRIMARY} onClick={this.submit.bind(this)}>确定提现</Button>

                    </div>
                    {userInfo.authenticateStatus === 2 ?
                        <div className="tip">*您已通过实名认证，提现无需审核，工资极速到账</div>
                        :
                        <div className="tip">*提现审核到账需1~3天时间，请耐心等待</div>
                    }
                </div>

            </div>
        )
    };
    componentWillMount() {
        let loading = document.getElementById('i-loading');
        if (loading) {
            loading.style.display = 'none'
        }
    }
    componentDidMount() {
        // 分享公众号
        let params = { userId: this.props.userInfo.userId,title:this.props.shareGongZhongHao.title, clientType: this.props.clientType }
        document.addEventListener('WeixinJSBridgeReady', getApi.doShareWap(params), false);
    };
    submit() {
        let userInfo = this.props.userInfo;
        const params = this.state;
        params.token = userInfo.token;
        params.userId = userInfo.userId;
        params.takeCashTo = 1;
        if (!this.validate.checkForm(params)) {
            const error = this.validate.errorList[0];
            Toast.fail(error.msg, 1)
            return false;
        }
        let chooseMoney = params.balance > 0 ? parseFloat(params.balance) : 0;
        let balanceMoney = this.props.userInfo.balance > 0 ? parseFloat(this.props.userInfo.balance) : 0;
        if (chooseMoney > balanceMoney) {
            Toast.fail("提现金额需小于余额", 1)
            return false;
        }
        this.setState({
            loadingState: true
        }, () => {
            getApi.doPublishUserTakeCash(
                params
            ).then((res) => {
                if (res.success) {
                    let userInfo = this.props.userInfo;
                    if (this.props.userInfo.balance === params.balance) {
                        userInfo.balance = 0;
                    } else if ((this.props.userInfo.balance - params.balance).toString().indexOf(".") === -1) {
                        userInfo.balance = (this.props.userInfo.balance - params.balance);
                    } else {
                        userInfo.balance = (this.props.userInfo.balance - params.balance).toString().substring(0, (this.props.userInfo.balance - params.balance).toString().indexOf(".") + 3);
                    }
                    let flag = (res.module && res.module.flag) ? res.module.flag : false;
                    this.props.setResume(userInfo);
                    this.props.history.replace('takeCashSuccess?flag=' + flag);
                }
            }).finally(res => {
                this.setState({
                    loadingState: false
                })
            });
        })
    };
    initValidate() {
        const rules = {
            balance: {
                required: true,
                min: 0.01
            },
        }

        const messages = {
            balance: {
                required: '请输入提现金额',
                min: '金额格式错误'
            },
        }

        return new Validate(rules, messages);
    };
}

const mapState = (state) => ({
    userInfo: state.login.userInfo,
    shareGongZhongHao:state.login.shareGongZhongHao,
    clientType: state.login.clientType,
})

const mapDispatch = (dispatch) => ({
    setResume(value) {
        dispatch({
            type: constants.RESUME,
            payload: value
        })
    }
})

export default connect(mapState, mapDispatch)(TakeCash);