import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Picker } from 'antd-mobile';
import { Dictionary, dictionaryList } from '../../utils/Dictionary';
const getDictionary = Dictionary.getInstance;

class RegionPicker extends Component {
    constructor(props) {
        super(props);
        this.state = {
            district: [
                {
                    "value": "340000",
                    "label": "安徽省",
                    "children": [{
                        "value": "341500",
                        "label": "六安市",
                        "children": [{
                            "value": "341522",
                            "label": "霍邱县",
                            "children": []
                        }, {
                            "value": "341525",
                            "label": "霍山县",
                            "children": []
                        }, {
                            "value": "341502",
                            "label": "金安区",
                            "children": []
                        }, {
                            "value": "341524",
                            "label": "金寨县",
                            "children": []
                        }, {
                            "value": "341526",
                            "label": "其它区",
                            "children": []
                        }, {
                            "value": "341521",
                            "label": "寿县",
                            "children": []
                        }, {
                            "value": "341523",
                            "label": "舒城县",
                            "children": []
                        }, {
                            "value": "341503",
                            "label": "裕安区",
                            "children": []
                        }]
                    }, {
                        "value": "340500",
                        "label": "马鞍山市",
                        "children": [{
                            "value": "340506",
                            "label": "博望区",
                            "children": []
                        }]
                    }, {
                        "value": "341800",
                        "label": "宣城市",
                        "children": [{
                            "value": "341822",
                            "label": "广德县",
                            "children": []
                        }, {
                            "value": "341824",
                            "label": "绩溪县",
                            "children": []
                        }, {
                            "value": "341825",
                            "label": "旌德县",
                            "children": []
                        }]
                    }]
                }, {
                    "value": "820000",
                    "label": "澳门特别行政区",
                    "children": [{
                        "value": "820100",
                        "label": "澳门半岛",
                        "children": []
                    }, {
                        "value": "820200",
                        "label": "离岛",
                        "children": []
                    }]
                }, {
                    "value": "110000",
                    "label": "北京",
                    "children": [{
                        "value": "110100",
                        "label": "北京市",
                        "children": [{
                            "value": "110114",
                            "label": "昌平区",
                            "children": []
                        }, {
                            "value": "110105",
                            "label": "朝阳区",
                            "children": []
                        }, {
                            "value": "110103",
                            "label": "崇文区",
                            "children": []
                        }, {
                            "value": "110115",
                            "label": "大兴区",
                            "children": []
                        }, {
                            "value": "110101",
                            "label": "东城区",
                            "children": []
                        }, {
                            "value": "110111",
                            "label": "房山区",
                            "children": []
                        }, {
                            "value": "110106",
                            "label": "丰台区",
                            "children": []
                        }, {
                            "value": "110108",
                            "label": "海淀区",
                            "children": []
                        }, {
                            "value": "110116",
                            "label": "怀柔区",
                            "children": []
                        }, {
                            "value": "110109",
                            "label": "门头沟区",
                            "children": []
                        }, {
                            "value": "110228",
                            "label": "密云县",
                            "children": []
                        }, {
                            "value": "110117",
                            "label": "平谷区",
                            "children": []
                        }, {
                            "value": "110230",
                            "label": "其它区",
                            "children": []
                        }, {
                            "value": "110107",
                            "label": "石景山区",
                            "children": []
                        }, {
                            "value": "110113",
                            "label": "顺义区",
                            "children": []
                        }, {
                            "value": "110112",
                            "label": "通州区",
                            "children": []
                        }, {
                            "value": "110102",
                            "label": "西城区",
                            "children": []
                        }, {
                            "value": "110104",
                            "label": "宣武区",
                            "children": []
                        }, {
                            "value": "110229",
                            "label": "延庆县",
                            "children": []
                        }]
                    }]
                }, {
                    "value": "450000",
                    "label": "广西壮族自治区",
                    "children": [{
                        "value": "450500",
                        "label": "北海市",
                        "children": [{
                            "value": "450502",
                            "label": "海城区",
                            "children": []
                        }, {
                            "value": "450521",
                            "label": "合浦县",
                            "children": []
                        }, {
                            "value": "450522",
                            "label": "其它区",
                            "children": []
                        }, {
                            "value": "450512",
                            "label": "铁山港区",
                            "children": []
                        }, {
                            "value": "450503",
                            "label": "银海区",
                            "children": []
                        }]
                    }, {
                        "value": "451000",
                        "label": "百色市",
                        "children": [{
                            "value": "451024",
                            "label": "德保县",
                            "children": []
                        }, {
                            "value": "451025",
                            "label": "靖西县",
                            "children": []
                        }, {
                            "value": "451028",
                            "label": "乐业县",
                            "children": []
                        }, {
                            "value": "451027",
                            "label": "凌云县",
                            "children": []
                        }, {
                            "value": "451031",
                            "label": "隆林各族自治县",
                            "children": []
                        }, {
                            "value": "451026",
                            "label": "那坡县",
                            "children": []
                        }, {
                            "value": "451023",
                            "label": "平果县",
                            "children": []
                        }, {
                            "value": "451032",
                            "label": "其它区",
                            "children": []
                        }, {
                            "value": "451022",
                            "label": "田东县",
                            "children": []
                        }, {
                            "value": "451029",
                            "label": "田林县",
                            "children": []
                        }, {
                            "value": "451021",
                            "label": "田阳县",
                            "children": []
                        }, {
                            "value": "451030",
                            "label": "西林县",
                            "children": []
                        }, {
                            "value": "451002",
                            "label": "右江区",
                            "children": []
                        }]
                    }, {
                        "value": "451400",
                        "label": "崇左市",
                        "children": [{
                            "value": "451424",
                            "label": "大新县",
                            "children": []
                        }, {
                            "value": "451421",
                            "label": "扶绥县",
                            "children": []
                        }, {
                            "value": "451402",
                            "label": "江州区",
                            "children": []
                        }, {
                            "value": "451423",
                            "label": "龙州县",
                            "children": []
                        }, {
                            "value": "451422",
                            "label": "宁明县",
                            "children": []
                        }, {
                            "value": "451481",
                            "label": "凭祥市",
                            "children": []
                        }, {
                            "value": "451482",
                            "label": "其它区",
                            "children": []
                        }, {
                            "value": "451425",
                            "label": "天等县",
                            "children": []
                        }]
                    }, {
                        "value": "450600",
                        "label": "防城港市",
                        "children": [{
                            "value": "450681",
                            "label": "东兴市",
                            "children": []
                        }, {
                            "value": "450603",
                            "label": "防城区",
                            "children": []
                        }, {
                            "value": "450602",
                            "label": "港口区",
                            "children": []
                        }, {
                            "value": "450682",
                            "label": "其它区",
                            "children": []
                        }, {
                            "value": "450621",
                            "label": "上思县",
                            "children": []
                        }]
                    }, {
                        "value": "450800",
                        "label": "贵港市",
                        "children": [{
                            "value": "450802",
                            "label": "港北区",
                            "children": []
                        }, {
                            "value": "450803",
                            "label": "港南区",
                            "children": []
                        }, {
                            "value": "450881",
                            "label": "桂平市",
                            "children": []
                        }, {
                            "value": "450821",
                            "label": "平南县",
                            "children": []
                        }, {
                            "value": "450882",
                            "label": "其它区",
                            "children": []
                        }, {
                            "value": "450804",
                            "label": "覃塘区",
                            "children": []
                        }]
                    }, {
                        "value": "450300",
                        "label": "桂林市",
                        "children": [{
                            "value": "450303",
                            "label": "叠彩区",
                            "children": []
                        }, {
                            "value": "450332",
                            "label": "恭城瑶族自治县",
                            "children": []
                        }, {
                            "value": "450327",
                            "label": "灌阳县",
                            "children": []
                        }, {
                            "value": "450331",
                            "label": "荔浦县",
                            "children": []
                        }, {
                            "value": "450322",
                            "label": "临桂区",
                            "children": []
                        }, {
                            "value": "450323",
                            "label": "灵川县",
                            "children": []
                        }, {
                            "value": "450328",
                            "label": "龙胜各族自治县",
                            "children": []
                        }, {
                            "value": "450330",
                            "label": "平乐县",
                            "children": []
                        }, {
                            "value": "450333",
                            "label": "其它区",
                            "children": []
                        }, {
                            "value": "450305",
                            "label": "七星区",
                            "children": []
                        }, {
                            "value": "450324",
                            "label": "全州县",
                            "children": []
                        }, {
                            "value": "450304",
                            "label": "象山区",
                            "children": []
                        }, {
                            "value": "450325",
                            "label": "兴安县",
                            "children": []
                        }, {
                            "value": "450302",
                            "label": "秀峰区",
                            "children": []
                        }, {
                            "value": "450311",
                            "label": "雁山区",
                            "children": []
                        }, {
                            "value": "450321",
                            "label": "阳朔县",
                            "children": []
                        }, {
                            "value": "450326",
                            "label": "永福县",
                            "children": []
                        }, {
                            "value": "450329",
                            "label": "资源县",
                            "children": []
                        }]
                    }, {
                        "value": "451200",
                        "label": "河池市",
                        "children": [{
                            "value": "451227",
                            "label": "巴马瑶族自治县",
                            "children": []
                        }, {
                            "value": "451229",
                            "label": "大化瑶族自治县",
                            "children": []
                        }, {
                            "value": "451224",
                            "label": "东兰县",
                            "children": []
                        }, {
                            "value": "451228",
                            "label": "都安瑶族自治县",
                            "children": []
                        }, {
                            "value": "451223",
                            "label": "凤山县",
                            "children": []
                        }, {
                            "value": "451226",
                            "label": "环江毛南族自治县",
                            "children": []
                        }, {
                            "value": "451202",
                            "label": "金城江区",
                            "children": []
                        }, {
                            "value": "451225",
                            "label": "罗城仫佬族自治县",
                            "children": []
                        }, {
                            "value": "451221",
                            "label": "南丹县",
                            "children": []
                        }, {
                            "value": "451282",
                            "label": "其它区",
                            "children": []
                        }, {
                            "value": "451222",
                            "label": "天峨县",
                            "children": []
                        }, {
                            "value": "451281",
                            "label": "宜州市",
                            "children": []
                        }]
                    }, {
                        "value": "451100",
                        "label": "贺州市",
                        "children": [{
                            "value": "451102",
                            "label": "八步区",
                            "children": []
                        }, {
                            "value": "451123",
                            "label": "富川瑶族自治县",
                            "children": []
                        }, {
                            "value": "451119",
                            "label": "平桂管理区",
                            "children": []
                        }, {
                            "value": "451124",
                            "label": "其它区",
                            "children": []
                        }, {
                            "value": "451121",
                            "label": "昭平县",
                            "children": []
                        }, {
                            "value": "451122",
                            "label": "钟山县",
                            "children": []
                        }]
                    }, {
                        "value": "451300",
                        "label": "来宾市",
                        "children": [{
                            "value": "451381",
                            "label": "合山市",
                            "children": []
                        }, {
                            "value": "451324",
                            "label": "金秀瑶族自治县",
                            "children": []
                        }, {
                            "value": "451382",
                            "label": "其它区",
                            "children": []
                        }, {
                            "value": "451323",
                            "label": "武宣县",
                            "children": []
                        }, {
                            "value": "451322",
                            "label": "象州县",
                            "children": []
                        }, {
                            "value": "451321",
                            "label": "忻城县",
                            "children": []
                        }, {
                            "value": "451302",
                            "label": "兴宾区",
                            "children": []
                        }]
                    }, {
                        "value": "450200",
                        "label": "柳州市",
                        "children": [{
                            "value": "450202",
                            "label": "城中区",
                            "children": []
                        }, {
                            "value": "450205",
                            "label": "柳北区",
                            "children": []
                        }, {
                            "value": "450222",
                            "label": "柳城县",
                            "children": []
                        }, {
                            "value": "450221",
                            "label": "柳江县",
                            "children": []
                        }, {
                            "value": "450204",
                            "label": "柳南区",
                            "children": []
                        }, {
                            "value": "450223",
                            "label": "鹿寨县",
                            "children": []
                        }, {
                            "value": "450227",
                            "label": "其它区",
                            "children": []
                        }, {
                            "value": "450224",
                            "label": "融安县",
                            "children": []
                        }, {
                            "value": "450225",
                            "label": "融水苗族自治县",
                            "children": []
                        }, {
                            "value": "450226",
                            "label": "三江侗族自治县",
                            "children": []
                        }, {
                            "value": "450203",
                            "label": "鱼峰区",
                            "children": []
                        }]
                    }, {
                        "value": "450100",
                        "label": "南宁市",
                        "children": [{
                            "value": "450126",
                            "label": "宾阳县",
                            "children": []
                        }, {
                            "value": "450127",
                            "label": "横县",
                            "children": []
                        }, {
                            "value": "450105",
                            "label": "江南区",
                            "children": []
                        }, {
                            "value": "450108",
                            "label": "良庆区",
                            "children": []
                        }, {
                            "value": "450123",
                            "label": "隆安县",
                            "children": []
                        }, {
                            "value": "450124",
                            "label": "马山县",
                            "children": []
                        }, {
                            "value": "450128",
                            "label": "其它区",
                            "children": []
                        }, {
                            "value": "450103",
                            "label": "青秀区",
                            "children": []
                        }, {
                            "value": "450125",
                            "label": "上林县",
                            "children": []
                        }, {
                            "value": "450122",
                            "label": "武鸣区",
                            "children": []
                        }, {
                            "value": "450107",
                            "label": "西乡塘区",
                            "children": []
                        }, {
                            "value": "450102",
                            "label": "兴宁区",
                            "children": []
                        }, {
                            "value": "450109",
                            "label": "邕宁区",
                            "children": []
                        }]
                    }, {
                        "value": "450700",
                        "label": "钦州市",
                        "children": [{
                            "value": "450721",
                            "label": "灵山县",
                            "children": []
                        }, {
                            "value": "450722",
                            "label": "浦北县",
                            "children": []
                        }, {
                            "value": "450723",
                            "label": "其它区",
                            "children": []
                        }, {
                            "value": "450703",
                            "label": "钦北区",
                            "children": []
                        }, {
                            "value": "450702",
                            "label": "钦南区",
                            "children": []
                        }]
                    }, {
                        "value": "450400",
                        "label": "梧州市",
                        "children": [{
                            "value": "450421",
                            "label": "苍梧县",
                            "children": []
                        }, {
                            "value": "450481",
                            "label": "岑溪市",
                            "children": []
                        }, {
                            "value": "450405",
                            "label": "长洲区",
                            "children": []
                        }, {
                            "value": "450404",
                            "label": "蝶山区",
                            "children": []
                        }, {
                            "value": "450406",
                            "label": "龙圩区",
                            "children": []
                        }, {
                            "value": "450423",
                            "label": "蒙山县",
                            "children": []
                        }, {
                            "value": "450482",
                            "label": "其它区",
                            "children": []
                        }, {
                            "value": "450422",
                            "label": "藤县",
                            "children": []
                        }, {
                            "value": "450403",
                            "label": "万秀区",
                            "children": []
                        }]
                    }, {
                        "value": "450900",
                        "label": "玉林市",
                        "children": [{
                            "value": "450981",
                            "label": "北流市",
                            "children": []
                        }, {
                            "value": "450923",
                            "label": "博白县",
                            "children": []
                        }, {
                            "value": "450903",
                            "label": "福绵区",
                            "children": []
                        }, {
                            "value": "450922",
                            "label": "陆川县",
                            "children": []
                        }, {
                            "value": "450982",
                            "label": "其它区",
                            "children": []
                        }, {
                            "value": "450921",
                            "label": "容县",
                            "children": []
                        }, {
                            "value": "450924",
                            "label": "兴业县",
                            "children": []
                        }, {
                            "value": "450902",
                            "label": "玉州区",
                            "children": []
                        }]
                    }]
                }, {
                    "value": "810000",
                    "label": "香港特别行政区",
                    "children": [{
                        "value": "810200",
                        "label": "九龙",
                        "children": [{
                            "value": "810205",
                            "label": "观塘区",
                            "children": []
                        }, {
                            "value": "810204",
                            "label": "黄大仙区",
                            "children": []
                        }, {
                            "value": "810201",
                            "label": "九龙城区",
                            "children": []
                        }, {
                            "value": "810203",
                            "label": "深水埗区",
                            "children": []
                        }, {
                            "value": "810202",
                            "label": "油尖旺区",
                            "children": []
                        }]
                    }, {
                        "value": "810100",
                        "label": "香港岛",
                        "children": [{
                            "value": "810103",
                            "label": "东区",
                            "children": []
                        }, {
                            "value": "810104",
                            "label": "南区",
                            "children": []
                        }, {
                            "value": "810102",
                            "label": "湾仔",
                            "children": []
                        }, {
                            "value": "810101",
                            "label": "中西区",
                            "children": []
                        }]
                    }, {
                        "value": "810300",
                        "label": "新界",
                        "children": [{
                            "value": "810301",
                            "label": "北区",
                            "children": []
                        }, {
                            "value": "810302",
                            "label": "大埔区",
                            "children": []
                        }, {
                            "value": "810308",
                            "label": "葵青区",
                            "children": []
                        }, {
                            "value": "810309",
                            "label": "离岛区",
                            "children": []
                        }, {
                            "value": "810307",
                            "label": "荃湾区",
                            "children": []
                        }, {
                            "value": "810303",
                            "label": "沙田区",
                            "children": []
                        }, {
                            "value": "810306",
                            "label": "屯门区",
                            "children": []
                        }, {
                            "value": "810304",
                            "label": "西贡区",
                            "children": []
                        }, {
                            "value": "810305",
                            "label": "元朗区",
                            "children": []
                        }]
                    }]
                }, {
                    "value": "330000",
                    "label": "浙江省",
                    "children": [{
                        "value": "330100",
                        "label": "杭州市",
                        "children": [{
                            "value": "330108",
                            "label": "滨江区",
                            "children": []
                        }, {
                            "value": "330127",
                            "label": "淳安县",
                            "children": []
                        }, {
                            "value": "330183",
                            "label": "富阳区",
                            "children": []
                        }, {
                            "value": "330105",
                            "label": "拱墅区",
                            "children": []
                        }, {
                            "value": "330182",
                            "label": "建德市",
                            "children": []
                        }, {
                            "value": "330104",
                            "label": "江干区",
                            "children": []
                        }, {
                            "value": "330185",
                            "label": "临安市",
                            "children": []
                        }, {
                            "value": "330186",
                            "label": "其它区",
                            "children": []
                        }, {
                            "value": "330102",
                            "label": "上城区",
                            "children": []
                        }, {
                            "value": "330122",
                            "label": "桐庐县",
                            "children": []
                        }, {
                            "value": "330106",
                            "label": "西湖区",
                            "children": []
                        }, {
                            "value": "330103",
                            "label": "下城区",
                            "children": []
                        }, {
                            "value": "330109",
                            "label": "萧山区",
                            "children": []
                        }, {
                            "value": "330110",
                            "label": "余杭区",
                            "children": []
                        }]
                    }, {
                        "value": "330500",
                        "label": "湖州市",
                        "children": [{
                            "value": "330523",
                            "label": "安吉县",
                            "children": []
                        }, {
                            "value": "330522",
                            "label": "长兴县",
                            "children": []
                        }, {
                            "value": "330521",
                            "label": "德清县",
                            "children": []
                        }, {
                            "value": "330503",
                            "label": "南浔区",
                            "children": []
                        }, {
                            "value": "330524",
                            "label": "其它区",
                            "children": []
                        }, {
                            "value": "330502",
                            "label": "吴兴区",
                            "children": []
                        }]
                    }, {
                        "value": "330400",
                        "label": "嘉兴市",
                        "children": [{
                            "value": "330481",
                            "label": "海宁市",
                            "children": []
                        }, {
                            "value": "330424",
                            "label": "海盐县",
                            "children": []
                        }, {
                            "value": "330421",
                            "label": "嘉善县",
                            "children": []
                        }, {
                            "value": "330402",
                            "label": "南湖区",
                            "children": []
                        }, {
                            "value": "330482",
                            "label": "平湖市",
                            "children": []
                        }, {
                            "value": "330484",
                            "label": "其它区",
                            "children": []
                        }, {
                            "value": "330483",
                            "label": "桐乡市",
                            "children": []
                        }, {
                            "value": "330411",
                            "label": "秀洲区",
                            "children": []
                        }]
                    }, {
                        "value": "330700",
                        "label": "金华市",
                        "children": [{
                            "value": "330783",
                            "label": "东阳市",
                            "children": []
                        }, {
                            "value": "330703",
                            "label": "金东区",
                            "children": []
                        }, {
                            "value": "330781",
                            "label": "兰溪市",
                            "children": []
                        }, {
                            "value": "330727",
                            "label": "磐安县",
                            "children": []
                        }, {
                            "value": "330726",
                            "label": "浦江县",
                            "children": []
                        }, {
                            "value": "330785",
                            "label": "其它区",
                            "children": []
                        }, {
                            "value": "330702",
                            "label": "婺城区",
                            "children": []
                        }, {
                            "value": "330723",
                            "label": "武义县",
                            "children": []
                        }, {
                            "value": "330782",
                            "label": "义乌市",
                            "children": []
                        }, {
                            "value": "330784",
                            "label": "永康市",
                            "children": []
                        }]
                    }, {
                        "value": "331100",
                        "label": "丽水市",
                        "children": [{
                            "value": "331122",
                            "label": "缙云县",
                            "children": []
                        }, {
                            "value": "331127",
                            "label": "景宁畲族自治县",
                            "children": []
                        }, {
                            "value": "331102",
                            "label": "莲都区",
                            "children": []
                        }, {
                            "value": "331181",
                            "label": "龙泉市",
                            "children": []
                        }, {
                            "value": "331182",
                            "label": "其它区",
                            "children": []
                        }, {
                            "value": "331121",
                            "label": "青田县",
                            "children": []
                        }, {
                            "value": "331126",
                            "label": "庆元县",
                            "children": []
                        }, {
                            "value": "331124",
                            "label": "松阳县",
                            "children": []
                        }, {
                            "value": "331123",
                            "label": "遂昌县",
                            "children": []
                        }, {
                            "value": "331125",
                            "label": "云和县",
                            "children": []
                        }]
                    }, {
                        "value": "330200",
                        "label": "宁波市",
                        "children": [{
                            "value": "330206",
                            "label": "北仑区",
                            "children": []
                        }, {
                            "value": "330282",
                            "label": "慈溪市",
                            "children": []
                        }, {
                            "value": "330283",
                            "label": "奉化市",
                            "children": []
                        }, {
                            "value": "330203",
                            "label": "海曙区",
                            "children": []
                        }, {
                            "value": "330205",
                            "label": "江北区",
                            "children": []
                        }, {
                            "value": "330204",
                            "label": "江东区",
                            "children": []
                        }, {
                            "value": "330226",
                            "label": "宁海县",
                            "children": []
                        }, {
                            "value": "330284",
                            "label": "其它区",
                            "children": []
                        }, {
                            "value": "330225",
                            "label": "象山县",
                            "children": []
                        }, {
                            "value": "330212",
                            "label": "鄞州区",
                            "children": []
                        }, {
                            "value": "330281",
                            "label": "余姚市",
                            "children": []
                        }, {
                            "value": "330211",
                            "label": "镇海区",
                            "children": []
                        }]
                    }, {
                        "value": "330800",
                        "label": "衢州市",
                        "children": [{
                            "value": "330822",
                            "label": "常山县",
                            "children": []
                        }, {
                            "value": "330881",
                            "label": "江山市",
                            "children": []
                        }, {
                            "value": "330824",
                            "label": "开化县",
                            "children": []
                        }, {
                            "value": "330802",
                            "label": "柯城区",
                            "children": []
                        }, {
                            "value": "330825",
                            "label": "龙游县",
                            "children": []
                        }, {
                            "value": "330882",
                            "label": "其它区",
                            "children": []
                        }, {
                            "value": "330803",
                            "label": "衢江区",
                            "children": []
                        }]
                    }, {
                        "value": "330600",
                        "label": "绍兴市",
                        "children": [{
                            "value": "330621",
                            "label": "柯桥区",
                            "children": []
                        }, {
                            "value": "330684",
                            "label": "其它区",
                            "children": []
                        }, {
                            "value": "330682",
                            "label": "上虞区",
                            "children": []
                        }, {
                            "value": "330683",
                            "label": "嵊州市",
                            "children": []
                        }, {
                            "value": "330624",
                            "label": "新昌县",
                            "children": []
                        }, {
                            "value": "330602",
                            "label": "越城区",
                            "children": []
                        }, {
                            "value": "330681",
                            "label": "诸暨市",
                            "children": []
                        }]
                    }, {
                        "value": "331000",
                        "label": "台州市",
                        "children": [{
                            "value": "331003",
                            "label": "黄岩区",
                            "children": []
                        }, {
                            "value": "331002",
                            "label": "椒江区",
                            "children": []
                        }, {
                            "value": "331082",
                            "label": "临海市",
                            "children": []
                        }, {
                            "value": "331004",
                            "label": "路桥区",
                            "children": []
                        }, {
                            "value": "331083",
                            "label": "其它区",
                            "children": []
                        }, {
                            "value": "331022",
                            "label": "三门县",
                            "children": []
                        }, {
                            "value": "331023",
                            "label": "天台县",
                            "children": []
                        }, {
                            "value": "331081",
                            "label": "温岭市",
                            "children": []
                        }, {
                            "value": "331024",
                            "label": "仙居县",
                            "children": []
                        }, {
                            "value": "331021",
                            "label": "玉环县",
                            "children": []
                        }]
                    }, {
                        "value": "330300",
                        "label": "温州市",
                        "children": [{
                            "value": "330327",
                            "label": "苍南县",
                            "children": []
                        }, {
                            "value": "330322",
                            "label": "洞头县",
                            "children": []
                        }, {
                            "value": "330303",
                            "label": "龙湾区",
                            "children": []
                        }, {
                            "value": "330302",
                            "label": "鹿城区",
                            "children": []
                        }, {
                            "value": "330304",
                            "label": "瓯海区",
                            "children": []
                        }, {
                            "value": "330326",
                            "label": "平阳县",
                            "children": []
                        }, {
                            "value": "330383",
                            "label": "其它区",
                            "children": []
                        }, {
                            "value": "330381",
                            "label": "瑞安市",
                            "children": []
                        }, {
                            "value": "330329",
                            "label": "泰顺县",
                            "children": []
                        }, {
                            "value": "330328",
                            "label": "文成县",
                            "children": []
                        }, {
                            "value": "330324",
                            "label": "永嘉县",
                            "children": []
                        }, {
                            "value": "330382",
                            "label": "乐清市",
                            "children": []
                        }]
                    }, {
                        "value": "330900",
                        "label": "舟山市",
                        "children": [{
                            "value": "330921",
                            "label": "岱山县",
                            "children": []
                        }, {
                            "value": "330902",
                            "label": "定海区",
                            "children": []
                        }, {
                            "value": "330903",
                            "label": "普陀区",
                            "children": []
                        }, {
                            "value": "330923",
                            "label": "其它区",
                            "children": []
                        }, {
                            "value": "330922",
                            "label": "嵊泗县",
                            "children": []
                        }]
                    }]
                }],
            pickerValue: null,
            visible: false,
            areaUnlimit: this.props.areaUnlimit || 0,
            attribute: 'city',
            theme:this.props.theme || 'form',
            placeholder:this.props.placeholder || null,
            placeholderClass:this.props.placeholderClass || null,
            province:this.props.province || null,
            city:this.props.city || null,
            area: this.props.area || null
        }
    }
    componentDidMount() {
        if (dictionaryList.length === 0) {
            getDictionary().setDictionaryList();
        }
        let list = dictionaryList[this.state.attribute] || [];
        if (!list || list.length === 0) {
            return;
        }
        let array = this.setValue(list, this.state.areaUnlimit);
        this.setState({
            district: array
        })
    }
    setValue(datas, areaUnlimit) {
        if (!datas) {
            return;
        }
        let array = [];
        for (let province of datas) {
            if (province.parentId === 0) {
                let provinceArray = [];
                let provinceJson = {
                    "value": province.id,
                    "label": province.translate,
                    "children": []
                };
                provinceJson["children"] = provinceArray;
                array.push(provinceJson);

                for (let city of datas) {
                    if (city.parentId === province.id) {
                        let cityArray = [];
                        let cityJson = {
                            "value": city.id,
                            "label": city.translate,
                            "children": []
                        }
                        cityJson["children"] = cityArray;
                        provinceArray.push(cityJson);


                        if (areaUnlimit === 1) {
                            cityArray.push({
                                "value":0,
                                "label":'全部'
                            });
                        }

                        for (let area of datas) {
                            if (area.parentId === city.id) {
                                let areaJson = {
                                    "value": area.id,
                                    "label": area.translate,
                                    "children": []
                                };
                                cityArray.push(areaJson);
                            }
                        }
                    }
                }

            }
        }
        return array;
    }

    render() {
        const CustomChildren = props => (
            <div>
                {theme === "work" &&
                    <div
                        onClick={props.onClick}
                        className={props.placeholderClass}
                    >
                        {props.extra === '请选择' ? ((props.province && props.city)?(props.province +','+ props.city +','+ (props.area || '全部')) : props.placeholder) : props.extra}
                    </div>
                }
            </div>

        );
        let { district,theme,placeholder,placeholderClass,province,city,area } = this.state;
        let { title } = this.props || "测试标题";
        return (
            <div>
                <Picker
                    data={district}
                    value={this.state.pickerValue}
                    title={title}
                    onChange={this.change.bind(this)}
                >
                    <CustomChildren theme={theme} placeholder={placeholder} placeholderClass={placeholderClass} province={province} city={city} area={area} />
                </Picker>
            </div>
        )
    };
    change(value){
        this.setState({pickerValue:value});
        this.props.onChange(value);
    }
}
const mapState = (state) => ({
})

const mapDispatch = (dispatch) => ({
});

export default connect(mapState, mapDispatch)(RegionPicker);