import { Modal, WingBlank} from 'antd-mobile';
import React, { Component } from 'react';
/* import { chkSearch } from '../../components/params/params'; */
import { connect } from 'react-redux';
// 分享弹窗-选择直接分享还是生成海报分享
class ShareModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            
        };
    }

    componentDidMount() {
    }

    render() {
        // let {searchName} = this.state;
        let { modal, name, posterUrl } = this.props;
        return (
            <WingBlank>
                <Modal
                    popup
                    visible={modal}
                    maskClosable={true}
                    onClose={this.props.onClose}
                    animationType="slide-up"
                    afterClose={this.props.refresh}
                >
                {name === "chooseModal" &&
                    <div className="bg-poster">
                        <div className="status_box">
                            <div className="box_item" onClick={this.onShareAppMessage.bind(this)}>
                                <img src="https://cdn.huizhuanvip.com/jianzhi/app/images/icon_share_wx.png" className="img" alt=""></img>
                                <div className="text">分享给好友</div>
                            </div>
                            <div className="box_item" onClick={this.showPosterModal.bind(this)}>
                                <img src="https://cdn.huizhuanvip.com/jianzhi/app/images/icon_share_hb.png" className="img" alt=""></img>
                                <div className="text">生成海报分享</div>
                            </div>
                        </div>
                    </div>
                }
                {name === "positionPosterModal" &&
                    <div className="bg-poster">
                        <div className="poster_box">
                            <img src="https://cdn.huizhuanvip.com/jianzhi/app/images/icon_share_close.png" className="poster_close" onClick={this.onClosePoster.bind(this)} alt=""></img>
                            <img src={posterUrl} className="poster_content" alt=""></img>
                            <div className="guide">长按保存图片，即可分享图片给好友或朋友圈</div>
                            {/* <div className="poster_save_btn" onClick={this.onSavePoster.bind(this)}>
                                <button className="btn">
                                    <img src="https://cdn.huizhuanvip.com/jianzhi/app/images/icon_share_save.png" className="poster_save_img" alt=""></img>保存图片
                                </button>
                            </div> */}
                        </div>
                    </div>
                }
                {name === "gzhPosterModal" &&
                    <div className="bg-poster">
                        <div className="poster_box">
                            <img src="https://cdn.huizhuanvip.com/jianzhi/app/images/icon_share_close.png" className="poster_close" onClick={this.onClosePoster.bind(this)} alt=""></img>
                            <img src={posterUrl} className="poster_content" alt=""></img>
                            <div className="guide">长按保存图片，即可分享图片给好友或朋友圈</div>
                            {/* <div className="poster_save_btn" onClick={this.onSavePoster.bind(this)}>
                                <button className="btn">
                                    <img src="https://cdn.huizhuanvip.com/jianzhi/app/images/icon_share_save.png" className="poster_save_img" alt=""></img>保存图片
                                </button>
                            </div> */}
                        </div>
                    </div>
                }
                </Modal>
            </WingBlank >
        );

    }

    onShareAppMessage() {
        this.props.onShareAppMessage();
    };
    showPosterModal() {
        this.props.showPosterModal();
    };
    onClosePoster() {
        this.props.onClosePoster();
    };
    onSavePoster() {
        this.props.onSavePoster();
    };
}
const mapState = (state) => ({
    city: state.login.city,
    location: state.login.location,
    searchNameList: state.login.searchNameList
})

const mapDispatch = (dispatch) => ({
});

export default connect(mapState, mapDispatch)(ShareModal);