import React, { Component } from 'react';
import { withRouter } from "react-router-dom";

class TopBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: this.props.title || "",
            signUp: this.props.signUp || null,
        }
    }

    render() {
        let { title, signUp } = this.state;
        return (
            <div>
                <div className="top-bar" >
                    <div className="top">
                        {!signUp &&
                            <div className="goback" onClick={() => { this.props.history.goBack() }}><i className="ico"></i></div>
                        }
                        <div className="h1">{title}</div>
                    </div>
                </div>
                    <div className="top-bar-zw"></div>
            </div>
        )
    };
}
export default withRouter(TopBar);
