import React, { Component } from 'react';
import { connect } from 'react-redux';
import { constants } from '../../../login/store';
import { Toast } from 'antd-mobile';
// import { BUTTON_PRIMARY } from '../../../../assets/style/common';
import Validate from '../../../../utils/Validate';
import Api from '../../../../utils/Api';
import TopBar from '../../../../components/top-bar/TopBar';
const getApi = Api.getInstance();

class ChangeAccount extends Component {
    constructor(props) {
        super(props);
		this.state = {
			mobile: null,
			code: null,
			codeloadingState: false,
			time: 0,
            loading: false,
            account: {},
		}
		this.validate = this.initValidate();
    }

    render() {
        let { subloadingState, codeloadingState, account, time } = this.state;
        let { userInfo } = this.props;
        return (
            <div className="bg-f4f5f7">
                <TopBar title={"修改账户"}></TopBar>

                <div className='viewStyle'>
                    <div className='change_title'>更换手机号后，下次登录可以使用新手机号，当前手机号：</div>
                    {userInfo.mobile && <div className='phoneNum'>{userInfo.mobile}</div>}
                    <input placeholder='请输入新的手机号码' name='mobile' className='newPhoneNumC' /* placeholder-className='newPhoneNum_placeholderC' */ onChange={(text) => { account.mobile = text.target.value; this.setState({ account: account }) }}></input>
                    <div className='verificationCode_style'>
                        <input className='verificationCodeC' /* placeholder-className='newPhoneNum_placeholderC' */ name='code' placeholder='请输入验证码' onChange={(text) => { account.code = text.target.value; this.setState({ account: account }) }}></input>
                        {!codeloadingState && <button className='verificationCode' loading={codeloadingState.toString()} disabled={codeloadingState} onClick={this.getCode.bind(this)}>获取验证码</button>}
                        {codeloadingState && <input className='verificationCode1' disabled="disabled" value={time + "s后重新获取"}></input>}
                    </div>
                    <button  className='changeBtnC' form-type='submit' loading={subloadingState}  /* hover-className="changeBtnD" */ onClick={this.submit.bind(this)}>确定更改</button>
                </div>

            </div>
        )
    };
    componentDidMount() {
        // 分享公众号
        let params = { userId: this.props.userInfo.userId,title:this.props.shareGongZhongHao.title, clientType: this.props.clientType }
        document.addEventListener('WeixinJSBridgeReady', getApi.doShareWap(params), false);
    };
	getCode() {
		var endTime = 60;
		if (!this.state.account.mobile || this.state.account.mobile.length !== 11) {
			Toast.fail("手机号格式错误", 1);
			return false;
		}
		this.setState({
			codeloadingState: true,
			time: endTime,
		}, () => {
			getApi.doGetVeryCode({ 
				mobile: this.state.account.mobile,
			}).then((res) => {
				if (res.success) {
					var timer = setInterval(() => {
						endTime--;
						this.setState({
							time: endTime,
						})
						if (endTime <= 0) {
							this.setState({
								codeloadingState: false
							})
							clearInterval(timer);
						};
					}, 1000);
				}
			}).catch(function (reason) {
				console.log(reason);
			});
		});
	}
    submit() {
        let userInfo = this.props.userInfo;
        const params = this.state.account;
        params.token = userInfo.token;
        params.userId = userInfo.userId;
        if (!this.validate.checkForm(params)) {
            const error = this.validate.errorList[0];
            Toast.fail(error.msg, 1)
            return false;
        }
        this.setState({
            subloadingState: true
        }, () => {
            getApi.doModifyWxMobile(
                params
            ).then((res) => {
                if (res.success) {
                    userInfo.mobile = this.state.account.mobile;
                    this.props.setUserInfo(userInfo);
                    this.props.history.goBack();
                }
            }).finally(res => {
                this.setState({
                    subloadingState: false
                })
            });
        })
    };
    initValidate() {
		const rules = {
			mobile: {
				required: true,
				tel: true,
			},
			code: {
				required: true,
			}
		}

		const messages = {
			mobile: {
				required: '请输入手机号码',
				tel: '手机号需11位',
			},
			code: {
				required: '请输入验证码'
			}
		}

        return new Validate(rules, messages);
    };
	componentWillMount() {
        let loading = document.getElementById('i-loading')
        if (loading) {
            loading.style.display = 'none'
        }
    }
}

const mapState = (state) => ({
    userInfo: state.login.userInfo,
    shareGongZhongHao:state.login.shareGongZhongHao,
    clientType: state.login.clientType,
})

const mapDispatch = (dispatch) => ({
	setUserInfo(value) {
		dispatch({
			type: constants.USERINFO,
			payload: value
		})
	}
})

export default connect(mapState, mapDispatch)(ChangeAccount);