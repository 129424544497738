import React, { Component } from 'react';
import { connect } from 'react-redux';
import TopBar from '../../../../components/top-bar/TopBar';
import {GetParams} from '../../../../components/params/params';
import Api from '../../../../utils/Api';
const getApi = Api.getInstance();

class TakeCashSuccess extends Component {
    constructor(props){
        super(props);
        this.state={
            flag : GetParams(this.props.history.location.search, "flag")
        }
    }

    componentDidMount() {
		// 分享公众号
        let params = { userId: this.props.userInfo.userId,title:this.props.shareGongZhongHao.title, clientType: this.props.clientType }
        document.addEventListener('WeixinJSBridgeReady', getApi.doShareWap(params), false);
    }

    render() {
        let { flag } = this.state;
        let { resume } = this.props;
        return (
            <div className="bg-fff">
                <TopBar title={"提现成功"}></TopBar>

                <div className="tixian">
                    <div className="status-info">
                        <div className="infos">
                            <div className="ico"></div>
                            <div className="txt">
                                {flag === 'true' ?
                                    <div className="h">已到账</div> :
                                    <div className="h">提现中</div>
                                }
                                {resume.authenticateStatus === 2 ?
                                    <div className="p">您的提现申请已进入极速到账模式<br />如有问题请与客服联系</div> :
                                    <div className="p">您尚未通过实名认证，当前提现需审核1-3个工作日到账<br />实名认证后可享提现不审核，工资极速到账</div>
                                }
                            </div>
                        </div>
                        <div className="btns">
                            <a onClick={()=>{this.props.history.replace("/myWallet")}} className="btn btn1" href="javacript:void(0);">查看详情</a>
                            {resume.authenticateStatus === 2 ?
                                <a onClick={()=>{this.props.history.go(-2)}} className="btn btn2" href="javacript:void(0);">查看首页</a> :
                                <a onClick={()=>{this.props.history.replace("/Authentication")}} className="btn btn2" href="javacript:void(0);">去认证</a>
                            }
                        </div>
                    </div>

                </div>


            </div>
        )
    };
    componentWillMount() {
        let loading = document.getElementById('i-loading');
        if (loading) {
            setTimeout(() => {
                loading.style.display = 'none'
            }, 1000)
        }
    }
}

const mapState = (state) => ({
    resume:state.login.resume,
    userInfo: state.login.userInfo,
    shareGongZhongHao:state.login.shareGongZhongHao,
    clientType: state.login.clientType,
})

const mapDispatch = (dispatch) => ({
})

export default connect(mapState, mapDispatch)(TakeCashSuccess);