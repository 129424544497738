import React, { Component } from 'react';
import { connect } from 'react-redux';
import Api from '../../../utils/Api';
import LazyLoad from 'react-lazyload';
const getApi = Api.getInstance();
class SharePositionSuccess extends Component {

	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			isRefresh: true,
		}

	}
	componentDidMount() {
		let params = { userId: this.props.userInfo.userId,title:this.props.shareGongZhongHao.title, clientType: this.props.clientType }
		document.addEventListener('WeixinJSBridgeReady', getApi.doShareWap(params), false);
	}
	render() {
		return (
			<div className="bg-fff">
				<div className="share">
					<div className="item bg1">
						<LazyLoad>
							<img src={require('../../../assets/img/share1.png')} alt=""/>
						</LazyLoad>
					</div>
					<div className="item bg2">
						<LazyLoad>
							<img src={require('../../../assets/img/share2.gif')} className="banner" alt=""/>
						</LazyLoad>
						<div className="txt">您已成功报名好友“老徐”分享的高薪兼职<br />在邀请1人，可获职位加薪<span className="">0.5元/时</span><br />关注公众号即可分享给好友，快来看看吧！</div>
						<LazyLoad>
							<img src={require('../../../assets/img/share_zhiwen.png')} className="zhiwen" alt=""/>
						</LazyLoad>
						<div className="txt1">- 关注公众号  分享有加薪 -</div>
					</div>
				</div>

			</div>
		)
	}
	componentWillMount() {
		let loading = document.getElementById('i-loading');
		if (loading) {
			setTimeout(() => {
				loading.style.display = 'none'
			}, 1000)
		}
	}
}
const mapState = (state) => ({
	userInfo: state.login.userInfo,
	shareGongZhongHao:state.login.shareGongZhongHao,
	clientType: state.login.clientType,
})

const mapDispatch = (dispatch) => ({
})


export default connect(mapState, mapDispatch)(SharePositionSuccess);