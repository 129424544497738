import React, { Component } from 'react';
import axios from 'axios';

export default class MyImagePicker extends Component {
  state = {
    url: this.props.url || null
  }

  addImage = () => {
    this.input.click();
  };

  handleImageChange = (e) => {
    const file = e.target.files[0];
    const windowURL = window.URL || window.webkitURL;//实现预览
    const dataURl = windowURL.createObjectURL(file);//硬盘或sd卡指向文件路径
    this.setState({
      url: dataURl
    });
    let param = new FormData(); 
    param.append('file', file);
    axios({
      url: 'https://merchant.level-job.com/api/userUpload/doUploadImage.action',
      data: param,
      method: 'post'
    }).then((res) => {
      if (res.data.success) {
        console.log("newUrl========>" + res.data.module);
        this.props.changeImage(res.data.module)
      } else {
        alert(
          res.data.error,
        );
        this.setState({ url: null });
      }
    });
  };

  render() {
    let { url } = this.state;
    return (
      <div>
        <input type="file" accept="image/*" style={{ display: 'none' }} ref={(e) => { this.input = e }} onChange={this.handleImageChange} />
          <div className="imagePicker" onClick={this.addImage}>
            {url ?
                <img className="image" src={url} alt=""/> :
                <img className="image" src={require('../../assets/img/icon_scpic.png')} alt=""/>
            }
        </div>
      </div>
    );
  }

}
