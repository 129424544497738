import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Picker } from 'antd-mobile';
import {Dictionary,dictionaryList} from '../../utils/Dictionary';
import Api from '../../utils/Api';
const getApi = Api.getInstance();
const getDictionary = Dictionary.getInstance;

class SinglePicker extends Component {
    constructor(props) {
        super(props);
        this.state = {
            attribute: this.props.attribute || '',
            district: [],
            translate: this.props.translate || null,
            disValue: null,
            placeholder: this.props.placeholder || null,
            className: this.props.className || null,
            placeholderClass: this.props.placeholderClass || null,
            theme: this.props.theme || 'form',
            parentId: this.props.parentId || 0,
            arrayTemp: this.props.array || [],
        }
    }
    componentDidMount() {
        if (this.state.attribute === "city") {
            if(dictionaryList.length === 0){
                getDictionary().setDictionaryList();
            }
            let list = dictionaryList["city"] || [];
            this.doDistrict(list);
        } else {
            let userInfo = this.props.userInfo;
            if (!userInfo.token || !userInfo.userId) {
                return;
            }
            if (this.state.attribute) {
                getApi.queryDictionary({
                    token: userInfo.token,
                    userId: userInfo.userId,
                    attribute: this.state.attribute,
                }).then((res) => {
                    if (res.success) {
                        let list = res.module || [];
                        this.doDistrict(list);
                    }
                }).catch((res) => {
                    console.log();
                }).finally((res) => {
                })
            }
            else {
                this.doDistrict(this.state.arrayTemp);
            }
        }
    }
    doDistrict(list) {
        if (!list || list.length === 0) {
            return;
        }
        let array = [];
        if (this.state.parentId !== 0 || this.state.theme === "index") {
            array.push({
                "value": 0,
                "label": '全部',
            });
        }
        for (let item of list) {
            if (this.state.parentId !== 0) {
                if (item.parentId === this.state.parentId) {
                    array.push({
                        "value": item.id,
                        "label": item.translate,
                    });
                }
            } else {
                array.push({
                    "value": item.id,
                    "label": item.translate,
                });
            }
        }

        this.setState({
            district: array,
        })
    }
    doFormatData(list) {
        if (!list || list.length === 0) {
            return;
        }
        let array = [];
        if (this.state.parentId !== 0 || this.state.theme === "index") {
            array.push({
                "value": 0,
                "label": '全部',
            });
        }
        for (let item of list) {
            if (this.state.parentId !== 0) {
                if (item.parentId === this.state.parentId) {
                    array.push({
                        "value": item.id,
                        "label": item.translate,
                    });
                }
            } else {
                array.push({
                    "value": item.id,
                    "label": item.translate,
                });
            }
        }
        return array;
    }
    componentWillUnmount = () => {
        this.setState = (state,callback)=>{
          return;
        };
    }
    render() {
        const CustomChildren = props => (
            <div>
                {theme === "form" &&
                    <div
                        onClick={props.onClick}
                        className={props.placeholderClass}
                    >
                        {props.extra === '请选择' ? (props.translate || props.placeholder) : props.extra}
                    </div>
                }
                {theme === "index" &&
                    <div
                        onClick={props.onClick}
                        className={"picker1"}
                    >
                        <div className="item">
                            {props.extra === '请选择' ? (props.translate || props.placeholder) : props.extra}
                            <img className="bor" src={require('../../assets/img/icon_sy_xl.png')} alt=""/>
                        </div>
                    </div>
                }
                {theme === "interview" &&
                    <div onClick={props.onClick} className={"filterInterview"}>
                        <div className="item1">取消</div>
                    </div>
                }
            </div>

        );
        let { district, disValue, translate, placeholder, placeholderClass, theme } = this.state;
        // 学校特殊处理
        if (district.length === 0 && !this.state.attribute && this.props.array && this.props.array.length > 0) {
            district = this.doFormatData(this.props.array);
        }
        return (
            <div>
                <Picker
                    data={district}
                    cols={1}
                    // className={className || "forss"}
                    dismissText={"取消"}
                    okText={"确认"}
                    title={"请选择"}
                    value={disValue}
                    extra={translate || placeholder}
                    onChange={this.change.bind(this)}>

                    <CustomChildren theme={theme} placeholder={placeholder} placeholderClass={placeholderClass} translate={translate} />
                    {/* <List.Item arrow="horizontal" className={className}>{placeholder}</List.Item> */}
                </Picker>
            </div>
        )
    };
    change(value) {
        this.setState({ disValue: value });
        if (this.state.theme === 'interview') {
            // 找出对应的label也返回
            const listTemp = this.state.district;
            for (let i = 0; i < listTemp.length; i++) {
                const t = listTemp[i];
                if (t.value === value[0]) {
                    this.props.onChange(value[0], t.label);
                    break;
                }
            }
        } else {
            this.props.onChange(value[0]);
        }
    }
}
const mapState = (state) => ({
    city: state.login.city,
    userInfo: state.login.userInfo,
})

const mapDispatch = (dispatch) => ({
});

export default connect(mapState, mapDispatch)(SinglePicker);