import { Button, Toast } from 'antd-mobile';
import { connect } from 'react-redux';
import React, { Component, Fragment } from 'react';
import { BUTTON_SIGNUP,BUTTON_SIGNUPED } from '../../assets/style/common';
import { ConfirmModal, ShowAlert, FullfillModal } from '../../components/confirmModal/confirmModal';
import MyDialog from '../../components/myDialog/myDialog';
import { constants } from '../../pages/login/store';
import Api from '../../utils/Api';
const getApi = Api.getInstance();

class SignUpButton extends Component {

  constructor(props) {
    super(props);
    this.state = {
        index: this.props.index,
        signUpStatus: this.props.signUpStatus,
        loadingState: this.props.loadingState,
        position: this.props.position,
        recommendId: this.props.recommendId,
        modal: false,
        fkSignUpId: this.props.fkSignUpId || 0,
    }
  }
  componentDidMount() {

  }
  render() {
    let { modal, signUpStatus } = this.state;
    let { index, loadingState, position } = this.props;
    return (
        <Fragment>
            {/* 职位详情 */}
            {index ==='positionDetails' && <div /* className="fixed_bottom_btns" */>
            {(signUpStatus===0 || signUpStatus===-1 || signUpStatus===-2) && <Button loading={loadingState} style={BUTTON_SIGNUP} onClick={this.publishSignUp.bind(this)}>{'报名参加'}</Button>}
            {(signUpStatus===1) && <Button loading={loadingState} style={BUTTON_SIGNUP} onClick={this.cancelSignUp.bind(this)}>{'取消报名'}</Button>}
            {(signUpStatus===3) && <Button loading={loadingState} style={BUTTON_SIGNUPED}>{'已报名'}</Button>}
            </div>}
            {modal && 
            <MyDialog name={"signUp"} modal={modal} maxPrice={position.maxPrice} 
              shareFlag={position.shareFlag} 
              onClose={this.onCloseClick.bind(this)} 
              sure={this.sureClick.bind(this)} 
              toSignUpList={() => this.props.toSignUpList()}></MyDialog>}
        </Fragment>
    );
  }
  
	publishSignUp() {
		let userInfo = this.props.userInfo;
		var mobile = userInfo.mobile;
		if (!mobile) {
      if (this.props.toLoginPage) {
        this.props.toLoginPage();
      }
			return;
		}

		if (this.state.signUpStatus === 1) {
			Toast.fail("您已投递过该职位", 1);
			return false;
    }
    if (userInfo.gender === 0 ||
      !userInfo.photo || !userInfo.name ||
      !userInfo.birthday || userInfo.schoolId === 0 ||
      userInfo.province === 0 || userInfo.city === 0 ||
      userInfo.qualification === 0 
    ) {
      FullfillModal(" ", "请完善基本信息？", () => {
        this.setState({
          loadingState: true,
        }, () => {
          this.props.toBaseInfoPage();
        })
      });
    } else {
      // 是否确认报名？
      ConfirmModal(" ", "确定要报名该职位吗？", () => {
        this.setState({
          loadingState: true,
        }, () => {
          if (this.state.position.margin > 0) {
            this.setState({
              loadingState: false,
            }, () => {
              let params = {};
              params.margin = this.state.position.margin;
              params.id = this.state.position.id;
              params.recommendId = this.state.recommendId || null;
              this.props.setMarginParams(params);
    
              if(this.props.toPayMarginPage) {
                this.props.toPayMarginPage();
              }
            })
            return;
          }
          getApi.doPublishSignUp({
            fkPositionId: this.state.position.id,
            jobType: 0,
            token: userInfo.token,
            userId: userInfo.userId,
            recommendId: this.state.recommendId || null,
          }).then((res) => {
            if (res.success) {
              this.setState({
                signUpStatus: 1,
                modal: true,
                fkSignUpId: res.module || 0,
              })
            }
          }).catch((res) => {
          }).finally((res) => {
            this.setState({
              loadingState: false,
            });
          });
        })
      });
    }
  }
  cancelSignUp() {
		let userInfo = this.props.userInfo;
		var mobile = userInfo.mobile;
		if (!mobile) {
      if (this.props.toLoginPage) {
        this.props.toLoginPage();
      }
			return;
		}
		if (this.state.signUpStatus !== 1) {
			Toast.fail("报名状态不对", 1);
			return false;
		}
		ConfirmModal(" ", "确定要取消报名吗？", () => {
			this.setState({
				loadingState: true,
			}, () => {
				getApi.doCancelSignUp({
					fkPositionId: this.state.position.id,
					jobType: 0,
					token: userInfo.token,
          userId: userInfo.userId,
          fkSignUpId: this.state.fkSignUpId,
				}).then((res) => {
					if (res.success) {
						this.setState({
							signUpStatus: -2,
						})
					}
				}).catch((res) => {
				}).finally((res) => {
					this.setState({
						loadingState: false,
					});
				});
			})
		});
  }
  onCloseClick() {
    this.setState({ modal: false });
    if (this.props.position.shareFlag === 1) {
      ShowAlert("已报名!", "稍后客服将与您确认报名信息，请保持联系畅通呦~");
    }
  }
  sureClick() {
    if (!this.props.userInfo.mobile) {
      if(this.props.toLoginPage) {
        this.props.toLoginPage();
      }
			return;
		}
		if (this.props.jumpToPositionShare) {
			this.props.setPosition(this.state.position);
			this.props.jumpToPositionShare();
		}
    this.setState({ modal: false });
  }
}
const mapState = (state) => ({
    userInfo: state.login.userInfo,
})

const mapDispatch = (dispatch) => ({
	setMarginParams(value) {
		dispatch({
			type: constants.SET_MARGIN_PARAMS,
			payload: value
		})
	}
});

export default connect(mapState, mapDispatch)(SignUpButton);