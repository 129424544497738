import React, { Component } from 'react';
import { connect } from 'react-redux';
import TopBar from '../../../components/top-bar/TopBar';
import { BUTTON_PRIMARY } from '../../../assets/style/common';
import { Button, Toast } from 'antd-mobile';
import { constants } from '../../login/store';
/* import { Dictionary, dictionaryList } from '../../../utils/Dictionary';
const getDictionary = Dictionary.getInstance; */
import FilterPicker from '../../../components/filter-picker/filterPicker';
import SinglePicker from '../../../components/single-picker/singlePicker';
import Validate from '../../../utils/Validate';
import Api from '../../../utils/Api';
import MyDialog from '../../../components/myDialog/myDialog';
const getApi = Api.getInstance();

class WorkExpect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loadingState: false,
            typeArray: [{
                value: 0,
                label: '无'
            },
            {
                value: 1,
                label: '有'
            }],
            WorkExpect: null,
            loading: false,
            modal: false,
            chooseList: [],
            dataList: []
        }
        this.validate = this.initValidate();
    }

    render() {
        let { loadingState, typeArray, WorkExpect, loading, modal, chooseList, dataList } = this.state;
        return (
            <div className="bg-fff">
                <TopBar title={"工作期望"}></TopBar>
                {loading &&
                    <div className="form-wrap">
                        <div className="item">
                            <div className="mt">兼职经验</div>
                            <div className="mc">
                                <div className="input">
                                    <FilterPicker theme={"work"} array={typeArray} placeholderClass={"picker"} placeholder={"请选择兼职经验"} translate={WorkExpect.experienceFlag === 0 ? '无' : '有'} onChange={(item) => { WorkExpect.experienceFlag = item; this.setState({ WorkExpect: WorkExpect }) }}></FilterPicker>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="mt">兼职周期</div>
                            <div className="mc">
                                <div className="input">
                                    <SinglePicker attribute={"cycle"} placeholder={"请选择兼职周期"} placeholderClass={"picker"} translate={WorkExpect.cycleName} onChange={(e) => { WorkExpect.cycle = e; this.setState({ WorkExpect: WorkExpect }) }}></SinglePicker>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="mt">空闲时间</div>
                            <div className="mc">
                                <div className="input"><input type="text" className="ipt" maxLength={20} placeholder="请输入空闲时间(例如:周五下午5点～8点)" value={WorkExpect.freeTime || ""} onChange={(text) => { WorkExpect.freeTime = text.target.value; this.setState({ WorkExpect: WorkExpect }) }} /></div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="mt">期望职位</div>
                            <div className="mc">
                                <div className="input" onClick={() => { this.setState({ modal: true }) }} >
                                    {(chooseList && chooseList.length > 0) ? <div>{chooseList.map((item, index) => {
                                        return (
                                            <div key={index}>
                                                {index === chooseList.length - 1 ?
                                                    <div type="text" className="ipt">
                                                        {item.translate}
                                                    </div> :
                                                    <div type="text" className="ipt">
                                                        {item.translate}/
                                                            </div>
                                                }
                                            </div>
                                        )
                                    })}</div> :
                                        <div type="text" className="ipt">请选择期望职位</div>
                                    }
                                </div>
                            </div>
                        </div>
                        <MyDialog name={"workExpect"} modal={modal} onClose={() => { this.setState({ modal: false }) }} chooseList={chooseList} dataList={dataList} chooseOrCance={this.chooseOrCancel.bind(this)}></MyDialog>
                        <Button loading={loadingState} style={BUTTON_PRIMARY} onClick={this.submit.bind(this)}>保存</Button>
                    </div>
                }
            </div>

        )
    };
    componentDidMount() {
        this.requestForWorkExpect();
        let params = { userId: this.props.userInfo.userId,title:this.props.shareGongZhongHao.title, clientType: this.props.clientType }
        document.addEventListener('WeixinJSBridgeReady', getApi.doShareWap(params), false);
    }
    requestForWorkExpect() {
        let userInfo = this.props.userInfo;
        let loading = document.getElementById('i-loading');
        loading.style.display = 'inline';

        this.setState({
            /* province: userInfo.province,
            provinceName: userInfo.provinceName,
            city: userInfo.city,
            cityName: userInfo.cityName,
            area: userInfo.area,
            areaName: userInfo.areaName, */
            WorkExpect: {
                jobState: userInfo.jobState,
                jobStateName: userInfo.jobStateName,
                experienceFlag: userInfo.experienceFlag,
                cycle: userInfo.cycle,
                cycleName: userInfo.cycleName,
                freeTime: userInfo.freeTime,
            },
            loading: true,
        })
        // 期望职位
        let array = [];
        if (userInfo.orderTypeOne) {
            array.push({ id: userInfo.orderTypeOne, translate: userInfo.orderTypeOneName });
        }
        if (userInfo.orderTypeTwo) {
            array.push({ id: userInfo.orderTypeTwo, translate: userInfo.orderTypeTwoName });
        }
        if (userInfo.orderTypeThree) {
            array.push({ id: userInfo.orderTypeThree, translate: userInfo.orderTypeThreeName });
        }
        
        this.setState({
            chooseList: array
        })

        
        if (!userInfo.token || !userInfo.userId) {
            return;
        }
        getApi.queryDictionary({
            token: userInfo.token,
            userId: userInfo.userId,
            attribute: "jobType",
        }).then((res) => {
            if (res.success) {
                // 处理选中的回显
                let dataList = res.module || [];
                if (dataList.length === 0) {
                    return;
                }
                let chooseList = array;
                if (chooseList.length !== 0) {
                    for (let choose of chooseList) {
                        for (let aItem of dataList) {
                            if (aItem.id === choose.id) {
                                aItem.selected = 1;
                                break;
                            }
                        }
                    }
                } else {
                    for (let aItem of dataList) {
                        aItem.selected = 0;
                    }
                }
                this.setState({
                    dataList: dataList,
                })
            }
        }).catch((res) => {
            console.log();
        }).finally((res) => {
            
            loading.style.display = 'none';
        })

        /* if (dictionaryList.length === 0) {
            getDictionary().setDictionaryList();
        }
        let dataList = JSON.parse(JSON.stringify(dictionaryList["jobType"])) || []; */
    };
    submit() {
        let userInfo = this.props.userInfo;
        let promise = {};
        let params = this.state.WorkExpect;
        params.jobStatus = 3365; // 没有选择，先写死
        params.jobStatusName = "兼职";
        params.token = userInfo.token;
        params.userId = userInfo.userId;
        let expectPosition = this.state.chooseList;
        if (expectPosition.length === 1) {
            params.orderTypeOne = expectPosition[0].id;
            params.orderTypeTwo = null;
            params.orderTypeThree = null;
        } else if (expectPosition.length === 2) {
            params.orderTypeOne = expectPosition[0].id;
            params.orderTypeTwo = expectPosition[1].id;
            params.orderTypeThree = null;
        } else if (expectPosition.length === 3) {
            params.orderTypeOne = expectPosition[0].id;
            params.orderTypeTwo = expectPosition[1].id;
            params.orderTypeThree = expectPosition[2].id;
        } else if (expectPosition.length === 0) {
            params.orderTypeOne = null;
            params.orderTypeTwo = null;
            params.orderTypeThree = null;
        }


        if (!this.validate.checkForm(params)) {
            const error = this.validate.errorList[0]
            Toast(error.msg, 1);
            return false;
        }
        promise = getApi.doModifyPartTimeWorkExpect(params);
        this.setState({
            loadingState: true,
        }, () => {
            promise.then((res) => {
                if (res.success) {
                    this.props.setUserInfo(res.module);
                    Toast.success("保存成功", 1, () => {
                        this.props.history.goBack();
                    });
                }
            }).catch((res) => {
                console.log(res);
            }).finally((res) => {
                this.setState({
                    loadingState: false,
                });
            });
        });
    };
    initValidate() {
        const rules = {}
        const messages = {}
        return new Validate(rules, messages);
    };
    chooseOrCancel(value) {
        var id = value.id;
        let dataList = this.state.dataList;
        var item = null;
        for (let aItem of dataList) {
            if (aItem.id === id) {
                item = aItem;
                break;
            }
        }
        let chooseList = this.state.chooseList;

        if ((!item.selected || !item.selected === 1) && chooseList.length >= 3) {
            Toast.fail("最多选择3个", 1)
            return;
        }

        if (item.selected === 1) {
            item.selected = 0;
        }
        else {
            item.selected = 1;
        }

        chooseList = [];
        for (let aItem of dataList) {
            if (aItem.selected === 1) {
                chooseList.push(aItem);
            }
        }
        this.setState({
            chooseList: chooseList,
            dataList: dataList
        })
    };
}

const mapState = (state) => ({
    resume: state.login.resume,
    userInfo: state.login.userInfo,
    shareGongZhongHao:state.login.shareGongZhongHao,
    clientType: state.login.clientType,
})

const mapDispatch = (dispatch) => ({

    setResume(item) {
        dispatch({
            type: constants.RESUME,
            payload: item
        })
    },
    setUserInfo(item) {
        dispatch({
            type: constants.USERINFO,
            payload: item
        })
    },
});

export default connect(mapState, mapDispatch)(WorkExpect);