import React, { Component } from 'react';
import { connect } from 'react-redux';
import { constants, actions } from '../login/store';
import { ParseParams } from '../../components/params/params';
import Api from '../../utils/Api';
const getApi = Api.getInstance();

class Start extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }

    }
    async componentDidMount() {
        console.log('window.location ' + window.location);
        let loc = window.location.search;
        let params = {};
        if (loc) {
            // loc = loc.substring(loc.indexOf('?'), loc.indexOf('#'))
            params = ParseParams(loc);
        }
        
        let paramsNew = ParseParams(this.props.history.location.search);
        params = {...params, ...paramsNew};
        console.log('locPa '+JSON.stringify(params));
        // 保存clientType到redux
        this.props.setClientType({ clientType: params.clientType || 7 });
        this.getSetting();
        console.log('params' + JSON.stringify(params));
        try {
            await this.login(params);
        }
        finally {
            let { state = '', id = 0, fkUserId } = params;
            let { userInfo } = this.props;
            if (state.indexOf("mall") !== -1) {
                if (!userInfo.mobile) {
                    this.props.history.push("/login?type=" + state);
                }
                else {
                    console.log("https://www.huitao520.top/index.html#start?state=" + state.substr(5) + "&token=" + userInfo.token + "&userId=" + userInfo.userId);
                    window.location = "https://mall.level-job.com/index.html#start?state=" + state.substr(5) + "&token=" + userInfo.token + "&userId=" + userInfo.userId;
                    //  window.location = "https://mall.level-job.com/index.html#start?state="+state.substr(5)+"&token="+userInfo.token+"&userId="+userInfo.userId;
                }
            }
            if (state === 'sharedPosition' || state === 'sharedWap') {
                this.props.history.push('/' + state + '?id=' + id + '&fkUserId=' + fkUserId)
            }
            else if (state === 'signup') {
                this.props.history.push('/signUpList');
            }
            else if (state === 'resume') {
                this.props.history.push('/personal');
            }
            else if (state === 'sharedModuleJob') {
                // 暑假工被分享人页面
                this.props.history.push('/sharedModuleJob?recommendId=' + fkUserId);
            }
            else if(state === 'poster' || state === "expresser"){
                if (!userInfo.mobile) {
                    this.props.history.push("/login?type=" + state);
                }
                else {
              // 寄快递
                    window.location = "http://mall.huitao560.top/index.html#start?state="+state+"&token=" + userInfo.token + "&userId=" + userInfo.userId;
                }
            } 
            else {
                this.props.history.push('/index')
            }
        }
    }
    componentWillMount() {
        let loading = document.getElementById('i-loading')
        if (loading) {
            loading.style.display = 'none'
        }
    }
    render() {
        return (
            <div></div>
        )
    };
    getSetting() {
        getApi.getSetting().then((res) => {
            if (res.success) {
                this.props.setSetting(res.module);
            }
        })
    };
    async login(params) {
        console.log('start params' + JSON.stringify(params));
        // 如果存在code就是code登录，否则用token登录
        if (params.code) {
            console.log('start code login');
            let res = await getApi.codeLogin({
                code: params.code,
                uuid: "unknown",
                clientType: params.clientType || 7,
                recommend: params.recommend,
            });
            if (res.module) {
                this.props.setUserInfo(res.module);
            }
            // console.log('start userInfo1'+JSON.stringify(res.module));   
        }
        else {
            console.log('start token login');
            let userInfo = this.props.userInfo;
            console.log(userInfo);
            if (userInfo && userInfo.token) {
            let res = await getApi.tokenLogin({
                'token': userInfo.token,
                'userId': userInfo.userId || 'null',
                'uuid': 'unknown',
                'clientType': userInfo.clientType || 7,
                'recommend': params.recommend,
            });
            if (res.module) {
                this.props.setUserInfo(res.module);
            }
            // console.log('start userInfo2'+JSON.stringify(res.module));   
            }
        }
    };


}

const mapState = (state) => ({
    userInfo: state.login.userInfo,
})

const mapDispatch = (dispatch) => ({
    setSetting(value) {
        dispatch({
            type: constants.SET_SETTING,
            payload: value,
        })
    },
    setClientType(value) {
        dispatch({
            type: constants.CLIENT_TYPE,
            payload: value,
        })
    },
    setUserInfo(value) {
        dispatch({
            type: constants.USERINFO,
            payload: value
        })
    },
    setDictionaryList() {
        dispatch(actions.doUpdateDictionaryList())
    }
});

export default connect(mapState, mapDispatch)(Start);