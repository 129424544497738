import React, { Component } from 'react';
import { connect } from 'react-redux';
import { constants} from '../../login/store';
import { BUTTON_MARGIN } from '../../../assets/style/common';
import TopBar from '../../../components/top-bar/TopBar';
import { Button} from 'antd-mobile';
// import wx from 'weixin-js-sdk';
import Api from '../../../utils/Api';
const getApi = Api.getInstance();

class PayMargin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loadingState: false,
        }

    }
    componentDidMount() {
        let params = { userId: this.props.userInfo.userId,title:this.props.shareGongZhongHao.title, clientType: this.props.clientType }
        document.addEventListener('WeixinJSBridgeReady', getApi.doShareWap(params), false);
    }

    render() {
        let { loadingState } = this.state;
        const { marginParams, userInfo } = this.props;
        return (
            <div className="bg-f4f5f7">
                <TopBar title={"保证金"}></TopBar>
                <div className="paymargin">
                    <div className="head">
                        <div className="info">
                            <div className="label">保证金</div>
                            <div className="price">
                                <div>￥</div>
                                <div className="num">{marginParams.margin}</div>
                            </div>
                        </div>
                        <div className="tips">
                            <i className="ico"></i>
                            <div className="txt">工作日上岗后，缴纳的保证金将退回到微信钱包</div>
                        </div>
                    </div>

                    <div className="rules">
                        <div className="txt">该职位需要缴纳保证金{marginParams.margin}元。</div>
                        <div className="txt">支付渠道建议使用
	                        <span className="strong">微信支付</span>确保账户有足够的钱款哦</div>
                    </div>

                    <div className="sign-up">
                        <div className="label">报名人</div>
                        <div className="name">{userInfo.name}</div>
                        <div className="phone">{userInfo.mobile}</div>
                    </div>

                    <div className="tip-item">
                        <div className="txt">为确保录用后准确联系，请确认报名信息无误</div>
                    </div>

                    <div className="agreement">
                        <i className="ico"></i>
                        <div className="txt">报名该职位须同意LEVEL实习
	                            <span className="span" onClick={() => { this.props.history.push("/privacyPolicy") }}>《隐私政策》</span>
                        </div>
                    </div>

                    <div className="tip-item">
                        <div className="txt">缴纳保证金前请仔细阅读LEVEL实习《隐私政策》中保证金相关的条款。</div>
                    </div>
                    <Button loading={loadingState} style={BUTTON_MARGIN} onClick={this.submit.bind(this)}>报名并提交保证金</Button>

                    <div className="fixed-bottom-zw"></div>
                </div>
            </div>
        )
    };

    onBridgeReady(wxParams){
        console.log('wxParams '+JSON.stringify(wxParams));
        window.WeixinJSBridge.invoke(
            'getBrandWCPayRequest', {
               "appId": wxParams.appId,
               "timeStamp": wxParams.timeStamp,
               "nonceStr":"404",
               "package": wxParams.package,
               "signType": "MD5",
               "paySign": wxParams.paySign
            },
            (res) => {
                console.log('resss:'+JSON.stringify(res));
                if(res.err_msg === "get_brand_wcpay_request:ok" ){
                    // 使用以上方式判断前端返回,微信团队郑重提示：
                    //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
                    this.queryOrderAfterPay(wxParams.tradeId);
                } 
            });
     };
    queryOrderAfterPay(id) {
        console.log('id ', id);
        getApi.doQueryOrder({
            'token': this.props.userInfo.token,
            'userId': this.props.userInfo.userId,
            'id': id,
            'tradeType': 'JSAPI',
          }).then((res) => {
            //   console.log('queryOrder'+JSON.stringify(res));
            if (res.success) {
                this.props.history.replace("/payMarginSuccess");
            }
          }).catch((res) => {
            console.log(res);
          }).finally((res) => {
            // console.log('queryOrder finally'+JSON.stringify(res));
          });
    };
    submit() {

        // this.props.history.replace("/payMarginSuccess");
        // return;
        var params = {};
        let { userInfo, marginParams, clientType } = this.props;
        params.token = userInfo.token;
        params.userId = userInfo.userId;
        params.money = marginParams.margin;
        params.tradeType = 'JSAPI';
        params.fkPositionId = marginParams.id;
        params.clientType = clientType;
        params.type = 6;
        params.memo = "支付保证金";
        params.payWay = 1 ;
        params.userType = 1;
        this.setState({
            loadingState: true,
        }, () => {
            getApi.doCreateTrade(
                params
            ).then((res) => {
                if (res.success) {
                    params.id = res.module.id;
                    getApi.doCreateWxPayParams(
                        params
                    ).then((res) => {
                        if (res.success) {
                            let wxParams = res.module;
                            wxParams.tradeId = params.id;
                            getApi.doShareSignature({
                                location: window.location.href.split('#')[0],
                                clientType: clientType,
                            }).then((res) => {
                                if (res.success) {
                                    /**let configParams = {
                                        debug: true, // true:调试时候弹窗
                                        appId: 'wx15dbec991818c868', // 微信appid
                                        timestamp: res.module.timestamp, // 时间戳
                                        nonceStr: res.module.nonceStr, // 随机字符串
                                        signature: res.module.signature, // 签名
                                        jsApiList: [
                                            // 所有要调用的 API 都要加到这个列表中
                                            'chooseWXPay', // 微信支付
                                        ]
                                    };
                                    console.log('configParams'+JSON.stringify(configParams));
                                    wx.config(configParams);
                                    console.log("config");
                                    let prepay_id = wxParams.package;

                                    let chooseWXPayParams = { //发起微信支付请求
                                        timeStamp: wxParams.timeStamp, // 支付签名时间戳
                                        nonceStr: wxParams.nonceStr, // 支付签名随机串，不长于 32 位
                                        package: prepay_id, // 统一支付接口返回的prepay_id参数值
                                        signType: 'MD5', // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
                                        paySign: wxParams.paySign, // 支付签名
                                        success: function (res) {
                                            console.log(res);
                                            alert("SUCCESS====11111======!!!!!!!!");
                                            getApi.queryOrder({
                                                tradeType: "MWEB",
                                                id: params.id,
                                                token: userInfo.token,
                                                userId: userInfo.userId,
                                            }).then((res) => {
                                                if (res.success) {
                                                    alert("SUCCESS===2222======!!!!!!!!");
                                                    // this.props.navigation.replace("PayMarginSuccess");
                                                }
                                            })
                                        },
                                        cancel: function(r) {
                                            console.log('cancel'+JSON.stringify(r));
                                        },
                                        fail: function (res) {
                                            console.log('res'+JSON.stringify(res));
                                        },
                                        complete: function (res) {
                                            console.log('complete'+JSON.stringify(res));
                                        }
                                    };
                                    console.log('chooseWXPayParams'+JSON.stringify(chooseWXPayParams));
                                    wx.chooseWXPay(chooseWXPayParams);*/

                                    if (typeof WeixinJSBridge == "undefined"){
                                        if( document.addEventListener ){
                                            document.addEventListener('WeixinJSBridgeReady', this.onBridgeReady(wxParams), false);
                                        }else if (document.attachEvent){
                                            document.attachEvent('WeixinJSBridgeReady', this.onBridgeReady(wxParams)); 
                                            document.attachEvent('onWeixinJSBridgeReady', this.onBridgeReady(wxParams));
                                        }
                                    }else{
                                        this.onBridgeReady(wxParams);
                                    }
                                        
                                }
                                
                            }).catch((res) => {
                                console.log(res);
                            }).finally((res) => {
                            })
                        }
                    }).finally((res) => {
                        this.setState({
                            loadingState: false,
                        })
                    })
                }
            });
        });
    };
    componentWillMount() {
        let loading = document.getElementById('i-loading');
        if (loading) {
            loading.style.display = 'none'
        }
    }
}

const mapState = (state) => ({
    marginParams: state.login.marginParams,
    userInfo: state.login.userInfo,
    shareGongZhongHao:state.login.shareGongZhongHao,
    clientType: state.login.clientType,
})

const mapDispatch = (dispatch) => ({
    setUserInfo(value) {
        dispatch({
            type: constants.USERINFO,
            payload: value
        })
    },

});

export default connect(mapState, mapDispatch)(PayMargin);