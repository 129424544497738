import { Modal } from 'antd-mobile';
const alert = Modal.alert;

export function ConfirmModal(title,message,confirm) {
    alert(title, message, [
        { text: '取消', onPress: () => {}},
        { text: '确定', onPress: confirm},
    ])
} 

export function FullfillModal(title,message,confirm) {
    alert(title, message, [
        { text: '取消', onPress: () => {}},
        { text: '去完善', onPress: confirm},
    ])
} 

export function ShowAlert(title,message,confirm) {
    alert(title, message, [
        { text: '知道了!', onPress: confirm},
    ])
} 
