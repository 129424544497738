import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Toast } from 'antd-mobile';
import { BUTTON_PRIMARY } from '../../../assets/style/common';
import MyImagePicker from '../../../components/image-picker/myImagePicker';
import Validate from '../../../utils/Validate';
import Api from '../../../utils/Api';
/* import {Dictionary,dictionaryList} from '../../../utils/Dictionary';
const getDictionary = Dictionary.getInstance; */
import TopBar from '../../../components/top-bar/TopBar';
const getApi = Api.getInstance();

class Feedback extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedId: 0,
            feedback: {},
            dataList: [],
            loading:false,
        }
        this.validate = this.initValidate();
    }



    render() {
        let { selectedId, feedback, dataList,loading } = this.state || {};
        return (
            <div className="bg-fff">
                <TopBar title={"意见反馈"}></TopBar>
                <div className="form-wrap">
                    <div className="tag-item item">
                        {(dataList && dataList.length > 0) && dataList.map((item, index) => {
                            return (
                                <div className={selectedId === item.id ? "tag active" : "tag"} onClick={this.chooseItem.bind(this,item.id)} key={index}>{item.translate}</div>
                            )
                        })}
                    </div>
                    <div className="textarea item">
                        <textarea className="ipt" placeholder="请描述具体建议内容..." onChange={(e)=>{feedback.content = e.target.value;this.setState({feedback:feedback})}}></textarea>
                    </div>
                    <div className="item">
                        <div className="mt">上传图片</div>
                        <div className="mc">
                            <div className="upload-items">
                                <ul>
                                <MyImagePicker className="upload" url={""} changeImage={(url) => {feedback.imagePath = url;this.setState({feedback:feedback})}}></MyImagePicker>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="item">
                        <div className="mt">联系电话</div>
                        <div className="mc">
                            <div className="input"><input type="text" className="ipt" placeholder="请输入联系电话" onChange={(e)=>{feedback.contactPhone = e.target.value;this.setState({feedback:feedback})}}/></div>
                        </div>
                    </div>

                    <Button loading={loading} style={BUTTON_PRIMARY} onClick={this.submitData.bind(this)}>保存</Button>

                </div>

            </div>
        )
    };
    componentDidMount() {
        let userInfo = this.props.userInfo;
        if (!userInfo.token || !userInfo.userId) {
            return;
        }
        getApi.queryDictionary({
            token: userInfo.token,
            userId: userInfo.userId,
            attribute: "feedback",
        }).then((res) => {
            if (res.success) {
                let dataList = res.module || [];
                if (!dataList || dataList.length === 0) {
                    return;
                }
                this.setState({
                    dataList: dataList
                });
            }
        }).catch((res) => {
            console.log();
        }).finally((res) => {
        })

        /* if(dictionaryList.length === 0){
            getDictionary().setDictionaryList();
        }
        let dataList = dictionaryList["feedback"] || []; */
        
		// 分享公众号
        let params = { userId: this.props.userInfo.userId,title:this.props.shareGongZhongHao.title, clientType: this.props.clientType }
        document.addEventListener('WeixinJSBridgeReady', getApi.doShareWap(params), false);
    };
    componentWillMount() {
        let loading = document.getElementById('i-loading');
        if (loading) {
            setTimeout(() => {
                loading.style.display = 'none'
            }, 1000)
        }
    }
    chooseItem(value) {
        this.setState({
            selectedId: value
        })
    };
    submitData() {
        const params = this.state.feedback;
        params.type = this.state.selectedId;
        params.token = this.props.userInfo.token;
        params.userId = this.props.userInfo.userId;
        if (!this.validate.checkForm(params)) {
            const error = this.validate.errorList[0]
            Toast.fail(error.msg,1)
            return false;
        }
        this.setState({
            loading: true,
        }, () => {
            getApi.doPublishFeedback(
                params
            ).then((res) => {
                if (res.success) {
                    Toast.success("反馈成功",1,()=>{
                        this.props.history.goBack();
                    })
                }
            }).catch((reason)=>{
                console.log(reason);
            }).finally((res) => {
                this.setState({
                    loading: false,
                });
            });
        });
    };
    initValidate() {
        const rules = {
            type: {
                required: true,
                min: 1
            },
            contactPhone: {
                required: true,
                tel: true,
            },
            content: {
                required: true,
            },
            imagePath: {
                required: true,
            },
        }

        const messages = {
            type: {
                required: '请选择反馈类型',
                min: '请选择反馈类型'
            },
            contactPhone: {
                required: '请输入联系电话',
                tel: '手机号需11位',
            },
            content: {
                required: '请输入反馈内容',
            },
            imagePath: {
                required: '请上传图片',
            },
        }

        return new Validate(rules, messages);
    };
}

const mapState = (state) => ({
    userInfo:state.login.userInfo,
    shareGongZhongHao:state.login.shareGongZhongHao,
    clientType: state.login.clientType,
})

const mapDispatch = (dispatch) => ({

})

export default connect(mapState, mapDispatch)(Feedback);