import axios from 'axios';
import wx from 'weixin-js-sdk';

// requestUrl: 'https://work.goh8.com',
    // var requestUrl ='http://192.168.1.121:8082';
// var requestUrl ='http://192.168.1.101:8080';
// var requestUrl = 'http://jianzhi.goh8.com:8080';
// var requestUrl = 'https://www.huitao520.top';
var requestUrl = 'https://merchant.level-job.com';
// var requestUrl = 'http://192.168.1.123:8082';

// var clientType = 7;

export default class Api {

    static getInstance() {

        if (!Api.instance) {

            Api.instance = new Api();
        }
        return Api.instance;
    }
    // 公众号微信登录
    codeLogin(params) {
        return this.callback('/api/userUnionLogin/codeLogin.action', params);
    }
    // 公众号token登录
    tokenLogin(params) {
        return this.callback('/api/userUnionLogin/tokenLogin.action', params);
    }
    // 公众号手机号登录 oo
    mobileLogin(params) {
        return this.callback('/api/userUnionLogin/mobileLogin.action', params);
    }
    // 公众号绑定手机号 oo
    bindMobile(params) {
        return this.callback('/api/userUnionLogin/bindMobile.action', params);
    }
    //修改经纬度信息    没有使用
    doModifyLongAndLat(params) {
        return this.callback('/api/userInfo/doModifyLongAndLat.action', params);
    }
    //修改手机号
    doModifyWxMobile(params) {
        return this.callback('/api/userUnionLogin/modifyMobile.action', params);
    }
    //发送验证码
    doGetVeryCode(params) {
        return this.callback('/api/userUnionLogin/sendVeryCode.action', params);
    }
    // 数据字典数据
    queryDictionary(params) {
        return this.callback('/api/thirdparty/queryDictionary.action', params);
    }

    //我的需求报名者简历    oo
    doModifyNewUserInfoResume(params) {
        return this.callback('/api/userInfo/doModifyBasicInformationNew.action', params);
    }
    //修改工作期望  oo
    doModifyWorkExpect(params) {
        return this.callback('/api/userWorkExpect/doModifyWorkExpect.action', params);
    }
    //修改工作期望(兼职)    00
    doModifyPartTimeWorkExpect(params) {
        return this.callback('/api/userInfo/doModifyWorkExpect.action', params);
    }
    //查看认证信息  oo
    doAuthenticationInfo(params) {
        return this.callback('/api/userAuthentication/doAuthenticationInfo.action', params);
    }
    //添加修改认证信息  oo
    doUserAuthentication(params) {
        return this.callback('/api/userAuthentication/doPublishUserAuthentication.action', params);
    }
    //个人中心  oo
    doPersonalInfo(params) {
        return this.callback('/api/userInfo/doPersonalInfo.action', params);
    }
    //意见反馈 oo
    doPublishFeedback(params) {
        return this.callback('/api/feedback/doPublishFeedback.action', params);
    }
    //举报/职位反馈
    doPublishReport(params) {
        return this.callback('/api/report/doPublishReport.action', params);
    }

    /**
     * 职位模块
     */
    //本地列表  oo
    doPositionList(params) {
        return this.callback('/api/position/doPositionList.action', params);
    }
    //本地列表  oo
    doNewPositionList(params) {
        return this.callback('/api/position/doNewPositionList.action', params);
    }
    //本地详情  oo
    doPositionInfo(params) {
        return this.callback('/api/position/doPositionInfo.action', params);
    }
    /**
     * 报名模块
     */
    //本地报名  oo
    doPublishSignUp(params) {
        return this.callback('/api/signUp/doPublishSignUp.action', params);
    }
    // 取消报名
    doCancelSignUp(params) {
        return this.callback('/api/signUp/doCancelSignUp.action', params);
    }
    //我的兼职本地兼职列表  oo
    doSignUpList(params) {
        return this.callback('/api/signUp/doSignUpList.action', params);
    }
    //我的兼职详情  oo
    doSignUpInfo(params) {
        return this.callback('/api/signUp/doSignUpInfo.action', params);
    }
    //生成订单
    doPublishMarginTrade(params) {
        return this.callback('/api/userTrade/doPublishMarginTrade.action', params);
    }
    //我的兼职结算详情
    doPositionPayLogInfo(params) {
        return this.callback('/api/userPositionPayLog/doPositionPayLogInfo.action', params);
    }
    //本地申请支付工资
    doApplySalaryPay(params) {
        return this.callback('/api/userPositionPayLog/doApplySalaryPay.action', params);
    }
    //本地取消申请/取消退回
    doCancel(params) {
        return this.callback('/api/userPositionLog/doCancel.action', params);
    }
    //同意
    doPass(params) {
        return this.callback('/api/userPositionLog/doPass.action', params);
    }
    //拒绝
    doReject(params) {
        return this.callback('/api/userPositionLog/doReject.action', params);
    }
    //面试/录用/托管
    doPositionLogInfo(params) {
        return this.callback('/api/userPositionLog/doPositionLogInfo.action', params);
    }
    /**
     * 支付模块
     */
    // 微信支付
    createWxPayParams(params) {
        return this.callback('/api/userTrade/createWxPayParams.action', params);
    }
    // 微信支付查询结果
    queryOrder(params) {
        return this.callback('/api/userTrade/queryOrder.action', params);
    }
    //提现记录
    doUserTakeCashList(params) {
        return this.callback('/api/userTakeCash/doUserTakeCashList.action', params);
    }
    //添加提现
    doPublishUserTakeCash(params) {
        return this.callback('/api/userTakeCash/doPublishUserTakeCash.action', params);
    }
    //我的钱包  oo
    doAccountDetailList(params) {
        return this.callback('/api/userAccountDetail/doAccountDetailList.action', params);
    }
    /**
     * 分享模块
     */
    // 分享有奖
    getShareInfo(params) {
        return this.callback('/api/share/doSharePrize.action', params);
    }
    // 分享有奖
    getPromotionSignUp(params) {
        return this.callback('/api/share/doPromoteSignUp.action', params);
    }
    // 职位分享详情
    // getShareSignUpInfo(params) {
    getPositionShareInfo(params) {
        return this.callback('/api/share/getPositionShareInfo.action', params);
    }
    // getSharedPositionInfo(params) {
    getPositionSharedInfo(params) {
        return this.callback('/api/share/getPositionSharedInfo.action', params);
    }
    // 被分享人的暑假工
    getSharedModuleJobInfo(params) {
        return this.callback('/api/share/getSharedModuleJobInfo.action', params);
    }
    getSharedWap(params) {
        return this.callback('/api/share/getSharedWap.action', params);
    }
    // getNewUserList(params) {
    getRewardSharedUserList(params) {
        return this.callback('/api/share/doRewardSharedUserList.action', params);
    }
    getNewUserRewardList(params) {
        return this.callback('/api/share/doNewUserRewardList.action', params);
    }
    getSharedUserList(params) {
        return this.callback('/api/share/doSharedUserList.action', params);
    }
    getTotalReward(params) {
        return this.callback('/api/share/doTotalReward.action', params);
    }
    getSetting(params) {
        return this.callback('/api/share/getSetting.action', params);
    }
    doShareCreditList(params){
        return this.callback('/api/share/doCreditList.action', params);
    }
    // 暑期工模块的职位
    getModuleJob(params) {
        return this.callback('/api/module/doPositionList.action', params);
    }
    // 取消面试。
    doUpdateInterviewStatus(params) {
      return this.callback('/api/userPositionLog/doUpdateInterviewStatus.action', params);
    }
    /**
     * 消息模块
     */
    //加载数据字典
    doUserDictionary() {
        console.log('doUserDictionary');
        // return this.callback('/api/userDictionary/doUserDictionary.action', {});
    }
    doCreateTrade(params) {
        return this.callback('/api/trade/doCreateTrade.action', params);
      }
      doCreateWxPayParams(params) {
        return this.callback('/api/trade/createWxPayParams.action', params);
      }
      doQueryOrder(params) {
        return this.callback('/api/trade/queryOrder.action', params);
      }

    doUrl() {
        if (requestUrl === 'https://merchant.level-job.com') {
            // 真实
            return 'https://wap.level-job.com';
        }
        else if (requestUrl === 'https://www.huitao520.top') {
            // 测试
            return 'https://www.huitao520.top';
        } else if (requestUrl === 'http://192.168.1.118:8082') {
            // 本地
            return 'http://192.168.1.118:8082';
        }
    }
    doServiceUrl() {
        return requestUrl;
    }
    // 分享职位
    doSharePosition(paramsFromPosition) {
        // console.log('doSharePosition '+JSON.stringify(paramsFromPosition));
        this.doShareSignature({
            location: window.location.href.split('#')[0],
            clientType: paramsFromPosition.clientType
        }).then((res) => {
            if (res.success) {
                let params = {
                    title: '',
                    imgUrl: 'https://cdn.huizhuanvip.com/jianzhi/app/images/icon_shar_fenx.png',
                };
                let url = this.doUrl();
                // 根据环境处理域名
                params.desc = paramsFromPosition.positionName + paramsFromPosition.salary + '元/' + paramsFromPosition.salaryPer + paramsFromPosition.salaryPaidType;
                params.link = url + '/index.html#/start?state=sharedPosition&clientType=' + paramsFromPosition.clientType + '&fkUserId=' + paramsFromPosition.userId + '&id=' + paramsFromPosition.id;
                params.title = paramsFromPosition.title;
                this.doShareWeb(params, res);
            }
        }).catch((res) => {
            console.log(res);
        }).finally((res) => {
        });
    }
    // 分享应用（公众号）
    doShareWap(paramsFromPage) {
        // console.log('doShareWap '+JSON.stringify(paramsFromPage));
        this.doShareSignature({
            location: window.location.href.split('#')[0],
            clientType: paramsFromPage.clientType
        }).then((res) => {
            if (res.success) {
                let params = {
                    title: '',
                    imgUrl: 'https://cdn.huizhuanvip.com/jianzhi/register/1581505629404118.png',
                };
                let url = this.doUrl();
                // params.desc = "LEVEL实习，薪资高-有担保-结算快-更安全";
                // params.link = 'https://wap.level-job.com/index.html#/start?state=sharedWap&clientType='+paramsFromPage.clientType+'&fkUserId='+paramsFromPage.userId;
                // params.title = paramsFromPage.title;
                // 分享应用暂时都连接向分享暑假工
                params.desc = "LEVEL实习暑期工，薪资高-有担保-结算快，戳一戳看看吧！";
                params.link = url + '/index.html#/start?state=sharedModuleJob&clientType=' + paramsFromPage.clientType + '&fkUserId=' + paramsFromPage.userId;
                params.title = '月薪5000元的暑期兼职 钱多事少离家近 名额有限 速来报名吧~';
                params.imgUrl = 'https://cdn.huizhuanvip.com/jianzhi/app/images/icon_summer.png';
                this.doShareWeb(params, res);
            }
        }).catch((res) => {
            console.log(res);
        }).finally((res) => {
        });
    }
    // 分享暑假工
    doShareModuleJob(paramsFromModule) {
        // console.log('doShareModuleJob '+JSON.stringify(paramsFromModule));
        this.doShareSignature({
            location: window.location.href.split('#')[0],
            clientType: paramsFromModule.clientType
        }).then((res) => {
            if (res.success) {
                let url = this.doUrl();
                let params = {};
                params.desc = "LEVEL实习暑期工，薪资高-有担保-结算快，戳一戳看看吧！";
                params.link = url + '/index.html#/start?state=sharedModuleJob&clientType=' + paramsFromModule.clientType + '&fkUserId=' + paramsFromModule.userId;
                params.title = paramsFromModule.title;
                params.imgUrl = paramsFromModule.imgUrl;
                this.doShareWeb(params, res);
            }
        }).catch((res) => {
            console.log(res);
        }).finally((res) => {
        });
    }
    // 分享app
    doShareApp() {

    }
    /**
     *分享相关 
     */
    doShareSignature(params) {
        return this.callback('/api/share/doShareSignature.action', params);
    }
    doShareWeb(params, res) {
        // console.log("1:" + JSON.stringify(params));
        wx.config({
            debug: false, // true:调试时候弹窗
            appId: res.module.appId, // 微信appid
            timestamp: res.module.timestamp, // 时间戳
            nonceStr: res.module.nonceStr, // 随机字符串
            signature: res.module.signature, // 签名
            jsApiList: [
                // 所有要调用的 API 都要加到这个列表中
                'updateAppMessageShareData', // 分享到朋友
                'onMenuShareAppMessage',
                'onMenuShareTimeline',
                'onMenuShareQQ',
                'getLocation',
                'openLocation'
            ]
        })
        wx.updateAppMessageShareData({
            title: params.title, // 分享标题
            desc: params.desc, // 分享描述
            link: params.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: params.imgUrl, // 分享图标
            success: function () {
                // alert("updateAppMessageShareData")
                // 设置成功
            }
        });
        wx.onMenuShareAppMessage({
            title: params.title, // 分享标题
            desc: params.desc, // 分享描述
            link: params.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: params.imgUrl, // 分享图标
            success: function () {
                // alert("onMenuShareAppMessage")
                // 设置成功
            }
        });
        wx.onMenuShareQQ({
            title: params.title, // 分享标题
            desc: params.desc, // 分享描述
            link: params.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: params.imgUrl, // 分享图标
            success: function () {
                // alert("onMenuShareQQ")
                // 设置成功
            }
        });
        wx.onMenuShareTimeline({
            title: params.title, // 分享标题
            desc: params.desc, // 分享描述
            link: params.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: params.imgUrl, // 分享图标
            success: function () {
                // alert("onMenuShareTimeline")
                // 设置成功
            }
        });
    }
    /**
     * 聊天使用的
     */
    // 调用服务器获得极光登录参数
    getJiGuangParams(params) {
        return this.callback('/api/userUnionLogin/getJiGuangParams.action', params);
    }
    // 调用服务器获得用户报名的职位信息
    getSignUpList(params) {
        return this.callback('/api/signUp/getSignUpList.action', params);
    }
    // 面试邀请列表
    doPositionLogList(params) {
      return this.callback('/api/userPositionLog/doPositionLogList.action', params);
    }

    // 日志
    doPublishViewLog(params) {
        return this.callback('/api/viewLog/doPublishViewLog.action', params);
    }
    // 
    doCreditList(params) {
        return this.callback('/api/credit/doCreditList.action', params);
    }

    callback(url, data) {
        return new Promise((resolve, reject) => {
            if (url.substr(0, 4) !== 'http') {
                url = requestUrl + url;
            }
            axios({
                url: url,
                data: data,
                method: 'POST',
                header: {
                    'Content-Type': 'application/json'
                }
            }).then((res) => {
                if (res.data) {
                    resolve(res.data);
                }
                if (!res.data.success) {
                    // Toast.fail(res.data.error,1);
                    alert("" + res.data.error);
                }
            }).catch((error) => {
                alert(
                    '服务器连接失败',
                );
                resolve({ success: false, error: "服务器连接失败" })
            })
        });
    }
}