export function ParseParams(url) {
    var retval = {};
    
    var args = url.split("?");
    if (!url || args.length === 0) {
        return retval; 
    }
    for (var str of args[1].split("&")) {
        var arg = str.split("=");
        if (arg.length > 1){
            retval[arg[0]] = arg[1];
        }
    }
    return retval;
}

export function GetParams(url,argsName) {
    var args = url.split("?");
    var retval = "";

    if (!url || args.length === 0) /*参数为空*/ {
        return retval; /*无需做任何处理*/
    }
    var str = args[1];
    args = str.split("&");
    for (var i = 0; i < args.length; i++) {
        str = args[i];
        var arg = str.split("=");
        if (arg.length <= 1) continue;
        if (arg[0] === argsName) retval = arg[1];
    }
    return retval;
}
export function GetState(url) {
    console.log(url);
    var args = url.split("?");
    var retval = null;

    if (!url || args.length === 0) /*参数为空*/ {
        return retval; /*无需做任何处理*/
    }
    var str = args[1];
    args = str.split("_");
    retval={"route":args[0],"fkUserId":args[1],"id":args[2],}
    return retval;
}
export function chkPrice(obj) { //方法1
    obj = obj.replace(/[^\d.]/g, "");
    //必须保证第一位为数字而不是. 
    obj = obj.replace(/^\./g, "");
    //保证只有出现一个.而没有多个. 
    obj = obj.replace(/\.{2,}/g, ".");
    //保证.只出现一次，而不能出现两次以上 
    obj = obj.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");

    let reg = obj.split(".");
    if (reg.length > 1 && reg[1].length > 2) {
        obj = reg[0] + "." + reg[1].substr(0, 2);
    }

    return obj;
}
export function chkSearch(obj) { //方法1
    obj = obj.replace(/[ ]/g, "");
    return obj;
}