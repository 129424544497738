import React, { Component } from 'react';
import { connect } from 'react-redux';
import { constants } from '../../../pages/login/store';
import { GetParams } from '../../../components/params/params';
// import { BUTTON_SIGNUP,BUTTON_SIGNUPED } from '../../../assets/style/common';
import Api from '../../../utils/Api';
import { Toast } from 'antd-mobile';
import TopBar from '../../../components/top-bar/TopBar';
// import MyDialog from '../../../components/myDialog/myDialog';
import SignUpButton from '../../../components/signUpButton/signUpButton';
import { ConfirmModal } from '../../../components/confirmModal/confirmModal';
import wx from 'weixin-js-sdk';
import LazyLoad from 'react-lazyload';
const getApi = Api.getInstance();

class PositionDetails extends Component {
	constructor(props) {
		super(props);
		this.state = {
			id: 0,
			position: null,
			collectionStatus: null,
			signUpStatus: null,
			specialArray: null,
			loading: false,
			isRefresh: true,
			loadingState: false,
			modal: false,
			shareImgFlag: false
		}
	}

	render() {
		let { loadingState, position, signUpStatus, fkSignUpId, specialArray, loading, shareImgFlag } = this.state;
		return (
			<div>
				{loading &&
					<div >
						<TopBar title={"职位详情"}></TopBar>
						<div className="position-details">
							<div className="job-item">
								<h1 className="title">{position.positionName}</h1>
								<div className="positon-location">
									<LazyLoad>
										<img className="img" src={require('../../../assets/temp/map_03.jpg')} alt="" onClick={this.clickMap.bind(this)}/>
									</LazyLoad>
									<div className="info">
										<i className="location-ico"></i>
										<div className="txt">{position.workProvince}{position.workCity}{position.workRegion}{position.workDetailAddress ? position.workDetailAddress : "--"}</div>
									</div>
								</div>

								<div className="price"><span className="num">{position.salary}</span>元/{position.salaryPer}/{position.salaryPaidType}{position.shareFlag === 1 && <span className="share">（分享可加薪{position.shareSalary}元/{position.salaryPer}）</span>}</div>
								<div className="job-tags">
									<div className="tag">
										<div className="label"><i className="ico number"></i><span>招聘人数：</span></div>
										<div className="text1">{position.positionRecruitsNumber}人</div>
									</div>
									<div className="tag">
										<div className="label"><i className="ico edu"></i><span>学历要求：</span></div>
										<div className="text1">{position.qualificationName ? position.qualificationName : "--"}</div>
									</div>
									<div className="tag">
										<div className="label"><i className="ico type"></i><span>职位类型：</span></div>
										<div className="text1">{position.positionType}</div>
									</div>
									<div className="tag">
										<div className="label"><i className="ico other"></i><span>其他要求：</span></div>
										<div className="text1">{position.requireSpecial ? position.requireSpecial : "--"}</div>
									</div>
								</div>
								{(position.margin > 0) &&
									<div className="ensure">
										<i className="ico"></i><p>: 该职位平台需收取{position.margin}元上岗保证金，上岗后将自动退还。</p>
									</div>
								}

							</div>

							<div className="job-item">
								<div className="ht">职位描述</div>
								<div className="hd">
									<div className="tags">
										<div className="tags">
											{(specialArray && specialArray.length > 0) ? <React.Fragment>{specialArray.map((item, index) => {
												return (
													<React.Fragment key={index}>
														<span className="tag">{item}</span>
													</React.Fragment>
												)
											})}
											</React.Fragment> :
												<React.Fragment></React.Fragment>
											}
										</div>
									</div>
									<div className="intro">
										{/* <p className="textarea" disabled>
											{position.introduction}
										</p> */}
										<div className="textarea" dangerouslySetInnerHTML={{ __html: position.introduction }}></div>
									</div>
								</div>
							</div>

							<div className="job-item">
								<div className="ht">工作时间</div>
								<div className="job-time">
									{(position.workDateStart || position.workDateEnd) &&
										<div className="item"><span className="ico date"></span><p className="text">{position.workDateStart}～{position.workDateEnd || "不限"}</p></div>
									}
									{(!position.workDateStart && !position.workDateEnd && position.workDayFlag !== 1 && position.workNightFlag !== 1) &&
										<div className="item"><span className="ico date"></span><p className="text">不限</p></div>

									}
									{position.workWeek &&
										<div className="item"><span className="ico week"></span><p className="text">{position.workWeekName}</p></div>
									}
								</div>
							</div>

							<div className="job-item">
								<div className="ht">面试要求</div>
								<div className="job-time">
									{position.requireInterviewAddress ?
										<div className="item"><span className="ico other"></span><p className="text">{position.requireInterviewFlag}</p></div>
										:
										<div className="item"><span className="ico other"></span><p className="text">{position.requireInterviewFlag}</p></div>
									}
								</div>
							</div>

							<div className="job-item">
								<div className="tips">
									<div className="ico"></div>
									<div className="txt">所有职位不向报名者收取（除上岗保证金外）任何费用，如有不实，请联系客服。</div>
									<div className='report-btn' onClick={this.jumpToReport.bind(this)}>反馈</div>
								</div>
							</div>

							{position.shareFlag === 1 &&
								<div className="share-tag">
									<i className="ico"></i>
									<div className="txt">分享职位可获加薪，还有意外奖励等着你。</div>
									<div className="btn" onClick={this.jumpTopositionShare.bind(this)}>快去分享吧！</div>
								</div>
							}


							<div className="fixed-bottom">
								<div className="info">
									<div className="txt">{position.shareFlag === 1 && <div className="span">{position.addSalaryStatus === 0 ? '可' : '已'}加薪 {position.shareSalary}元/{position.salaryPer}</div>}<div className="strong">薪资合计</div></div>
									<div className="price">
										<span className="num">{position.addSalaryStatus === 0 ? position.salary : position.salary + position.shareSalary}</span><span className="cny">(元/{position.salaryPer})</span>
									</div>
								</div>
								<SignUpButton index={"positionDetails"} signUpStatus={signUpStatus} loadingState={loadingState} position={position} fkSignUpId={fkSignUpId}
								recommendId={this.state.recommendId} 
								jumpToPositionShare={() => this.props.history.push("/sharePosition?id="+position.id)} 
								toSignUpList={() => this.props.history.push("/signUpList")} 
								toLoginPage={() => this.props.history.push('/login?userId=' + this.state.recommendId)}
								toPayMarginPage={() => this.props.history.push("/payMargin")}
								toBaseInfoPage={() => this.props.history.push("/baseInformation")}
								setPosition={() => this.props.setPosition.bind(this)}></SignUpButton>
								{/* {signUpStatus === 1 ?
									<Button loading={loadingState} style={BUTTON_SIGNUPED}>{'已报名'}</Button> :
									<Button loading={loadingState} style={BUTTON_SIGNUP} onClick={this.submit.bind(this)}>{'报名参加'}</Button>
								} */}

							</div>
							<div className="fixed-bottom-zw"></div>
							{position.shareFlag === 1 &&
								<div className="position-share" onClick={this.jumpTopositionShare.bind(this)}>
									<LazyLoad>
										<div className="shareNum">
											<div className="shareNumItem">
											{position.maxPrice}
											</div>
											元
										</div>
										<img src={require('../../../assets/img/share_img.png')} className="img" alt=""/>
									</LazyLoad>
								</div>
							}
							{/* <MyDialog name={"signUp"} modal={modal} maxPrice={position.maxPrice} shareFlag={position.shareFlag} onClose={() => { this.setState({ modal: false }); if(position.shareFlag === 1){ShowAlert("已报名!", "稍后客服将与您确认报名信息，请保持联系畅通呦~", () => {});} }} sure={() => { this.setState({ modal: false });this.jumpTopositionShare(); }} toSignUpList={() => {this.props.history.push("/signUpList")}}></MyDialog> */}
							{shareImgFlag && <div className="popbg" onClick={this.hideShareImg.bind(this)}>
								<LazyLoad>
									<img src="https://cdn.huizhuanvip.com/jianzhi/app/images/share.png" className="share-img" alt=""/>
								</LazyLoad>
							</div>}
						</div>
					</div>
				}
			</div>
		)
	};
	submit() {
		let userInfo = this.props.userInfo;
		var mobile = userInfo.mobile;
		if (!mobile) {
			this.props.history.push('/login?userId=' + this.state.recommendId);
			return;
		}

		if (this.state.signUpStatus === 1) {
			Toast.fail("您已投递过该职位", 1);
			return false;
		}
		// 是否确认报名？
		ConfirmModal(" ", "确定要报名该职位吗？", () => {
			this.setState({
				loadingState: true,
			}, () => {
				if (this.state.position.margin > 0) {
					this.setState({
						loadingState: false,
					}, () => {
						let params = {};
						params.margin = this.state.position.margin;
						params.id = this.state.position.id;
						params.recommendId = this.state.recommendId || null;
						this.props.setMarginParams(params);
	
						this.props.history.push("/payMargin");
					})
					return;
				}
				getApi.doPublishSignUp({
					fkPositionId: this.state.position.id,
					jobType: 0,
					token: userInfo.token,
					userId: userInfo.userId,
					recommendId: this.state.recommendId || null,
				}).then((res) => {
					if (res.success) {
						this.setState({
							signUpStatus: 1,
							modal: true,
						})
					}
				}).catch((res) => {
				}).finally((res) => {
					this.setState({
						loadingState: false,
					});
				});
			})
		});
	}
	jumpToReport() {
		if (!this.props.userInfo.mobile) {
			this.props.history.push("/login?userId=" + this.state.recommendId);
			return;
		}
		this.props.history.push("/reportpost?id="+this.state.position.id);
	}
	jumpTopositionShare() {
		if (!this.props.userInfo.mobile) {
			this.props.history.push("/login?userId=" + this.state.recommendId);
			return;
		}
		this.props.setPosition(this.state.position);
		this.props.history.push("/sharePosition?id="+this.state.position.id);
	}
	hideShareImg() {
		this.setState({
			shareImgFlag: false
		});
	}
	componentDidMount() {
		let res = GetParams(this.props.history.location.search, "id");
		let recommendId = GetParams(this.props.history.location.search, "recommendId") || null;
		if (res) {
			this.setState({
				id: res,
				recommendId
			}, () => {
				this.requestForPositionDetails();
				getApi.doPublishViewLog({
					token: this.props.userInfo.token,
					userId: this.props.userInfo.userId,
					action: 0,
					actionName: "浏览页面",
					memo: "position",
					positionId:res,
					clientType:7
				}).then((res) => {
				}).finally((res) => {
				});
			})
		}
	}
	requestForPositionDetails() {
		let {userInfo,shareFromPosition, clientType} = this.props;
		let loading = document.getElementById('i-loading');
		loading.style.display = 'inline';
		getApi.doPositionInfo({
			id: this.state.id,
			token: userInfo.token,
			userId: userInfo.userId,
		}).then((res) => {
			if (res.success) {
				this.setState({
					position: res.module,
					collectionStatus: res.module.collectionStatus,
					signUpStatus: res.module.signUpStatus,
					fkSignUpId: res.module.fkSignUpId || 0,
					specialArray: res.module.salaryWelfare ? res.module.salaryWelfare.split(",") : [],
					loading: true,
				});
				let userInfo = this.props.userInfo;
				let params = this.state.position;
				params.userId = userInfo.userId;
				params.title = shareFromPosition.title;
				params.clientType = clientType;
				document.addEventListener('WeixinJSBridgeReady', getApi.doSharePosition(params), false);
			}
		}).catch((res) => {
		}).finally((res) => {
			loading.style.display = 'none'
		})
	};
	clickMap(){
		let {position} = this.state;
		wx.openLocation({
			latitude: position.workLatitude, // 纬度，浮点数，范围为90 ~ -90。如果是动态获取的数据，使用parseFloat()处理数据
			longitude: position.workLongitude, // 经度，浮点数，范围为180 ~ -180。如果是动态获取的数据，使用parseFloat()处理数据；
			name: position.workProvince+position.workCity+position.workRegion+position.workDetailAddress, // 位置名
			address: position.workDetailAddress, // 地址详情说明
			scale: 10, // 地图缩放级别,整形值,范围从1~28。默认为最大
		});
	}
}

const mapState = (state) => ({
	userInfo: state.login.userInfo,
	shareFromPosition: state.login.shareFromPosition,
	shareGongZhongHao: state.login.shareGongZhongHao,
	clientType: state.login.clientType,
});

const mapDispatch = (dispatch) => ({
	setPosition(value) {
		dispatch({
			type: constants.POSITION,
			payload: value
		})
	},
	setMarginParams(value) {
		dispatch({
			type: constants.SET_MARGIN_PARAMS,
			payload: value
		})
	}
});

export default connect(mapState, mapDispatch)(PositionDetails);