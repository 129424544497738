import React, { Component } from 'react';
import {withRouter} from "react-router-dom";

class Navigator extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tag: this.props.tag || "",
        }
    }

    render() {
        let { tag } = this.state;
        return (
            <div>
                <div className="fixed-nav">
                    <a className={!tag || tag === "index" ?"item cur":"item"} href="javacript:void(0);" onClick={() => {
                        this.props.history.push(
                            '/index',
                        )
                    }}>
                        {!tag || tag === "index" ?
                            <img src={require('../../assets/img/nav_ico_home_cur.png')} alt=""></img>:
                            <img src={require('../../assets/img/nav_ico_home.png')} alt=""></img> 
                        }
                        <div className="txt">首页</div>
                    </a>
                    <a className= {tag === "qiuzhi" ?"item cur":"item"} href="javacript:void(0);" onClick={() => {
                        this.props.history.push(
                            '/signUpList',
                        )
                    }}>
                        {tag === "qiuzhi" ?
                            <img src={require('../../assets/img/nav_ico_position_cur.png')} alt=""></img>:
                            <img src={require('../../assets/img/nav_ico_position.png')}  alt=""></img> 
                        }
                        <div className="txt">求职</div>
                    </a>

                    {/* <a className="item">
                        {tag == "message" ?
                            <img src={require('../../assets/img/nav_ico_msg.png')} alt=""></img> :
                            <img src={require('../../assets/img/nav_ico_msg_cur.png')} alt=""></img>
                        }
                        <div className="txt">消息</div>
                    </a> */}
                    <a className={tag === "personal" ?"item cur":"item"} href="javacript:void(0);" onClick={() => {
                        this.props.history.push(
                            '/personal',
                        )
                    }}>
                    {tag === "personal" ?
                        <img src={require('../../assets/img/nav_ico_my_cur.png')}  alt=""></img>:
                        <img src={require('../../assets/img/nav_ico_my.png')} alt=""></img>
                }
                        <div className="txt">个人中心</div>
                    </a>
                </div>
                <div className="fixed-nav-zw"></div>
            </div>
        )
    };
}
export default withRouter(Navigator);
