import React, { Component } from 'react';
import { connect } from 'react-redux';
// import { constants } from '../../../pages/login/store';
import { GetParams } from '../../../components/params/params';
// import { ConfirmModal, ShowAlert } from '../../../components/confirmModal/confirmModal';
// import { Modal } from 'antd-mobile';
import Api from '../../../utils/Api';
import MyListView from '../../../components/list-view/myListView';
import ShareModal from '../../../components/share-modal/shareModal';
import LazyLoad from 'react-lazyload';
const getApi = Api.getInstance();

class ModuleJob extends Component {

    constructor(props) {
        super(props);
        this.state = {
            recommendId: "",
            shareImgFlag: false, 
            shareModalFlag: false, 
            sharePosterFlag: false,
        }
    }

    componentDidMount() {
      let recommendId = GetParams(this.props.history.location.search, "recommendId");
      if (recommendId) {
        this.setState({
          recommendId: recommendId,
        })
      }
      console.log('recommendId '+ recommendId);
      getApi.doPublishViewLog({
        token: this.props.userInfo.token,
        userId: this.props.userInfo.userId,
        action: 0,
        actionName: "浏览页面",
        memo: "module",
        clientType:7
      }).then((res) => {
      }).finally((res) => {
      });
    }

    render() {
        let { shareImgFlag, shareModalFlag, sharePosterFlag, posterUrl } = this.state;
        return (
          <div className="wrap">
              <div className="moduleJobBg">
                  <img src={'https://cdn.huizhuanvip.com/jianzhi/app/images/job_intdex_bg.png'} className="img" alt=""/>
              </div>
              <div className="body">
                <div className="slogan"><img src="https://cdn.huizhuanvip.com/jianzhi/app/images/slogan.png" mode="widthFix" className="img" alt=""></img></div>

                <div className="content">
                  <div className="moduleJobBg"><img src="https://cdn.huizhuanvip.com/jianzhi/app/images/bg.png" className="img" alt=""></img></div>
                  <div className="mt">
                    <div className="h2">职位列表</div>
                    <div className="ico" onClick={() => this.showShareModal()}><img src={require("../../../assets/img/icon_sjg_share.png")} className="img" alt=""></img></div>
                  </div>
                  <div className="mc">
                    
                    <MyListView onRef={(ref) => { this.listView = ref }} loadData={(page) => this.requestForPositionList(page)} createListItem={(value) => { return this._createListItem(value) }}></MyListView>

                  </div>
                </div>
              </div>
              
              {/* 分享按钮后的弹窗 */}
              {shareImgFlag && <div className="popbg" onClick={this.hideShareImg.bind(this)}>
                  <LazyLoad>
                      <img src="https://cdn.huizhuanvip.com/jianzhi/app/images/share.png" className="share-img" alt=""/>
                  </LazyLoad>
              </div>}
              {shareModalFlag &&
                  <ShareModal modal={shareModalFlag} name="chooseModal" onClose={() => { this.setState({ shareModalFlag: false }) }} onShareAppMessage={this.onShareAppMessage.bind(this)} showPosterModal={this.showPosterModal.bind(this)}></ShareModal>
              }
              {sharePosterFlag &&
                  <ShareModal modal={sharePosterFlag} name="positionPosterModal" onClose={() => { this.setState({ sharePosterFlag: false }) }} onClosePoster={this.onClosePoster.bind(this)} posterUrl={posterUrl}></ShareModal>
              }
          </div>
        )
    }
    _createListItem(position) {
      let { recommendId } = this.state;
      let url = '/positionDetails?id=' + position.id;
      if (recommendId) {
        url = url + '&recommendId=' + recommendId;
      }
      return (
        <div className="position-list" onClick={() => this.props.history.push(url, )} >
          <div className="item">
            <div className="ct">
              <div className="name">{position.positionName}</div>
              <div className="price"><div className="num">{position.salary}</div>元/{position.salaryPerName}</div>
            </div>
            <div className="cc">
              <div className="tags">
                <div className="tag">{position.workRegionName || position.workCityName}</div>
                <div className="tag">{position.foodFlag===1?'包吃':'不包吃'}</div>
                <div className="tag">{position.housingFlag===1?'包住':'不包住'}</div>
                <div className="tag">{position.workHours}小时</div>
              </div>
              <div className="label">{position.salaryPaidTypeName}</div>
            </div>
          </div>
        </div>
      )
    };
    showShareModal() {
      console.log('showShareModal');
      this.setState({
          shareModalFlag: true,
      })
    }
    onShareAppMessage () {
      this.setState({
          shareModalFlag: !this.state.shareModalFlag,
          shareImgFlag: !this.state.shareImgFlag,
      });
    }
    hideShareImg() {
        this.setState({
            shareImgFlag: false
        });
    }
    showPosterModal () {
        this.setState({
            shareModalFlag: !this.state.shareModalFlag,
            sharePosterFlag: !this.state.sharePosterFlag,
        })
    }
    onClosePoster () {
        this.setState({
            sharePosterFlag: !this.state.sharePosterFlag,
        })
    }
    requestForPositionList(page) {
      let userInfo = this.props.userInfo;
      getApi.getModuleJob({
        id: 1,
        page: page,
      }).then(res => {
        if (res.success) {
          this.listView.setData(res.module);
          // 这里的clientType是小程序的，所以写死了
          this.setState({
            posterUrl: getApi.doServiceUrl() + "/api/share/getModulePoster.action?token=" + userInfo.token + "&userId=" + userInfo.userId + "&clientType=8",
          });
          
          let {shareModule, clientType} = this.props;
          let params = {};
          params.userId = userInfo.userId;
          params.title = shareModule.title;
          params.imgUrl = shareModule.imgUrl;
          params.clientType = clientType;
          document.addEventListener('WeixinJSBridgeReady', getApi.doShareModuleJob(params), false);
        }
      })
    }
    componentWillMount() {
        let loading = document.getElementById('i-loading');
        if (loading) {
            loading.style.display = 'none'
        }
    }
}

const mapState = (state) => ({
    userInfo: state.login.userInfo,
    shareModule: state.login.shareModule,
    shareGongZhongHao:state.login.shareGongZhongHao,
    clientType: state.login.clientType,
})

const mapDispatch = (dispatch) => ({
})

export default connect(mapState, mapDispatch)(ModuleJob);