import { Carousel } from 'antd-mobile';
import React, { Component } from 'react';

export default class MyCarousel extends Component {

  constructor(props) {
    super(props);
    this.state = {
      imgHeight: 176,
      data: this.props.maxRecommendList || ['1', '2', '3'],
      tag: this.props.tag || 'tip',
      verticalFlag: this.props.verticalFlag === '0'? false : true,
    }
  }
  componentDidMount() {

  }
  render() {
    let { data, tag, verticalFlag } = this.state;
    return (
      <Carousel
        vertical={verticalFlag}
        dots={this.props.showDotsFlag || false}
        autoplay
        infinite
      >

        {data.map((item, index) => (
          <div key={index}>
            {tag === 'index' &&
              <div className="slider3">
                <img src={item} className="img" alt=""/>
              </div>
            }
            {tag === 'tip' &&
              <div className="slider1">
                <div className="slide1">
                  <img src={item.photo} className="img" alt=""/>
                  <span className="name">{item.userName}</span>
                  <span className="phone">{item.mobile}</span>
                  <span className="tag">邀请<i>{item.num}</i>人</span>
                  <span className="tag">奖励<i>{item.reward}</i>元</span>
                </div>
              </div>
            }
            {tag === 'wap' &&
              <div className="slide2">
                <div className="item">
                  <img src={item.photo} className="avatar" alt=""/>
                  <span className="name">{item.userName}</span>
                  <span className="txt">兼职<span>{item.time}</span>次</span>
                  <span className="txt">赚到 <span>{item.money}</span>元</span>
                </div>
              </div>
            }
            {tag === 'personal' &&
              <img className="ad-img" src={item} style={{ display: 'inline-block',height: '79px'}} alt=""/>
            }
            {tag === 'sharedModuleJob' &&
              <div className="item">
                  <div className="tags ht1"><div className="tag">招聘岗位</div></div>
                  <div className="tags ht2"><div className="tag">{item.positionName}</div></div>
                  <div className="intro ht3">
                  <div className="it"><label>薪资：</label><p>{item.salary}元/{item.salaryPerName}</p></div>
                  <div className="it"><label>负责：</label><p>{item.introduction}</p></div>
                  </div>
                  <div className="tags ht4"><div className="tag">岗位福利</div></div>
                  <div className="labels ht5">
                      <div className="label">{item.workRegionName || item.workCityName}</div>
                      {item.housingFlag ? <div className="label">包吃</div> : null}
                      {item.foodFlag ? <div className="label">包住</div> : null}
                      {item.workHours ? <div className="label">{item.workHours}小时</div> : null}
                  </div>
                  <div className="dots"></div>
              </div>
            }
          </div>
        ))}
      </Carousel>
    );
  }
}
