import React, { Component } from 'react';
import { connect } from 'react-redux';
import MyCarousel from '../../../components/myCarousel/myCarousel';
import { GetParams } from '../../../components/params/params';
import Api from '../../../utils/Api';
const getApi = Api.getInstance();

class SharedWap extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userRecommender: {},
            maxRecommendList: [{
                photo: "https://cdn.huizhuanvip.com/jianzhi/api/image/1578109492593.jpg",
                userName: "秋天的风",
                time: "1",
                money: 100
            }, {
                photo: "https://cdn.huizhuanvip.com/jianzhi/api/image/1575435477434.jpg",
                userName: "动次打次",
                time: "2",
                money: 200
            }, {
                photo: "https://cdn.huizhuanvip.com/jianzhi/api/image/1574477681553.jpg",
                userName: "穿裤子的小发糕",
                time: "3",
                money: 300
            }, {
                photo: "https://cdn.huizhuanvip.com/jianzhi/api/image/1578469445176.jpeg",
                userName: "天地三才",
                time: "4",
                money: 400
            }, {
                photo: "https://cdn.huizhuanvip.com/jianzhi/api/image/1578109492593.jpg",
                userName: "烣歰季节",
                time: "5",
                money: 500
            }, {
                photo: "https://wx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTLCQdkiajcFO1aJYZMLzS1iaicjiadyOyZq3XWia5QlChNkcPHicib8MHJDFYAjkhmJuJKGicfL9chbuajBTw/132",
                userName: "阳",
                time: "6",
                money: 600
            }],
        }

    }
    componentDidMount() {
        let fkUserId = GetParams(this.props.history.location.search, "fkUserId");
        console.log("fkUserId:",fkUserId);
        if (fkUserId) {
            this.setState({
                fkUserId: fkUserId,
            }, () => {
                this.requestForSharedWap();
            })
        }
    }
    componentWillMount() {
        let loading = document.getElementById('i-loading')
        if (loading) {
        }
    }
    render() {
        let { maxRecommendList, userRecommender } = this.state;
        return (
            <div className="bg-040360">

                <div className="gzwx">
                    <div className="head">
                        <img className="head-img" src={require('../../../assets/img/wx_top.png')} alt=""/>
                    </div>
                    <div className="top-line">
                        {userRecommender.photo &&
                            <img className="avatar" src={userRecommender.photo} alt=""/>
                        }
                        {/* <img className="avatar" src={require('../../../assets/img/share_banner.png')} /> */}
                        <div className="txt">{userRecommender.name || '你的好友'}邀请一起来兼职</div>
                    </div>
                    <div className="info">
                        <div className="bor"></div>
                        <div className="txt">找兼职上LEVEL实习<br />薪资高-有担保-结算快-更安全</div>
                        <div className="bor"></div>
                    </div>
                    {/* <div className="slide">
                        <div className="item">
                            <img className="avatar" src={require('../../../assets/img/share_banner.png')} />
                            <div className="name txt">冷冷的冰雨</div>
                            <div className="txt">兼职<span>2</span>次</div>
                            <div className="txt">赚到<span>2030</span>元</div>
                        </div>
                    </div> */}
                    <MyCarousel maxRecommendList={maxRecommendList} tag={"wap"}></MyCarousel>

                    <div className="wx">
                        <div className="bor"></div>
                        <div className="txt">长按右侧二维码<br />关注官方公众号</div>
                        <div className="qr">
                            <img className="wxqr" src={userRecommender.acode} alt=""/>
                        </div>
                    </div>


                </div>

            </div>
        )
    };
    requestForSharedWap() {
        let {userInfo, clientType} = this.props;
        let { userRecommender } = this.state;
        getApi.getSharedWap({
            recommend: this.state.fkUserId,
            token: userInfo.token,
            userId: userInfo.userId,
            clientType: clientType,
        }).then((res) => {
            console.log('ress'+JSON.stringify(res));
            if (res.success) {
                const resModule = res.module;
                userRecommender.fkUserId = resModule.fkUserId;
                userRecommender.name = resModule.userName;
                userRecommender.mobile = resModule.mobile;
                userRecommender.photo = resModule.photo;
                userRecommender.acode = "https://mp.weixin.qq.com/cgi-bin/showqrcode?ticket="+resModule.acode;
                this.setState({
                    userRecommender
                })
            }
        }).catch((res) => {
            console.log(res);
        }).finally((res) => {
        });
    }


}

const mapState = (state) => ({
    userInfo: state.login.userInfo,
    clientType: state.login.clientType,
})

const mapDispatch = (dispatch) => ({
});

export default connect(mapState, mapDispatch)(SharedWap);