import React, { Component } from 'react';
import { connect } from 'react-redux';
import { constants } from '../login/store';
import Navigator from '../../components/navigator/navigator';
import MyCarousel from '../../components/myCarousel/myCarousel';
import Api from '../../utils/Api';
const getApi = Api.getInstance();

class Personal extends Component {

    componentDidMount() {
        this.requestForPersonal();
        let params = { userId: this.props.userInfo.userId,title:this.props.shareGongZhongHao.title, clientType: this.props.clientType }
        document.addEventListener('WeixinJSBridgeReady', getApi.doShareWap(params), false);
    }

    render() {
        const { personal, userInfo } = this.props;
        return (
            <div className="bg-fff">
                <div className="user-center">
                    <div className="user-top" onClick={() => {
                        if (!userInfo.mobile) {
                            this.props.history.push("/login");
                            return;
                        }
                        this.props.history.push(
                            '/BaseInformation',
                        )
                    }}>
                        <div className="user-info">
                            <div className="avatar">
                                    {userInfo.photo ? <img src={userInfo.photo} className="img" alt="" /> : <img src={require('../../assets/img/icon_touxiang.png')} className="img" alt="" />}
                                <div className="edit"><span className="edit-btn"><a href="javacript:void(0);">编辑</a></span></div>
                            </div>
                            {(userInfo.mobile && userInfo.name) &&
                                <div className="name">{userInfo.name}</div>
                            }
                            {(!userInfo.mobile) &&
                                <div className="name">{"请登录"}</div>
                            }
                            {(userInfo.mobile && !userInfo.name) &&
                                <div className="name">{"请设置昵称"}</div>
                            }
                        </div>
                    </div>
                    <div className="user-items">
                        <div className="money" onClick={() => {
                            if (!userInfo.mobile) {
                                this.props.history.push("/login");
                                return;
                            } this.props.history.push("/myWallet")
                        }}>
                            <div className="item">
                                <strong>{userInfo.mobile && personal && personal.totalMoney ? personal.totalMoney : "0"}</strong>
                                <p>累计收入</p>
                            </div>
                            <div className="item">
                                <strong>{userInfo.mobile && userInfo && userInfo.balance ? userInfo.balance : "0"}</strong>
                                <p>余额</p>
                            </div>
                        </div>
                        <div className="tx" onClick={() => {
                            if (!userInfo.mobile) {
                                this.props.history.push("/login");
                                return;
                            } this.props.history.push("/takeCash")
                        }}>
                            <i className="tx-ico"></i>
                        </div>
                    </div>
                    {/* <div className="user-center-ad">
                        <a href="">
                            <LazyLoad>
                                <img className="ad-img" src={'https://cdn.huizhuanvip.com/jianzhi/app/images/banner4.png'} />
                            </LazyLoad>
                        </a>
                    </div> */}
                    
                    <div className="user-center-ad" onClick={() => {
                            if (!userInfo.mobile) {
                                this.props.history.push("/login");
                                return;
                            } this.props.history.push("/sharePrize")
                        }}>
                            <MyCarousel maxRecommendList={['https://cdn.huizhuanvip.com/jianzhi/app/images/banner4.png']} tag={"personal"}></MyCarousel>
                    </div>

                    <div className="head-nav">
                        <a className="item" onClick={() => {
                            if (!userInfo.mobile) {
                                this.props.history.push("/login");
                                return;
                            } this.props.history.push("/signUpList")
                        }} href="javacript:void(0);">
                            <div className="ico pt"></div>
                            <div className="txt">我的求职</div>
                        </a>
                        <a className="item" onClick={() => {
                            if (!userInfo.mobile) {
                                this.props.history.push("/login");
                                return;
                            } this.props.history.push("/sharePrize")
                        }} href="javacript:void(0);">
                            <div className="ico fx"></div>
                            <div className="txt">分享赚钱</div>
                            <div className="tip"><span className="content">单次最高50元</span></div>
                        </a>
                        <a className="item" onClick={this.getApp.bind(this)} href="javacript:void(0);">
                            <div className="ico xz"></div>
                            <div className="txt">下载app</div>
                        </a>
                        <a className="item" onClick={() => { this.props.history.push("/PrivacyPolicy") }} href="javacript:void(0);">
                            <div className="ico xy"></div>
                            <div className="txt">用户协议</div>
                        </a>
                    </div>

                    <div className="list-nav">
                        <div className="tit" /* onClick={this.clear.bind(this)} */>
                            <h3>其他功能</h3>
                            {/* <div style={{borderWidth: '0.1rem', height: '0.1rem'}} onClick={this.clear.bind(this)}></div> */}
                        </div>
                        <div className="items">
                            <a className="item" onClick={() => {
                                if (!userInfo.mobile) {
                                    this.props.history.push("/login");
                                    return;
                                } this.props.history.push('/BaseInformation')
                            }} href="javacript:void(0);">
                                <div className="ico wdzl"></div>
                                <div className="txt">我的资料</div>
                            </a>
                            <a className="item" onClick={() => {
                                if (!userInfo.mobile) {
                                    this.props.history.push("/login");
                                    return;
                                } this.props.history.push("/authentication")
                            }} href="javacript:void(0);">
                                <div className="ico smrz"></div>
                                <div className="txt">实名认证</div>
                            </a>
                            <a className="item" onClick={() => {
                                if (!userInfo.mobile) {
                                    this.props.history.push("/login");
                                    return;
                                } this.props.history.push("/workExpect")
                            }} href="javacript:void(0);">
                                <div className="ico zgqw"></div>
                                <div className="txt">工作期望</div>
                            </a>
                            <a className="item" onClick={() => {
                                if (!userInfo.mobile) {
                                    this.props.history.push("/login");
                                    return;
                                } this.props.history.push("/feedback")
                            }} href="javacript:void(0);">
                                <div className="ico yjfk"></div>
                                <div className="txt">意见反馈</div>
                            </a>
                        </div>
                    </div>
                </div>

                <Navigator tag={"personal"}></Navigator>
            </div>
        )
    };
    componentWillMount() {
        let loading = document.getElementById('i-loading');
        if (loading) {
            loading.style.display = 'none'
        }
    }

    requestForPersonal() {
        let userInfo = this.props.userInfo;
        if (!userInfo.token || !userInfo.userId || !userInfo.mobile) {
            return;
        }
        getApi.doPersonalInfo({
            token: userInfo.token,
            userId: userInfo.userId,
        }).then((res) => {
            if (res.success) {
                res = res.module;
                let totalMoney = 0;
                let balance = 0;
                if (res.totalMoney === 0 || (res.totalMoney.toString()).indexOf(".") === -1) {
                    totalMoney = res.totalMoney;
                } else {
                    totalMoney = (res.totalMoney.toString()).substring(0, (res.totalMoney.toString()).indexOf(".") + 3);
                }
                if (res.balance === 0 || (res.balance.toString()).indexOf(".") === -1) {
                    balance = res.balance;
                } else {
                    balance = (res.balance.toString()).substring(0, (res.balance.toString()).indexOf(".") + 3);
                }
                let personal = this.props.personal;
                personal.totalMoney = totalMoney;
                personal.balance = balance;
                personal.completion = res.completion;
                this.props.setPersonal(personal);
            }
        }).catch((res) => {
        }).finally((res) => {

        })
    };
    getApp() {
        window.location = "https://a.app.qq.com/o/simple.jsp?pkgname=com.level.job.user"
        // let ua = window.navigator.userAgent,
        //     app = window.navigator.appVersion;
        // if (!!ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)) {
        //     window.location = "https://fir.im/HeLi?release_id=5e4d036ef945480d021fb9fe"
        // }
        // else if (ua.indexOf('Android') > -1 || ua.indexOf('Adr') > -1) {
        //     window.location = "https://a.app.qq.com/o/simple.jsp?pkgname=com.level.job.user"
        // }
    };
   async clear(){
       await this.props.setClear();
        if(!this.props.userInfo.mobile){
            this.props.history.push("/login");
        }
    }
}

const mapState = (state) => ({
    userInfo: state.login.userInfo,
    personal: state.login.personal,
    shareGongZhongHao:state.login.shareGongZhongHao,
    clientType: state.login.clientType,
})

const mapDispatch = (dispatch) => ({
    setPersonal(value) {
        dispatch({
            type: constants.PERSONAL,
            payload: value
        })
    },
    setClear(value) {
        dispatch({
            type: constants.CLEAR,
            payload: value
        })
    }
})

export default connect(mapState, mapDispatch)(Personal);
