import React, { Component } from 'react';
import { connect } from 'react-redux';
/* import { constants } from '../../../pages/login/store';
import { Modal, WingBlank} from 'antd-mobile';
import wx from 'weixin-js-sdk'; */
import TopBar from '../../../components/top-bar/TopBar';
import ShareModal from '../../../components/share-modal/shareModal';
import Api from '../../../utils/Api';
import LazyLoad from 'react-lazyload';
import { GetParams } from '../../../components/params/params';

const getApi = Api.getInstance();

class SharePosition extends Component {

    constructor(props) {
        super(props);
        this.state = {
            position: null,
            shareInfoResponse: {},
            loading: false,
            shareImgFlag: false,
            shareModalFlag: false,
            sharePosterFlag: false,
        }

    }
    componentDidMount() {
        let res = GetParams(this.props.history.location.search, "id");
        this.requestForSharePosition(res);
        /* let {userInfo,shareFromPosition, clientType} = this.props;
        let params = {};
        params.id = res;
        params.userId = userInfo.userId;
        params.title = shareFromPosition.title;
        params.clientType = clientType;
        document.addEventListener('WeixinJSBridgeReady', getApi.doSharePosition(params), false); */
    }

    render() {
        let { shareInfoResponse, shareImgFlag, loading, shareModalFlag, sharePosterFlag, posterUrl } = this.state;
        return (
            <div className="bg-fff">
                {loading &&
                    <div>
                        <TopBar title={"分享加薪"}></TopBar>
                        <div className="share-position wrap">

                            <div className="banner">
                                {/* <LazyLoad> */}
                                    <img src="https://cdn.huizhuanvip.com/jianzhi/app/images/banner10.png" className="share-img" alt=""/>
                                {/* </LazyLoad> */}
                            </div>
                            <div className="top">
                                <div className="head-item">
                                    <div className="item"><div className="h1">{shareInfoResponse.positionName}</div></div>
                                    <div className="item"><div className="price">【薪资】{shareInfoResponse.salary}元/{shareInfoResponse.salaryPer} {shareInfoResponse.salaryPaidType}</div></div>
                                    <div className="item">【时间】{shareInfoResponse.workDateStart || '现在'} 至 {shareInfoResponse.workDateEnd || '长期'}</div>
                                    <div className="item">【地点】{shareInfoResponse.workDetailAddress}</div>
                                    <div className="item">【内容】{shareInfoResponse.introduction}</div>
                                </div>
                            </div>

                            <div className="share-user">
                                {shareInfoResponse.mySignUpFlag === 0 &&
                                    <div className="txt">现金奖励！ 职位分享给好友，最高可赚15元现金哦~</div>
                                }
                                {(!shareInfoResponse.recommendList || shareInfoResponse.recommendList.length === 0) && shareInfoResponse.mySignUpFlag === 1 &&
                                    <div className="txt">还差1人，即可获得加薪 {shareInfoResponse.shareSalary}元/{shareInfoResponse.salaryPer},快去邀请吧~</div>
                                }
                                {(shareInfoResponse.recommendList && shareInfoResponse.recommendList.length > 0) && shareInfoResponse.mySignUpFlag === 1 &&
                                    <div className="txt">加薪成功！继续邀请，赚更多现金奖励哦~</div>
                                }
                                <div className="btns">
                                    <a className="btn" onClick={this.showShareImg.bind(this)} href="javacript:void(0);"><i className="ico add"></i>立即分享</a>
                                </div>
                            </div>

                            <div className="share-yq-user">
                                <div className="t">我邀请的人</div>
                                <div className="user-list">
                                    {shareInfoResponse.recommendList && shareInfoResponse.recommendList.map((recommend, index) => {
                                        return <React.Fragment key={index}>
                                            <div className="item">
                                                <div className="avatar">
                                                    <LazyLoad>
                                                        <img src={recommend.photo ? recommend.photo : require('../../../assets/img/add_share.png')} className="img" alt=""/>
                                                    </LazyLoad>
                                                </div>
                                                <div className="info">
                                                    <div className="name">{recommend.userName || recommend.mobile}</div>
                                                    {recommend.platformFlag === 1 && <div className="tag">平台新人</div>}
                                                </div>
                                                <div className="status">{recommend.rewardFlag === 1 ? '已上岗' : '未上岗'}</div>
                                            </div>
                                        </React.Fragment>
                                    })
                                    }

                                    <div className="btns" onClick={this.showShareImg.bind(this)}>
                                        <a className="btn" href="javacript:void(0);">快去邀请吧！</a>
                                    </div>
                                </div>
                            </div>

                            <div className="share-total">
                                <div className="t">我的邀请奖励</div>
                                <div className="items">
                                    <div className="item">
                                        <div className="mt">{(shareInfoResponse.mySignUpFlag || 0) * ((shareInfoResponse.recommendList && shareInfoResponse.recommendList.length > 0) ? 1 : 0) * shareInfoResponse.shareSalary}</div>
                                        <div className="mc">职位加薪<br />薪资提升<br />（{shareInfoResponse.shareSalary}元/{shareInfoResponse.salaryPer}）</div>
                                    </div>
                                    <div className="item">
                                        <div className="mt">{shareInfoResponse.invitationNum || 0}</div>
                                        <div className="mc">推广人数<br />分享好友<br />（{/*{shareInfoResponse.sharePromotionSalary || 5*/}人数）</div>
                                    </div>
                                    <div className="item">
                                        <div className="mt">{shareInfoResponse.promotionReward || 0}</div>
                                        <div className="mc">推广职位<br />预计奖励<br />（{shareInfoResponse.sharePromotionFee || 0}元/人）</div>
                                    </div>
                                </div>
                            </div>

                            <div className="share-rule">
                                <div className="t">** 分享规则 **</div>
                                <div className="item">
                                    <div className="label"><span>1.</span></div>
                                    <div className="txt">分享本职位给好友，好友通过您分享的职位链接报名并第一次上岗获得工资后，您将获得<span>2-5</span>元/位现金奖励，上不封顶；</div>
                                </div>
                                <div className="item">
                                    <div className="label"><span>2.</span></div>
                                    <div className="txt">若有1名以上的好友通过您分享的链接报名本职位并成功上岗，您自己去上岗该职位时可获得（<span>{shareInfoResponse.shareSalary}</span>元/{shareInfoResponse.salaryPer}）加薪，职位只可加薪一次；</div>
                                </div>
                                <div className="item">
                                    <div className="label"><span>3.</span></div>
                                    <div className="txt">职位推广奖励在好友上岗并结算工资后，将发放至您的LEVEL实习个人账户，可随时提现；</div>
                                </div>
                                <div className="item">
                                    <div className="label"><span>4.</span></div>
                                    <div className="txt">分享不同职位给好友，只要好友是第一次上岗该职位，你都能获得一次推广奖励，加薪奖励和职位推广奖励可叠加享受；</div>
                                </div>
                            </div>

                            <div className="qa">
                                <div className="t">**//  常见问题   //**</div>
                                <div className="item">
                                    <div className="ask">问：我自己不去兼职，分享职位可以获得奖励吗？</div>
                                    <div className="answer">答：可以的，只要好友通过您的链接报名并是第一次上岗该职位您就能获得奖励，您自己去上岗也可以额外获得加薪机会。</div>
                                </div>
                                <div className="item">
                                    <div className="ask">问：好友报名过其他职位，我还可以获得推广职位奖励吗？</div>
                                    <div className="answer">答：可以的，只要您分享给好友的职位，好友是第一次报名上岗，您就可以获得推广职位奖励。</div>
                                </div>
                                <div className="item">
                                    <div className="ask">问：好友通过我的链接，没有报名我分享的职位，但报名链接里的其他职位，我还可以获得推广职位奖励吗？</div>
                                    <div className="answer">答：可以的，只要好友通过您分享的链接，报名其他职位且是第一次上岗该职位，您就可以获得推广职位奖励。</div>
                                </div>
                                <div className="item">
                                    <div className="ask">问：推广职位奖励什么时候发放？</div>
                                    <div className="answer">答：职位邀请奖励，在好友上岗结束后结算工资时一并发放。</div>
                                </div>
                                <div className="bt"><i></i><i></i><i></i></div>
                            </div>
                        </div>
                        {/* 分享按钮后的弹窗 */}
                        {shareImgFlag && <div className="popbg" onClick={this.hideShareImg.bind(this)}>
                            <LazyLoad>
                                <img src="https://cdn.huizhuanvip.com/jianzhi/app/images/share.png" className="share-img" alt=""/>
                            </LazyLoad>
                        </div>}
                        {shareModalFlag &&
                            <ShareModal modal={shareModalFlag} name="chooseModal" onClose={() => { this.setState({ shareModalFlag: false }) }} onShareAppMessage={this.onShareAppMessage.bind(this)} showPosterModal={this.showPosterModal.bind(this)}></ShareModal>
                        }
                        {sharePosterFlag &&
                            <ShareModal modal={sharePosterFlag} name="positionPosterModal" onClose={() => { this.setState({ sharePosterFlag: false }) }} onClosePoster={this.onClosePoster.bind(this)} posterUrl={posterUrl}></ShareModal>
                        }
                    </div>
                }
            </div>
        )
    }
    onShareAppMessage () {
        this.setState({
            shareModalFlag: !this.state.shareModalFlag,
            shareImgFlag: !this.state.shareImgFlag,
        });
    }
    showPosterModal () {
        this.setState({
            shareModalFlag: !this.state.shareModalFlag,
            sharePosterFlag: !this.state.sharePosterFlag,
        })
    }
    onClosePoster () {
        this.setState({
            sharePosterFlag: !this.state.sharePosterFlag,
        })
    }
    requestForSharePosition(id) {
        let userInfo = this.props.userInfo;
        getApi.getPositionShareInfo({
            fkPositionId: id,
            token: userInfo.token,
            userId: userInfo.userId,
        }).then((res) => {
            if (res.success) {
                if (res.module.mySignUpId > 0) {
                    res.module.mySignUpFlag = 1;
                } else {
                    res.module.mySignUpFlag = 0;
                }
                res.module.salaryPer =  res.module.salaryPerName;
                res.module.salaryPaidType =  res.module.salaryPaidTypeName;
                // 这里的clientType是小程序的，所以写死了
                this.setState({
                    shareInfoResponse: res.module,
                    loading: true,
                    posterUrl: getApi.doServiceUrl() + "/api/share/getPositionPoster.action?token=" + userInfo.token + "&userId=" + userInfo.userId + "&fkPositionId=" + id+"&clientType=8",
                });
                
                let {shareFromPosition, clientType} = this.props;
                let params = res.module;
                params.userId = userInfo.userId;
                params.title = shareFromPosition.title;
                params.clientType = clientType;
                document.addEventListener('WeixinJSBridgeReady', getApi.doSharePosition(params), false);
            }
        }).catch((res) => {
            console.log(res);
        }).finally((res) => {
        });

    }
    showShareImg() {
        /**this.setState({
            shareImgFlag: true
        });*/
        this.setState({
            shareModalFlag: true,
        })
    }
    hideShareImg() {
        this.setState({
            shareImgFlag: false
        });
    }
    componentWillMount() {
        let loading = document.getElementById('i-loading');
        if (loading) {
            setTimeout(() => {
                loading.style.display = 'none'
            }, 1000)
        }
    }
}

const mapState = (state) => ({
    userInfo: state.login.userInfo,
    shareFromPosition: state.login.shareFromPosition,
    clientType: state.login.clientType,
})

const mapDispatch = (dispatch) => ({
})

export default connect(mapState, mapDispatch)(SharePosition);