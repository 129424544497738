import React, { Component } from 'react';
import { connect } from 'react-redux';
import MyListView from '../../../components/list-view/myListView';
import TopBar from '../../../components/top-bar/TopBar';
import { GetParams } from '../../../components/params/params';
import Api from '../../../utils/Api';
const getApi = Api.getInstance();

class SharedInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            list: [],
            loading: false,
            status: '0',
            totalReward: {},
        }
    }

    render() {
        let { status, totalReward } = this.state;
        return (
            <div className="bg-fff">
                <TopBar title={"奖励明细"}></TopBar>
                <div className="my-wallet1">
                    <div className="wallet-top">
                        <div className="infos">
                            {totalReward.agentFlag &&
                                <div className="item">
                                    <div className="mt">邀请人数（人）</div>
                                    <div className="mc">{totalReward.invitationNum || 0}</div>
                                </div>
                            }
                            <div className="item">
                                <div className="mt">推广次数（次）</div>
                                <div className="mc">{totalReward.promotionNum || 0}</div>
                            </div>
                            <div className="item">
                                <div className="mt">推广奖励（元）</div>
                                <div className="mc">{totalReward.promotionReward || 0}</div>
                            </div>
                            {totalReward.agentFlag &&
                                <div className="item">
                                    <div className="mt">我的积分</div>
                                    <div className="mc">{totalReward.totalCredit || 0}</div>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="main-head">
                        {totalReward.agentFlag &&
                            <div className={status === '0' ? 'title-s' : 'title'} onClick={this.changStatus.bind(this, '0')}>邀请人数</div>
                        }
                        <div className={status === '1' ? 'title-s' : 'title'} onClick={this.changStatus.bind(this, '1')}>推广次数</div>
                        <div className={status === '2' ? 'title-s' : 'title'} onClick={this.changStatus.bind(this, '2')}>推广奖励</div>
                        {totalReward.agentFlag &&
                            <div className={status === '3' ? 'title-s' : 'title'} onClick={this.changStatus.bind(this, '3')}>我的积分</div>
                        }
                    </div>

                    <MyListView onRef={(ref) => { this.listView = ref }} loadData={(page) => this.requestForList(page)} createListItem={(value) => { return this._createListItem(value) }}></MyListView>

                </div>


            </div>
        )
    }

    componentDidMount() {
        let userInfo = this.props.userInfo;
        if (!userInfo.mobile) {
            this.props.history.push("/login");
            return;
        }
        // 请求统计数据
        getApi.getTotalReward({
            token: userInfo.token,
            userId: userInfo.userId,
        }).then((res) => {
            if (res.success) {
                this.setState({ totalReward: res.module });
            }
        }).catch((res) => {
            console.log();
        }).finally((res) => {
        });

        let status = GetParams(this.props.history.location.search, "status");
        this.setState({ status })
        // 分享公众号
        let params = { userId: this.props.userInfo.userId, title: this.props.shareGongZhongHao.title, clientType: this.props.clientType }
        document.addEventListener('WeixinJSBridgeReady', getApi.doShareWap(params), false);
    };
    changStatus(value) {
        this.setState({
            status: value
        }, () => {
            this.listView.initLoading();
        })
    }
    requestForList(page) {
        let { userInfo } = this.props;
        let { status } = this.state;
        this.setState({
            loading: false
        })
        if (status === '0') {
            getApi.getShareInfo({
                page: page,
                token: userInfo.token,
                userId: userInfo.userId,
            }).then((res) => {
                if (res.success) {
                    this.setState({ totalReward: res.module });
                    this.listView.setData(res.module.recommendList);
                }
            }).catch((res) => {
                console.log();
            }).finally((res) => {
                this.setState({
                    loading: true
                })
            })
        }
        else if (status === '1') {
            getApi.getPromotionSignUp({
                page: page,
                token: userInfo.token,
                userId: userInfo.userId,
            }).then((res) => {
                if (res.success) {
                    this.setState({ totalReward: res.module });
                    this.listView.setData(res.module.recommendList);
                }
            }).catch((res) => {
                console.log();
            }).finally((res) => {
                this.setState({
                    loading: true
                })
            })
        }
        else if (status === '2') {
            getApi.getRewardSharedUserList({
                page: page,
                token: userInfo.token,
                userId: userInfo.userId,
            }).then((res) => {
                if (res.success) {
                    let list = res.module.recommendList;
                    for (let item of list) {
                        item.reward = item.reward >= 0 ? item.reward + '元' : '--'
                    }
                    this.listView.setData(res.module.recommendList);
                }
            }).catch((res) => {
                console.log();
            }).finally((res) => {
                this.setState({
                    loading: true
                })
            })
        }
        else if (status === '3') {
            getApi.doShareCreditList({
                page: page,
                token: userInfo.token,
                userId: userInfo.userId,
            }).then((res) => {
                if (res.success) {
                    this.listView.setData(res.module.creditDetailResponseList);
                }
            }).catch((res) => {
                console.log();
            }).finally((res) => {
                this.setState({
                    loading: true
                })
            })
        }
    }
    _createListItem(value) {
        let { status, loading } = this.state;
        return (
            <div className="list-items">
                {status === '0'&&loading &&
                    <div className="item">
                        <div className="mt">
                            <div className="name">{value.name || value.mobile}</div>
                            <div className="label">{value.time}</div>
                            <div className="label">{value.rewardFlag !== 0 ? '已' : '未'}上岗</div>
                            {/* <div className="txt add">{value.reward!=0?value.reward+'元':'--'}</div> */}
                        </div>
                    </div>
                }
                {status === '1'&&loading &&
                    <div className="item">
                        <div className="mt">
                            <div className="name">{value.name || value.mobile}</div>
                            <div className="label">{value.time}</div>
                            <div className="txt add">{value.positionName}</div>
                        </div>
                        <div className="mb">
                            {/* <div className="label">{value.reward!=0?'已':'未'}上岗</div> */}
                        </div>
                    </div>
                }
                {status === '2'&&loading &&
                    <div className="item">
                        <div className="mt">
                            <div className="name">{value.name || value.mobile}</div>
                            <div className="txt add">{value.reward}</div>
                        </div>
                        <div className="mb">
                            <div className="label">{value.createTime}</div>
                            <div className="label">{value.positionName}</div>
                        </div>
                    </div>
                }
                {status === '3'&&loading &&
                    <div className="item">
                        <div className="mt">
                            <div className="label">{value.createTime}</div>
                            <div className="txt">{value.memo}</div>
                            <div className="add">{value.credit >= 0 ? '+' + value.credit : value.credit}</div>
                        </div>
                        <div className="mb">
                            {/* <div className="label">{value.createTime}</div> */}
                        </div>
                    </div>
                }
            </div>

        )
    };
    componentWillMount() {
        let loading = document.getElementById('i-loading');
        if (loading) {
            setTimeout(() => {
                loading.style.display = 'none'
            }, 1000)
        }
    }
}

const mapState = (state) => ({
    userInfo: state.login.userInfo,
    shareGongZhongHao: state.login.shareGongZhongHao,
    clientType: state.login.clientType,
})

const mapDispatch = (dispatch) => ({
});

export default connect(mapState, mapDispatch)(SharedInfo);