import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Toast } from 'antd-mobile';
import { constants } from '../../pages/login/store';
import Navigator from '../../components/navigator/navigator';
import MyListView from '../../components/list-view/myListView';
import SinglePicker from '../../components/single-picker/singlePicker';
// import TopBar from '../../components/top-bar/TopBar';
import Api from '../../utils/Api';
const getApi = Api.getInstance();

class SignUpList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            signStatus: null,
            list: [],
            loading: false,
            responseStatus: 0,
            responseMemo: null,
        }
    }

    render() {
        let { signStatus } = this.state;
        return (
            <div className="bg-fff">
                {/* <TopBar signUp={true} title={"我的求职"}></TopBar> */}
                
                
                <div className='navigation'>
                    {/* <div className="navigation_up">
                        <div className='navigation_title'>求职</div>
                    </div> */}
                    {/* <TopBar signUp={true} title={"我的求职"}></TopBar> */}
                    <div>
                        <div className="top-bar" >
                            <div className="top">
                                <div className="h1">{"我的求职"}</div>
                            </div>
                        </div>
                        <div className="top-bar-zw"></div>
                    </div>

                    <div className="chooseBtn">
                        {!signStatus ? <div className="chooseBtn_itemBtn selected" onClick={this.filteListener.bind(this)}>
                            <div className="name">全部</div>
                            <div className="line"></div>
                        </div> : <div className="chooseBtn_itemBtn unselected" onClick={this.filteListener.bind(this)}>
                            <div className="name">全部</div>
                            <div className="line"></div>
                        </div>}
                        
                        {signStatus === '0' ? <div className="chooseBtn_itemBtn selected" onClick={this.filteListener.bind(this, '0')}>
                            <div className="name">未上岗</div>
                            <div className="line"></div>
                        </div> : <div className="chooseBtn_itemBtn unselected" onClick={this.filteListener.bind(this, '0')}>
                            <div className="name">未上岗</div>
                            <div className="line"></div>
                        </div>}

                        {signStatus === '1' ? <div className="chooseBtn_itemBtn selected" onClick={this.filteListener.bind(this, '1')}>
                            <div className="name">已上岗</div>
                            <div className="line"></div>
                        </div> : <div className="chooseBtn_itemBtn unselected" onClick={this.filteListener.bind(this, '1')}>
                            <div className="name">已上岗</div>
                            <div className="line"></div>
                        </div>}

                        {signStatus === '2' ? <div className="chooseBtn_itemBtn selected" onClick={this.filteListener.bind(this, '2')}>
                            <div className="name">面试记录</div>
                            <div className="line"></div>
                        </div> : <div className="chooseBtn_itemBtn unselected" onClick={this.filteListener.bind(this, '2')}>
                            <div className="name">面试记录</div>
                            <div className="line"></div>
                        </div>}
                    </div>
                </div>

                <MyListView onRef={(ref) => { this.listView = ref }} loadData={(page) => this.requestForSignUpList(page)} createListItem={(value) => { return this._createListItem(value) }} fromPage="signUpList"></MyListView>
                <Navigator tag={"qiuzhi"}></Navigator>
            </div>
        )
    }
    componentDidMount() {
        let userInfo = this.props.userInfo;
        if (!userInfo.mobile) {
            this.props.history.push("/login");
            return;
        }
        let params = { userId: this.props.userInfo.userId,title:this.props.shareGongZhongHao.title, clientType: this.props.clientType }
        document.addEventListener('WeixinJSBridgeReady', getApi.doShareWap(params), false);
    };
    filteListener(signStatus, aa) {
        if (typeof signStatus === 'string') {
            this.setState({ signStatus }, () =>  this.listView.initLoading());
        }else {
            this.setState({ signStatus: null }, () =>  this.listView.initLoading());
        }
    }
    requestForSignUpList(page) {
        const { signStatus } = this.state;
        let userInfo = this.props.userInfo;
        if (!userInfo.token || !userInfo.userId) {
            return;
        }
        if (signStatus === '2') {
            getApi.doPositionLogList({
                page: page,
                token: userInfo.token,
                userId: userInfo.userId,
                type: 1,
            }).then((res) => {
                if (res.success) {
                    res.module.forEach(signUp => {
                      signUp.keyVal = JSON.parse(signUp.keyVal);
                    });
                    this.listView.setData(res.module);
                }
            }).catch((res) => {
                console.log();
            }).finally((res) => {
            })
        } else {
            getApi.doSignUpList({
                jobType: 0,
                page: page,
                token: userInfo.token,
                userId: userInfo.userId,
                signStatus: this.state.signStatus,
            }).then((res) => {
                if (res.success) {
                    this.listView.setData(res.module);
                }
            }).catch((res) => {
                console.log();
            }).finally((res) => {
            })
        }
    }

    _createListItem(value) {
        const { signStatus } = this.state;
        const { userInfo } = this.props;
        if (signStatus !== '2') {
            return (
                <div className="position1-list" onClick={() => {
                    this.props.history.push(
                        '/signUpInfo?id=' + value.id,
                    )
                }}>
                    <div className="item">
                        <div className="mt">
                            <div className="ht">
                                <div className="name">{value.positionName}</div>
                                {value.margin > 0 &&
                                    <div className="label"><span className="label-ico bao-ico"></span></div>
                                }
                            </div>
                            <div className="price">
                                <span className="money">{value.addSalary === 0 ? value.salary : (value.salary + value.shareSalary).toString()}</span><span className="unit">元/{value.salaryPer}</span>
                            </div>
                        </div>
                        <div className="mc">
                            <div className="tags">
                                <div className="tag">{value.workRegionName}</div>
                                <div className="tag">{value.positionTypeName || '不限'}</div>
                                <div className="tag">{value.salaryPaidType}</div>
                                <div className="tag" >{value.createdTime}</div>
                            </div>
                            <div className="price">
                                {value.addSalary === 1 ?
                                    <del className="del"><span className="money">{value.salary}</span><span className="unit">元/{value.salaryPer}</span></del> :
                                    <div>
                                        {value.sharePromotionFee > 1 ?
                                            <div> 可+<span className="money">{value.shareSalary}</span><span className="unit">元/{value.salaryPer}</span> </div> :
                                            <del className="del">--</del>
                                        }
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="mb">
                            <div className="avatar-items">
                                <ul>
                                    <li>
                                        <img src={userInfo.photo ? userInfo.photo : require('../../assets/img/chat_avater.png')} alt=""/>
                                    </li>
                                    {(value.recommendList && value.recommendList.length > 0) ? value.recommendList.map((item, index) => {
                                        return (
                                            <li key={index}>
                                                    <img src={item.photo ? item.photo : require('../../assets/img/chat_avater.png')} alt=""/>
                                            </li>
                                        )
                                    }) : <div></div>
                                    }
                                </ul>
                                <div className="tip">{value.addSalary === 0 ? '未' : '已'}加薪</div>
                            </div>
                            {value.positionStatus === 3 ?
                                <div className="share-btn">
                                    <span className="end">已结束</span>
                                </div> :
                                <div>
                                    {value.sharePromotionFee > 0 &&
                                        <div className="share-btn">
                                            <span className="btn" onClick={(e) => {
                                                e.stopPropagation();
                                                this.props.history.push("/sharePosition?id="+value.fkPositionId);
                                            }}>{value.addSalary === 0 ? '分享加薪' : '继续分享'}</span>
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                    </div>
    
                </div>
            )
        } else {
            if (value.keyVal) {
                return (
                    <div className="position1-list-ms">
                        <div className="ms-head">
                            <div className="title">{value.jobName}</div>
                            <button className="btn" onClick={() => { this.props.history.push("/reportpost?id="+value.fkJobId) }} data-item='{{positionLog}'>反馈</button>
                            {/* <single-picker theme="interview" wx:if="{value.responseStatus === 0}" className="btn1" bind:change="cancelReasonChange" data-item='{{positionLog}' placeHolder="取消" attribute="interviewCancelReason"></single-picker> */}
                            {value.responseStatus === 0 && <SinglePicker theme={"interview"} className="btn1" attribute={"interviewCancelReason"} placeholder={"取消"} onChange={this.cancelReasonChange.bind(this, value)}></SinglePicker>}
                        </div>
        
                        <div className="ms-body result">
                            <div className="items">
                                <div className="item">
                                    <div className="title">面试时间：</div>
                                    <div className="content">{value.keyVal.interviewTime}</div>                            
                                </div>
                                {value.responseStatus === 0 && <div className="item state">待面试</div>}
                                {value.responseStatus === -1 && <div className="item state">正常取消</div>}
                                {value.responseStatus === -2 && <div className="item state">临时取消</div>}
                                {value.responseStatus === 1 && <div className="item state">通过</div>}
                                {value.responseStatus === 2 && <div className="item state">不通过</div>}
                            </div>
                            <div className="items">
                                <div className="item">
                                    <div className="title">联 系 人 ：</div>
                                    <div className="content">{value.keyVal.contactName}</div>
                                </div>
                            </div>
                            <div className="items">
                                <div className="item">
                                    <div className="title">联系电话：</div>
                                    <div className="content">{value.keyVal.contactPhone}</div>
                                    {value.keyVal.contactPhone && <a href={"tel:" + value.keyVal.contactPhone} className="call"><img className="img" src={require("../../assets/img/icon_qz_phone.png")} alt=""></img></a>}
                                </div>
                            </div>
                            <div className="items">
                                <div className="item">
                                    <div className="title">面试地点：</div>
                                    <div className="content">{value.keyVal.interviewPlace}</div>
                                </div>
                            </div>
                            {value.keyVal.interviewRemark && <div className="items">
                                <div className="item">
                                    <div className="title">面试备注：</div>
                                    <div className="content">{value.keyVal.interviewRemark}</div>
                                </div>
                            </div>}
                            {value.responseMemo && <div className="items">
                                <div className="item">
                                    <div className="title">面试结果：</div>
                                    <div className="content">{value.responseMemo || ''}</div>
                                </div>
                            </div>}
                        </div>
        
                    </div>
                )
            } else {
                return null;
            }
        }
    };
    cancelReasonChange(item, value, label) {
      console.log('cancelReasonChange');
      if (!item || !item.id) {
        Toast.fail("请选择数据", 1);
			  return false;
      }
      if (!value) {
        Toast.fail("请选择原因", 1);
			  return false;
      }
      this.setState({
          responseStatus: value,
          responseMemo: label,
      }, () => {
        let {userInfo} = this.props;
        let loading = document.getElementById('i-loading');
        loading.style.display = 'inline';
        getApi.doUpdateInterviewStatus({
          id: item.id,
          token: userInfo.token,
          userId: userInfo.userId,
          responseStatus: this.state.responseStatus,
          rejectMemo: this.state.responseMemo
        }).then((res) => {
          if (res.success) {
            Toast.success("取消成功", 1, () => {
              this.listView.initLoading();
            });
          }
        }).catch((res) => {
        }).finally((res) => {
          loading.style.display = 'none'
        })
      })
    }
    componentWillMount() {
        let loading = document.getElementById('i-loading');
        if (loading) {
            setTimeout(() => {
                loading.style.display = 'none'
            }, 1000)
        }
    }
}

const mapState = (state) => ({
    userInfo: state.login.userInfo,
    name: state.login.name,
    resume: state.login.resume,
    shareGongZhongHao:state.login.shareGongZhongHao,
    clientType: state.login.clientType,
})

const mapDispatch = (dispatch) => ({
    setPosition(value) {
        dispatch({
            type: constants.POSITION,
            payload: value
        })
    },
});

export default connect(mapState, mapDispatch)(SignUpList);