import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Toast } from 'antd-mobile';
import { constants } from '../../login/store';
import SinglePicker from '../../../components/single-picker/singlePicker';
import MyDatePicker from '../../../components/date-picker/myDatePicker';
import MyImagePicker from '../../../components/image-picker/myImagePicker';
import RegionPicker from '../../../components/region-picker/regionPicker';
import TopBar from '../../../components/top-bar/TopBar';
import { BUTTON_PRIMARY } from '../../../assets/style/common';
import Validate from '../../../utils/Validate';
import Api from '../../../utils/Api';
const getApi = Api.getInstance();


class BaseInformation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loadingState: false,
            resume: JSON.parse(JSON.stringify(this.props.userInfo)) || {},
            schoolList: [],
        }
        this.validate = this.initValidate();
    }
    componentDidMount() {
      // 请求学校的数据
      if (this.state.resume.city) {
        getApi.queryDictionary({
          token: this.props.userInfo.token,
          userId: this.props.userInfo.userId,
          attribute: 'school',
          parentId: this.state.resume.city,
        }).then((res) => {
          if (res.success) {
            this.setState({ schoolList: res.module });
          }
        }).catch(function (reason) {
            console.log(reason);
        }).finally((res) => {
        });
      }
      /* else {
        Toast.fail("请先选择城市", 1);
      } */
		  // 分享公众号
      let params = { userId: this.props.userInfo.userId,title:this.props.shareGongZhongHao.title, clientType: this.props.clientType }
      document.addEventListener('WeixinJSBridgeReady', getApi.doShareWap(params), false);
    }

    render() {
        let { loadingState, resume, schoolList } = this.state;
        return (
            <div className="bg-fff">
                <TopBar title={"基本信息"}></TopBar>
                <div className="form-wrap">
                    <div className="item item-avatar">
                        <div className="mt">照片</div>
                        <div className="avatar">
                            <MyImagePicker className="upload" url={resume.photo} changeImage={(url) => { resume.photo = url; this.setState({ resume: resume }) }}></MyImagePicker>
                        </div>
                    </div>
                    <div className="item">
                        <div className="mt">姓名<span>*</span></div>
                        <div className="mc">
                            <div className="input"><input type="text" className="ipt" maxLength={20} placeholder="请输入姓名" value={resume.name || ""} onChange={(text) => { resume.name = text.target.value; this.setState({ resume: resume }) }} /></div>
                        </div>
                    </div>
                    <div className="item">
                        <div className="mt">性别<span>*</span></div>
                        <div className="mc">
                            <SinglePicker theme={"form"} attribute={"gender"} placeholderClass={"picker"} placeholder={"请选择性别"} translate={resume.genderName} onChange={(res) => { resume.gender = res; this.setState({ resume: resume }) }}></SinglePicker>
                        </div>
                    </div>
                    <div className="item">
                        <div className="mt">出生日期<span>*</span></div>
                        <div className="mc">
                            <MyDatePicker mode={"date"} placeholderClass={"picker"} placeholder={"请选择出生日期"} translate={resume.birthday} onChange={(res) => { resume.birthday = res; this.setState({ resume: resume }) }}></MyDatePicker>
                        </div>
                    </div>
                    <div className="item">
                        <div className="mt">意向区域<span>*</span></div>
                        <div className="mc">
                            <div className="input">
                                <RegionPicker theme={"work"} attribute={"city"} placeholder={"请选择意向区域"} placeholderClass={"picker"} province={resume.provinceName || ""} city={resume.cityName || ""} area={resume.areaName || ""} areaUnlimit={1} onChange={item => { resume.province = item[0]; resume.city = item[1]; resume.area = item[2]; this.setState({ resume: resume }, this.getSchoolList.bind(this));  }}></RegionPicker>
                            </div>
                        </div>
                    </div>
                    <div className="item">
                        <div className="mt">学校<span>*</span></div>
                            {/* <div className="input"><input type="text" className="ipt" placeholder="请输入学校" value={resume.school || ""} onChange={(text) => { resume.school = text.target.value; this.setState({ resume: resume }) }} /></div> */}
                        <div className="mc">
                            <SinglePicker theme={"form"} placeholderClass={"picker"} placeholder={"请选择学校"}  translate={resume.schoolIdName || resume.school} onChange={(res) => { resume.schoolId = res; this.setState({ resume: resume })}} array={schoolList}></SinglePicker>
                        </div>
                    </div>
                    <div className="item">
                        <div className="mt">学历<span>*</span></div>
                        <div className="mc">
                            <SinglePicker theme={"form"} attribute={"qualification"} placeholderClass={"picker"} placeholder={"请选择学历"} translate={resume.qualificationName} onChange={(res) => { resume.qualification = res; this.setState({ resume: resume }) }}></SinglePicker>
                        </div>
                    </div>
                    {/* <div className="tip">(以下联系方式至少选填一项，以便企业与您联系，推荐填写手机号码哟~)</div> */}
                    <div className="item">
                        <div className="mt">手机号</div>
                        <div className="mc">
                            <div className="input">
                              <input type="text" disabled className="ipt" placeholder="请输入手机号" value={resume.mobile || ""} onChange={(text) => { resume.mobile = text.target.value; this.setState({ resume: resume }) }} />
                              <span className="jumptoChangeAccount" onClick={this.jumptoChangeAccount.bind(this)}>去修改<img className="img" src={require("../../../assets/img/icon_grzx_zy_back.png")} alt=""></img></span>  
                            </div>
                        </div>
                    </div>
                    {/* <div className="item">
                        <div className="mt">邮箱</div>
                        <div className="mc">
                            <div className="input"><input type="text" className="ipt" placeholder="请输入邮箱" value={resume.email || ""} onChange={(text) => { resume.email = text.target.value; this.setState({ resume: resume }) }} /></div>
                        </div>
                    </div>
                    <div className="item">
                        <div className="mt">微信</div>
                        <div className="mc">
                            <div className="input"><input type="text" className="ipt" placeholder="请输入微信" value={resume.wechat || ""} onChange={(text) => { resume.wechat = text.target.value; this.setState({ resume: resume }) }} /></div>
                        </div>
                    </div>
                    <div className="item">
                        <div className="mt">QQ</div>
                        <div className="mc">
                            <div className="input"><input type="text" className="ipt" placeholder="请输入QQ" value={resume.qq || ""} onChange={(e) => { resume.qq = e.target.value; this.setState({ resume: resume }) }} /></div>
                        </div>
                    </div> */}
                    <Button loading={loadingState} style={BUTTON_PRIMARY} onClick={this.submit.bind(this)}>保存</Button>
                </div>

            </div>
        )
    };
    getSchoolList() {
      let { resume } = this.state;
      if (resume.city) {
        getApi.queryDictionary({
          token: this.props.userInfo.token,
          userId: this.props.userInfo.userId,
          attribute: 'school',
          parentId: resume.city,
        }).then((res) => {
          if (res.success) {
            this.setState({ schoolList: res.module });
          }
        }).catch(function (reason) {
            console.log(reason);
        }).finally((res) => {
        });
      }
      else {
        Toast.fail("请先选择城市", 1);
      }
    }
    jumptoChangeAccount() {
      this.props.history.push("/changeAccount");
    }
    submit() {
        let userInfo = this.props.userInfo;
        const params = this.state.resume;
        params.token = userInfo.token;
        params.userId = userInfo.userId;

        if (!this.validate.checkForm(params)) {
            const error = this.validate.errorList[0]
            Toast.fail(error.msg, 1);
            return false;
        }
        let chooseTime = new Date(params.birthday.replace(/-/g, "/")).getTime();
        let todayTime = new Date().getTime();
        if (chooseTime > todayTime) {
            Toast.fail('请填写正确出生日期', 1);
            return false;
        }
        this.setState({
            loadingState: true,
        }, () => {
            getApi.doModifyNewUserInfoResume(
                params
            ).then((res) => {
                if (res.success) {
                    this.props.setUserInfo(res.module);
                    Toast.success('保存成功', 1, () => {
                        this.setState({
                            loadingState: false,
                        });
                        this.props.history.goBack();
                    });
                }

            }).catch(function (reason) {
                console.log(reason);
            }).finally((res) => {
                this.setState({
                    loadingState: false,
                });
            });
        });
    };
    initValidate() {
        const rules = {
            photo: {
                required: true,
            },
            name: {
                required: true,
                maxLength: 20,
            },
            gender: {
                required: true,
                digits: true
            },
            birthday: {
                required: true,
            },
            province: {
              required: true,
              digits: true,
            },
            city: {
              required: true,
              digits: true,
            },
            schoolId: {
                required: true,
                digits: true,
                min: 1,
            },
            qualification: {
                required: true,
                digits: true
            },
            mobile: {
                tel: true,
            },
            wechat: {
                minlength: 1,
            },
            qq: {
                minlength: 1,
            },
            email: {
                email: true,
            }
        }
        const messages = {
            photo: {
                required: '请选择照片'
            },
            name: {
                required: '请输入姓名',
                maxLength: '姓名20字以内'
            },
            gender: {
                required: '请选择性别',
                digits: '请选择性别',
            },
            birthday: {
                required: '请选择出生日期',
            },
            province: {
              required: "请选择省份",
              digits: "请选择省份",
            },
            city: {
              required: "请选择城市",
              digits: "请选择城市",
            },
            schoolId: {
                required: '请选择学校',
                digits: '请选择学校',
                min: '请选择学校',
            },
            qualification: {
                required: '请选择最高学历',
                digits: '请选择最高学历'
            },
            mobile: {
                tel: '手机号需11位',
            },
            wechat: {
                minlength: '最少1个字符',
            },
            qq: {
                minlength: '最少1个字符',
            },
            email: {
                email: '邮箱格式错误',
            }
        }
        return new Validate(rules, messages);
    };
    componentWillMount() {
        let loading = document.getElementById('i-loading');
        if (loading) {
            setTimeout(() => {
                loading.style.display = 'none'
            }, 1000)
        }
    }
}

const mapState = (state) => ({
    userInfo: state.login.userInfo,
    shareGongZhongHao:state.login.shareGongZhongHao,
    clientType: state.login.clientType,
})

const mapDispatch = (dispatch) => ({
    setUserInfo(item) {
        dispatch({
            type: constants.USERINFO,
            payload: item
        })
    },
});

export default connect(mapState, mapDispatch)(BaseInformation);