export const NAME = 'name/name';
export const RESUME = "resume/resume";
export const PERSONAL = "personal/personal";
export const EXPECTPOSITION = "expectPosition/expectPosition";
export const SEARCHNAME = "searchName/searchName";
export const USERINFO = "userInfo/userInfo";
export const CITY = "city/city";
export const DICTIONARYLIST = "dictionaryList/dictionaryList";
export const WORKEXPECT = "workExpect/workExpect";
export const GPSLIMIT = "gpsLimit/gpsLimit";
export const POSITION = "share/position";
export const SET_MARGIN_PARAMS = "share/setMarginParams";
export const SET_SETTING = "share/setSetting";
export const CLEAR = "clear/clear";
export const CLIENT_TYPE = "login/clientType";
export const defaultState = {
    name: "hhhhh",
    resume: {
    },
    userInfo: {
        token: null,
        userId: null,
    },
    personal: {

    },
    city: 892,
    location: '南京市',
    searchNameList: [],
    expectPosition: [],
    dictionaryList: [],
    workExpect: {},
    gpsLimit: {},
    marginParams:{},
    shareGongZhongHao: {
        title: '做兼职赚外快,众多高薪兼职等你来挑~',
        imgUrl: 'https://cdn.huizhuanvip.com/jianzhi/app/images/icon_share_fenm.png',
    },
    shareFromPosition: {
        title: '快来帮我加薪！高薪兼职还能继续加薪，太赚了~',
        imgUrl: 'https://cdn.huizhuanvip.com/jianzhi/app/images/icon_shar_fenx.png',
    },
    shareModule: {
        title: '',
        imgUrl: '',
    },
    rankSetting: {
        imageUrl: '',
        webUrl: '',
      },
    // 邀请新人奖励单价
    sharePromotionSalary: 10,
    // 职位推广奖励单价
    shareInviteSalary: 5,
    // level实习公众号的clientId
    clientType: 7,
};
