import React, { Component } from 'react';
import { connect } from 'react-redux';
import LazyLoad from 'react-lazyload';
import { Button } from 'antd-mobile';
import { constants } from '../../../pages/login/store';
import { GetParams } from '../../../components/params/params';
import Api from '../../../utils/Api';
import TopBar from '../../../components/top-bar/TopBar';
const getApi = Api.getInstance();

class SignUpInfo extends Component {
	constructor(props) {
		super(props);
		this.state = {
			id: 0,
			position: null,
			collectionStatus: null,
			loading: false,
			refreshing: false,
			signUp: {},
		}
	}

	render() {
		let {loading, signUp } = this.state;
		return (
			<div>
				{loading &&
					<div>
						<TopBar title={"职位详情"}></TopBar>
						<div className='details'>
							<div className='transparentLine'></div>
							<div className='details_up'>
								<div className='details_up_jobTitle'>
									<div className='jobName' bindtap="jumpToInfo">{signUp.positionName}</div>
									{signUp.signStatus === 0 && <div className='communicateStatus'>未上岗</div>}
									{signUp.signStatus === 1 && <div className='communicateStatus'>已上岗</div>}
									{signUp.signStatus === -1 && <div className='communicateStatus'>未上岗</div>}
									{signUp.signStatus === -2 && <div className='communicateStatus'>已取消</div>}
								</div>
								<div className='dottedLine'></div>

								<div className='up_body'>
									<div className='bodyStyle'>
										<div className='bodyStyle_title'>职位类型</div>
										{signUp.positionTypeName ?
											<div className='bodyStyle_text'>{signUp.positionTypeName}</div> :
											<div className='bodyStyle_text'>--</div>
										}
									</div>
									<div className='bodyStyle'>
										<div className='bodyStyle_title'>职位福利</div>
										{signUp.salaryWelfareName ?
											<div className='bodyStyle_text'>{signUp.salaryWelfareName}</div> :
											<div className='bodyStyle_text'>--</div>
										}
									</div>
									<div className='bodyStyle'>
										<div className='bodyStyle_title'>职位薪资</div>
										{signUp.salary ?
											<div className='bodyStyle_money'>
												<div className='bodyStyle_text2'>{signUp.salary}</div>
												<div className='bodyStyle_text'>元/{signUp.salaryPerName} {signUp.salaryPaidTypeName}</div>
											</div> :
											<div className='bodyStyle_money'>--</div>
										}
									</div>
									<div className='bodyStyle'>
										<div className='bodyStyle_title'>报名时间</div>
										{signUp.createdTime ?
											<div className='bodyStyle_text'>{signUp.createdTime}</div> :
											<div className='bodyStyle_text'>--</div>
										}
									</div>
									<div className='bodyStyle'>
										<div className='bodyStyle_title'>入职时间</div>
										{signUp.startTime ?
											<div className='bodyStyle_text'>{signUp.startTime}</div> :
											<div className='bodyStyle_text'>--</div>
										}
									</div>
									<div className='bodyStyle'>
										<div className='bodyStyle_title'>结束时间</div>
										{signUp.endTime ?
											<div className='bodyStyle_text'>{signUp.endTime}</div> :
											<div className='bodyStyle_text'>--</div>
										}
									</div>
								</div>
								<div className='dottedLine'></div>
								<div className='bodyStyle_note'>
									<div className='bodyStyle_title'>入职备注</div>
									{signUp.remark ?
										<div className='bodyStyle_text'>{signUp.remark}</div> :
										<div className='bodyStyle_text'>--</div>
									}
								</div>
							</div>
							<div className='transparentLine'></div>

							<div className='settlementRecords'>
								<div className='settlementRecords_title'>结算记录</div>
								{signUp.positionPayLogListResponses.length === 0 || !signUp.positionPayLogListResponses ?
									<div className='noRecords'>暂无结算记录</div> :
									signUp.positionPayLogListResponses.map((positionPayLogItem, index) => {
										return (
											<div className='settlementRecords_details' key={index}>
												{positionPayLogItem.payedStatus === 1 && positionPayLogItem.successTime &&
													<div className='details_time'>{positionPayLogItem.successTime}</div>
												}
												{positionPayLogItem.payedStatus === 2 && positionPayLogItem.payedTime &&
													<div className='details_time'>{positionPayLogItem.payedTime}</div>
												}
												{positionPayLogItem.payedStatus === 3 && positionPayLogItem.payedTime &&
													<div className='details_time'>{positionPayLogItem.payedTime}</div>
												}
												{positionPayLogItem.payedStatus === 4 && positionPayLogItem.pendingTime &&
													<div className='details_time'>{positionPayLogItem.pendingTime}</div>
												}
												{positionPayLogItem.payedStatus === -1 && (positionPayLogItem.successTime || positionPayLogItem.auditTime) &&
													<div className='details_time'>{positionPayLogItem.successTime || positionPayLogItem.auditTime}</div>
												}
												{positionPayLogItem.payedStatus === 0 && <div className='details_state'>待支付</div>}
												{positionPayLogItem.payedStatus === 1 && <div className='details_state'>商家已支付</div>}
												{positionPayLogItem.payedStatus === 2 && <div className='details_state'>托管中</div>}
												{positionPayLogItem.payedStatus === 3 && <div className='details_state'>已申请</div>}
												{positionPayLogItem.payedStatus === 4 && positionPayLogItem.pendingType === 8 && <div className='details_state'>商家修改托管工资</div>}
												{positionPayLogItem.payedStatus === 4 && positionPayLogItem.pendingType === 10 && <div className='details_state'>已申请支付工资</div>}
												{positionPayLogItem.payedStatus === -1 && <div className='details_state'>已撤销</div>}

												<div className='details_money'>
													<div className='moneyNum2'>{positionPayLogItem.payedMoney}元</div>
													<LazyLoad>
														<img className="pointImg" src={require('../../../assets/img/yq_arrow.png')} alt=""/>
													</LazyLoad>
												</div>
											</div>
										)
									})
								}
							</div>
							<div className='transparentLine'></div>
							
							<div className='interviewRecords'>
								<div className='interviewRecords_head'>
									<div className='settlementRecords_title'> 面试记录</div>
									<Button className="btn" onClick={() => { this.props.history.push("/reportpost?id="+signUp.positionId) }}>反馈</Button>
								</div>
								{signUp.interviewList.length === 0 || !signUp.interviewList ?
									<div className='noRecords'>暂无面试记录</div> :
									signUp.interviewList.map((interview, index) => {
										return (
											<div className='settlementRecords_details' key={index}>
												
												<div className="interview-list">
													<div className="ms-body result">
														<div className="items">
															<div className="item">
																<div className="title">面试时间：</div>
																<div className="content">{interview.keyVal.interviewTime}</div>                      
															</div>
															{interview.responseStatus === 0 && <div className="item state">待面试</div>}
															{interview.responseStatus === -1 && <div className="item state">正常取消</div>}
															{interview.responseStatus === -2 && <div className="item state">临时取消</div>}
															{interview.responseStatus === 1 && <div className="item state">通过</div>}
															{interview.responseStatus === 2 && <div className="item state">不通过</div>}
														</div>
														<div className="items">
															<div className="item">
																<div className="title">联系人：</div>
																<div className="content">{interview.keyVal.contactName}</div>
															</div>
														</div>
														<div className="items">
															<div className="item">
																<div className="title">联系电话：</div>
																<div className="content">{interview.keyVal.contactPhone}</div>
																{interview.keyVal.contactPhone && <a href={"tel:" + interview.keyVal.contactPhone} className="call"><img className="img" src={require("../../../assets/img/icon_qz_phone.png")} alt=""></img></a>}
															</div>
														</div>
														<div className="items">
															<div className="item">
																<div className="title">面试地点：</div>
																<div className="content">{interview.keyVal.interviewPlace}</div>
															</div>
														</div>
														{interview.keyVal.interviewRemark && <div className="items">
															<div className="item">
																<div className="title">面试备注：</div>
																<div className="content">{interview.keyVal.interviewRemark}</div>
															</div>
														</div>}
														{interview.responseMemo && <div className="items">
															<div className="item">
																<div className="title">面试结果：</div>
																<div className="content">{interview.responseMemo || ''}</div>
															</div>
														</div>}
													</div>

												</div>

											</div>
										)
									})
								}
							</div>
							<div className='transparentLine'></div>

							<div className='reportRecords'>
								<div className='reportRecords_head'>
									<div className='settlementRecords_title'>职位反馈</div>
									<Button className="btn" onClick={() => { this.props.history.push("/reportpost?id="+signUp.positionId) }}>反馈</Button>
								</div>
								{signUp.reportList.length === 0 || !signUp.reportList ?
									<div className='noRecords'>暂无职位反馈</div> :
									signUp.reportList.map((report, index) => {
										return (
											<div className='settlementRecords_details' key={index}>
												
												<div className="reportRecords-body">
													<div className="times items">
														<div className="time">{report.createTime}</div>
													</div>

													<div className="results items">
														<span className="result">{report.typeName}：</span><span className="result">{report.content}</span>
													</div>
													<img className="img items" src={report.imagePath || '../../../images/WechatIMG20.png'} alt=""></img>

												</div>

											</div>
										)
									})
								}
							</div>
							<div className='transparentLine'></div>

							<div className='applicationHistory'>
								<div className='applicationHistory_title'>报名历史</div>
								{signUp.positionLogListResponses.length === 0 || !signUp.positionLogListResponses ?
									<div className='noRecords'>暂无报名历史</div> :
									signUp.positionLogListResponses.map((positionLogItem, index) => {
										return (
											<div key={index}>
												<div className='applicationHistory_details'>
													<div className='details_left'>
														<div className='details_left_point'></div>
														<div className='details_left_time'>{positionLogItem.createdTime}</div>
													</div>
													<div className='details_right'>
														{positionLogItem.type !== 1 && positionLogItem.type !== 2 && positionLogItem.type !== 3 && positionLogItem.type !== 8 && <div className='details_right1'>{positionLogItem.memo}</div>}
														{positionLogItem.type === 3 && <div className='details_right1'>商家{positionLogItem.memo}</div>}
														{(positionLogItem.type === 1 || positionLogItem.type === 2) && !positionLogItem.keyVal && <div className='details_right1'>{positionLogItem.memo}</div>}
														{positionLogItem.type === 1 && positionLogItem.keyVal && <div className='details_right1'>商家{positionLogItem.memo}</div>}
														{positionLogItem.type === 2 && positionLogItem.keyVal && <div className='details_right1'>商家{positionLogItem.memo}</div>}
														{positionLogItem.type === 8 && <div className='details_right1'>商家{positionLogItem.memo}</div>}
													</div>
												</div>

												<div className='applicationHistory_details'></div>
											</div>
										)
									})}

							</div>
							<div className='transparentLine'></div>
						</div>
					</div>
				}
			</div>
		)
	};
	componentDidMount() {
		let res = GetParams(this.props.history.location.search, "id");
		if (res) {
			this.setState({
				id: res,
			}, () => {
				this.requestForSignUpInfo();
			})
		}
		let params = { userId: this.props.userInfo.userId,title:this.props.shareGongZhongHao.title, clientType: this.props.clientType }
		document.addEventListener('WeixinJSBridgeReady', getApi.doShareWap(params), false);
	}
	requestForSignUpInfo() {
		let userInfo = this.props.userInfo;
		getApi.doSignUpInfo({
			id: this.state.id,
			token: userInfo.token,
			userId: userInfo.userId,
		}).then((res) => {
			if (res.success) {
				res.module.interviewList.forEach(item => {
					item.keyVal = JSON.parse(item.keyVal);
				});
				this.setState({
					signUp: res.module,
					loading: true,
				})
			}
		}).catch((res) => {
			console.log();
		}).finally((res) => {
		})
	};
	componentWillMount() {
		let loading = document.getElementById('i-loading');
		if (loading) {
				loading.style.display = 'none'
		}
	}
}

const mapState = (state) => ({
	userInfo: state.login.userInfo,
	shareGongZhongHao:state.login.shareGongZhongHao,
	clientType: state.login.clientType,
});

const mapDispatch = (dispatch) => ({
	setMarginParams(value) {
		dispatch({
			type: constants.SET_MARGIN_PARAMS,
			payload: value
		})
	}
});

export default connect(mapState, mapDispatch)(SignUpInfo);