import * as constants from './constants';

export default (state = constants.defaultState, { type, payload }) => {
	// console.log("0000==>" + JSON.stringify(sessionStorage.getItem("persist:root")))
	// if (sessionStorage.getItem("persist:root") && sessionStorage.getItem("persist:root")!=null && sessionStorage.getItem("persist:root")!="null") {
	// 	// newState = JSON.parse(JSON.parse(sessionStorage.getItem("persist:root")).login);

	// 	sessionStorage.getItem("persist:root").then((res)=>{
	// 		console.log(res);
	// 	});
	// }
	let newState = JSON.parse(JSON.stringify(state));
	newState.dictionaryList = state.dictionaryList;


	switch (type) {
		case constants.NAME: {
			newState.name = payload;
			break;
		}
		// 没有resume了，信息都存储在userInfo中了
		case constants.RESUME: {
			// const newState = JSON.parse(JSON.stringify(state));
			newState.userInfo = { ...newState.userInfo, ...payload };
			return newState;
		}
		case constants.SET_SETTING: {
			newState.shareGongZhongHao = {
				title: payload.shareAppTitle,
				imgUrl: payload.shareAppImage,
			};
			newState.shareFromPosition = {
				title: payload.sharePositionTitle,
			};
			newState.shareModule = {
				title: payload.shareModuleTitle,
				imgUrl: payload.shareModuleImage,
			}
			newState.rankSetting = {
				webUrl: payload.webUrl,
				imageUrl: payload.imageUrl,
			}
			// console.log("SET_SETTING", JSON.stringify(newState));
			break;
		}
		case constants.CLIENT_TYPE: {
			newState.clientType = payload.clientType;
			break;
		}
		case constants.USERINFO: {
			newState.userInfo = { ...newState.userInfo, ...payload };;
			if (payload.resume) {
				newState.resume = payload.resume;
			}
			break;
		}
		case constants.PERSONAL: {
			newState.personal = payload;
			if (payload.resume) {
				newState.resume = payload.resume;
			}
			break;
		}
		case constants.CITY: {
			newState.city = payload.id;
			newState.location = payload.translate;
			break;
		}
		case constants.SEARCHNAME: {
			newState.searchNameList = payload;
			break;
		}
		case constants.DICTIONARYLIST: {
			newState.dictionaryList = payload;
			break;
		}
		case constants.WORKEXPECT: {
			newState.workExpect = payload;
			break;
		}
		case constants.EXPECTPOSITION: {
			newState.expectPosition = payload;
			break;
		}
		case constants.GPSLIMIT: {
			newState.gpsLimit = payload;
			break;
		}
		case 'persist/REHYDRATE': {
			if (payload) {
				var incoming = payload.login; // Carts is the name of the reducer
				if (incoming) {
					newState = { ...newState, ...incoming };
				}
			}
			break;
		}

		// 分享相关
		case constants.POSITION: {
			newState.position = payload;
			break;
		}
		case constants.SET_MARGIN_PARAMS: {
			newState.marginParams = payload;
			break;
		}
		case constants.CLEAR: {
			newState = {
				name: "hhhhh",
				resume: {
				},
				userInfo: {
					token: null,
					userId: null,
				},
				personal: {

				},
				city: 892,
				location: '南京市',
				searchNameList: [],
				expectPosition: [],
				dictionaryList: [],
				workExpect: {},
				gpsLimit: {},
				marginParams: {},
				shareGongZhongHao: {
					title: '做兼职赚外快,众多高薪兼职等你来挑~',
					imgUrl: 'https://cdn.huizhuanvip.com/jianzhi/app/images/icon_share_fenm.png',
				},
				shareFromPosition: {
					title: '快来帮我加薪！高薪兼职还能继续加薪，太赚了~',
					imgUrl: 'https://cdn.huizhuanvip.com/jianzhi/app/images/icon_shar_fenx.png',
				},
				// 邀请新人奖励单价
				sharePromotionSalary: 10,
				// 职位推广奖励单价
				shareInviteSalary: 5,
				clientType: 7,
			};
			break;
		}
		default:
			return newState;
	}
	// console.log('newState'+JSON.stringify(newState));
	return newState;
}