import React, { Component, Suspense } from 'react';

import { HashRouter as Router, Route } from 'react-router-dom';
// import { createBrowserHistory } from 'history';
import {createBrowserHistory} from 'history'


import StartPage from './pages/start/index';
import LoginPage from './pages/login/index';
import IndexPage from './pages/index/index';
import SignUpListPage from './pages/signUpList/index';
import SignUpInfoPage from './pages/signUpList/signUpInfo/index';
import PositionDetailsPage from './pages/index/positionDetails/index';
import SharePrizePage from './pages/signUpList/sharePrize/index';
import PersonalPage from './pages/personal/index';
import BaseInformationPage from './pages/personal/baseInformation/index';
import PayMarginPage from './pages/index/payMargin/index';
import PayMarginSuccessPage from './pages/index/payMarginSuccess/index';
import FeedbackPage from './pages/personal/feedback/index';
import ReportPostPage from './pages/personal/reportpost/index';
import SharedWapPage from './pages/signUpList/sharedWap/index';
import SharePositionPage from './pages/signUpList/sharePosition/index';
import SharedPositionPage from './pages/signUpList/sharedPosition/index';
import SharedPositionSuccessPage from './pages/signUpList/sharedPositionSuccess/index';
import SharedInfoPage from './pages/signUpList/sharedInfo/index';
import AuthenticationPage from './pages/personal/authentication/index';
import MyWalletPage from './pages/personal/myWallet/index';
import TakeCashPage from './pages/personal/myWallet/takeCash/index';
import TakeCashSuccessPage from './pages/personal/myWallet/takeCashSuccess/index';
import PrivacyPolicyPage from './pages/personal/privacyPolicy/index';
import WorkExpectPage from './pages/personal/workExpect/index';
import ModuleJobPage from './pages/index/moduleJob/index';
import SharedModuleJobPage from './pages/index/sharedModuleJob/index';
import ChangeAccountPage from './pages/personal/setting/changeAccount/index';


const browserHistory = createBrowserHistory();

require('./assets/css/m_base.css');
class App extends Component {
    render() {
        
        return (
            <Suspense fallback={<div></div>}>
            {/* <React.Fragment> */}
                <Router history={browserHistory}>
                    <React.Fragment>
             {/* <Router history={browserHistory}> */}
                    <Route path='/' exact component={StartPage}></Route>
                    <Route path='/start' exact component={StartPage}></Route>
                    <Route path='/login' exact component={LoginPage}></Route>
                    <Route path='/index' exact component={IndexPage}></Route>
                    <Route path='/signUpList' exact component={SignUpListPage}></Route>
                    <Route path='/signUpInfo' exact component={SignUpInfoPage}></Route>
                    <Route path='/positionDetails' exact component={PositionDetailsPage}></Route>
                    <Route path='/sharePrize' exact component={SharePrizePage}></Route>
                    <Route path='/personal' exact component={PersonalPage}></Route>
                    <Route path='/baseInformation' exact component={BaseInformationPage}></Route>
                    <Route path='/payMargin' exact component={PayMarginPage}></Route>
                    <Route path='/payMarginSuccess' exact component={PayMarginSuccessPage}></Route>
                    <Route path='/feedback' exact component={FeedbackPage}></Route>
                    <Route path='/reportpost' exact component={ReportPostPage}></Route>
                    <Route path='/sharedWap' exact component={SharedWapPage}></Route>
                    <Route path='/sharePosition' exact component={SharePositionPage}></Route>
                    <Route path='/sharedPosition' exact component={SharedPositionPage}></Route>
                    <Route path='/sharedPositionSuccess' exact component={SharedPositionSuccessPage}></Route>
                    <Route path='/sharedInfo' exact component={SharedInfoPage}></Route>
                    <Route path='/authentication' exact component={AuthenticationPage}></Route>
                    <Route path='/myWallet' exact component={MyWalletPage}></Route>
                    <Route path='/takeCash' exact component={TakeCashPage}></Route>
                    <Route path='/takeCashSuccess' exact component={TakeCashSuccessPage}></Route>
                    <Route path='/privacyPolicy' exact component={PrivacyPolicyPage}></Route>
                    <Route path='/workExpect' exact component={WorkExpectPage}></Route>
                    <Route path='/moduleJob' exact component={ModuleJobPage}></Route>
                    <Route path='/sharedModuleJob' exact component={SharedModuleJobPage}></Route>
                    <Route path='/changeAccount' exact component={ChangeAccountPage}></Route>
                    <Route path='/rankList' exact component={asyncComponent(() => load('./pages/web/index'))}></Route>
        {/* </Router> */}
                     </React.Fragment>
                 </Router>
            {/* </React.Fragment> */}
            </Suspense>
        );
    }

}
// 异步按需加载component
function asyncComponent(getComponent) {
    return class AsyncComponent extends React.Component {
        static Component = null;
        state = { Component: AsyncComponent.Component };

        componentDidMount() {
            if (!this.state.Component) {
                getComponent().then(({ default: Component }) => {
                    AsyncComponent.Component = Component
                    this.setState({ Component })
                })
            }
        }
        //组件将被卸载
        componentWillUnmount() {
            //重写组件的setState方法，直接返回空
            this.setState = (state, callback) => {
                return;
            };
        }
        render() {
            const { Component } = this.state
            if (Component) {
                return <Component {...this.props} />
            }
            return null
        }
    }
}

function load(component) {
    return import(`${component}`)
}
export default App;
