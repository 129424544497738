import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Picker } from 'antd-mobile';

class FilterPicker extends Component {
    constructor(props) {
        super(props);
        this.state = {
            attribute: this.props.attribute || 'jobType',
            district: this.props.array || [],
            translate: this.props.translate || null,
            disValue: null,
            placeholder: this.props.placeholder || null,
            className: this.props.className || null,
            placeholderClass: this.props.placeholderClass || null,
            theme: this.props.theme || 'form',
        }
    }
    componentDidMount() {
    }

    render() {
        const CustomChildren = props => (
            <div>
                {theme === "index" &&
                    <div
                        onClick={props.onClick}
                    >
                        <div className="item">
                            {props.extra === '请选择' ? (props.translate || props.placeholder) : props.extra}
                            <img className="bor" src={require('../../assets/img/icon_sy_xl.png')} alt=""/>
                        </div>
                    </div>
                }
                {theme === "wallet" &&
                    <div
                        onClick={props.onClick}
                    >
                        <div className="item">
                            {props.extra === '请选择' ? (props.translate || props.placeholder) : props.extra}
                            <i className="arrow"></i></div>
                    </div>
                }
                 {theme === "work" &&
                    <div
                        onClick={props.onClick}
                        className={props.placeholderClass}
                    >
                        {props.extra === '请选择' ? (props.translate || props.placeholder) : props.extra}
                    </div>
                }
            </div>

        );
        let { district, disValue, translate, placeholder, placeholderClass, theme } = this.state;
        return (
            <div>
                <Picker
                    data={district}
                    cols={1}
                    // className={className || "forss"}
                    dismissText={"取消"}
                    okText={"确认"}
                    title={"请选择"}
                    value={disValue}
                    extra={translate || placeholder}
                    onChange={this.change.bind(this)}>
                    <CustomChildren theme={theme} placeholder={placeholder} placeholderClass={placeholderClass} translate={translate} />
                    {/* <List.Item arrow="horizontal" className={className}>{placeholder}</List.Item> */}
                </Picker>
            </div>
        )
    };
    change(value) {
        this.setState({ disValue: value });
        this.props.onChange(value[0]);
    }
}
const mapState = (state) => ({
})

const mapDispatch = (dispatch) => ({
});

export default connect(mapState, mapDispatch)(FilterPicker);