// 保存按钮
export const BUTTON_PRIMARY = {
    backgroundColor: 'rgba(4,112,245,1)',
    color:'#fff',
    marginTop:60,
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
};
export const BUTTON_SECOND = {
    backgroundColor: 'rgba(4,112,245,1)',
    color:'#fff',
};
export const BUTTON_SIGNUP = {
    backgroundColor: 'rgb(4, 112, 245)',
    color: 'rgb(255, 255, 255)',
    /* margin-top: 60px; */
    height: '100%',
    width: '2.6rem',
    lineHeight: '1.2rem',
    textAlign: 'center',
    fontSize: '0.3rem',
    borderRadius:0,
};
export const BUTTON_SIGNUPED = {
    backgroundColor: 'rgba(242, 248, 254,1)',
    color: 'rgb(4, 112, 245)',
    /* margin-top: 60px; */
    height: '100%',
    width: '2.6rem',
    lineHeight: '1.2rem',
    textAlign: 'center',
    fontSize: '0.3rem',
    borderRadius:0,
    borderLeft:'0.02rem solid rgb(4, 112, 245)',
    borderTop:'0.02rem solid rgb(4, 112, 245)',
};
export const BUTTON_MARGIN = {
    backgroundColor: '#0470f5',
    color: '#fff',
    /* margin-top: 60px; */
    height: '1.3333rem',
    lineHeight: '1.3333rem',
    textAlign: 'center',
    fontSize: '0.32rem',
    borderRadius:0,
    fontWeight:'bold',
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
};


