import React, { Component } from 'react';
import { connect } from 'react-redux';
import TopBar from '../../../components/top-bar/TopBar';
import Api from '../../../utils/Api';
import MyCarousel from '../../../components/myCarousel/myCarousel';
import ShareModal from '../../../components/share-modal/shareModal';
import LazyLoad from 'react-lazyload';
const getApi = Api.getInstance();
class SharePrize extends Component {

    constructor(props) {
        super(props);
        this.state = {
            shareInfoResponse: {},
            recommendList: [],
            maxRecommendList: [{
                photo: "https://cdn.huizhuanvip.com/jianzhi/api/image/1578109492593.jpg",
                userName: "秋天的风",
                mobile: "157****5634",
                num: 10,
                reward: 10 * 15,
            }, {
                photo: "https://cdn.huizhuanvip.com/jianzhi/api/image/1575435477434.jpg",
                userName: "动次打次",
                mobile: "157****8987",
                num: 10,
                reward: 7 * 15 + 3 * 5,
            }, {
                photo: "https://cdn.huizhuanvip.com/jianzhi/api/image/1574477681553.jpg",
                userName: "穿裤子的小发糕",
                mobile: "138****8687",
                num: 10,
                reward: 7 * 15 + 3 * 5,
            }, {
                photo: "https://cdn.huizhuanvip.com/jianzhi/api/image/1578469445176.jpeg",
                userName: "天地三才",
                mobile: "156****3249",
                num: 8,
                reward: 4 * 15 + 4 * 5,
            }, {
                photo: "https://cdn.huizhuanvip.com/jianzhi/api/image/1578109492593.jpg",
                userName: "烣歰季节",
                mobile: "146****3297",
                num: 7,
                reward: 4 * 15 + 3 * 5,
            }, {
                photo: "https://wx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTLCQdkiajcFO1aJYZMLzS1iaicjiadyOyZq3XWia5QlChNkcPHicib8MHJDFYAjkhmJuJKGicfL9chbuajBTw/132",
                userName: "阳",
                mobile: "165****2549",
                num: 5,
                reward: 5 * 15,
            }],
            loading: false,
            shareImgFlag: false,
            shareModalFlag: false,
            sharePosterFlag: false,
        }
    }

    componentDidMount() {
        this.requestForSharePosition();
        let params = { userId: this.props.userInfo.userId, title: this.props.shareGongZhongHao.title, clientType: this.props.clientType }
        document.addEventListener('WeixinJSBridgeReady', getApi.doShareWap(params), false);
    }
    render() {
        let { shareInfoResponse, recommendList, maxRecommendList, loading, shareImgFlag, shareModalFlag, sharePosterFlag, posterUrl } = this.state;
        let { rankSetting } = this.props;
        return (
            <div className="bg-eee yq-wrap">
                {loading && <div>
                    <TopBar title={"分享有奖"}></TopBar>
                    <div className="head">
                        <LazyLoad>
                            <img src="https://cdn.huizhuanvip.com/jianzhi/app/images/yq_top.png" alt="" />
                        </LazyLoad>
                    </div>
                    <div className="item-fff head-info">
                        <div className="t">分享奖励</div>
                        <div className="info">
                            {shareInfoResponse.agentFlag &&
                                <div className="item" onClick={() => {
                                    this.props.history.push(
                                        '/SharedInfo?status=0',
                                    )
                                }}>
                                    <div className="num">{shareInfoResponse.invitationNum}<div className="img"></div></div>
                                    <div className="mt">邀请人数(人)</div>
                                </div>
                            }
                            <div className="item" onClick={() => {
                                this.props.history.push(
                                    '/sharedInfo?status=1',
                                )
                            }}>
                                <div className="num">{shareInfoResponse.promotionNum}<div className="img"></div></div>
                                <div className="mt">推广次数(次)</div>
                            </div>
                            <div className="item" onClick={() => {
                                this.props.history.push(
                                    '/sharedInfo?status=2',
                                )
                            }}>
                                <div className="num">{shareInfoResponse.promotionReward}<div className="img"></div></div>
                                <div className="mt">推广奖励(元)</div>
                            </div>
                            {shareInfoResponse.agentFlag &&
                                <div className="item" onClick={() => {
                                    this.props.history.push(
                                        '/SharedInfo?status=3',
                                    )
                                }}>
                                    <div className="num">{shareInfoResponse.totalCredit}<div className="img"></div></div>
                                    <div className="mt">我的积分</div>
                                </div>
                            }
                        </div>
                    </div>
                    {rankSetting.imageUrl && rankSetting.webUrl &&
                        <img src={rankSetting.imageUrl} alt="" className="rules-img" onClick={() => { window.location = rankSetting.webUrl }} />
                    }
                    <div className="item-fff rules">
                        {/* <div className="t"><span>***</span><strong>玩法一</strong><span>***</span></div>
                        <div className="tittle"> <span>邀请新人</span>
                            <div className="btn" onClick={this.showShareImg.bind(this)}>马上邀请</div>
                        </div>

                        <div className="img">
                            <LazyLoad>
                                <img src="https://cdn.huizhuanvip.com/jianzhi/app/images/yq_rules.png" alt=""/>
                            </LazyLoad>
                        </div>
                        <div className="items">
                            <div className="item">通过分享<br />邀请新人</div>
                            <div className="item">新人通过<br />链接注册</div>
                            <div className="item">新人报名<br />兼职上岗</div>
                            <div className="item">邀请成功<br />获得奖励</div>
                        </div> */}
                        <div className="t"><span>***</span><strong>赚钱方式</strong><span>***</span></div>
                        <div className="tittle"> <span>推广职位</span>
                            <div className="btn1" onClick={() => {
                                this.props.history.push(
                                    '/index',
                                )
                            }}>立即推广</div>
                        </div>

                        <div className="img">
                            <LazyLoad>
                                <img src="https://cdn.huizhuanvip.com/jianzhi/app/images/yq_zwtg.png" alt="" />
                            </LazyLoad>
                        </div>
                        <div className="items">
                            <div className="item">把职位推<br />广给好友</div>
                            <div className="item">好友通过链接<br />第一次报名上岗</div>
                            <div className="item">推广成功<br />获得奖励</div>
                        </div>
                        <div className="img">
                            <LazyLoad>
                                <img src="https://cdn.huizhuanvip.com/jianzhi/app/images/icon_baner1.png" alt="" />
                            </LazyLoad>
                        </div>
                        <div className="rule-cont">
                            <h6>分享有奖规则</h6>
                            <p>1.分职位推广即分享平台职位给好友，好友通过您分享的职位链接报名并是第一次上岗获得工资后，您将得到<span>2-5</span>元/位现金奖励，不同职位可重复获得奖励；</p>
                            <p>2.推广职位可获得加薪的机会，若有<span>1</span>名以上的好友报名您分享的职位并获得工资，您自己去上岗该职位时可获得加薪，不同职位的加薪标准不同；</p>
                            <p>3.职位推广奖励和加薪奖励可以叠加享受，若好友通过您分享的职位链接报名且TA是第一次上岗该职位，您在获得推广奖励的同时获得该职位的加薪奖励；</p>
                            <p>4.活动解释权归平台所有，请遵守平台《用户协议》和相关推广规则有效推广，平台有权监督推广的有效性和真实性，对异常推广采取相关处罚。</p>
                        </div>
                        <div className="btns"><a className="btn" onClick={this.showShareImg.bind(this)} href="javacript:void(0);">快来邀请好友吧</a></div>
                    </div>


                    <MyCarousel maxRecommendList={maxRecommendList} tag={"tip"}></MyCarousel>

                    <div className="yq-table">
                        <h6>邀请记录</h6>
                        <table><tbody>
                            {recommendList && recommendList.map((recommend, index) => {
                                return (<React.Fragment key={index}>
                                    <tr>
                                        <td>
                                            <div className="phone">{recommend.userName || recommend.mobile}</div>
                                        </td>
                                        <td>
                                            <div className="time">{recommend.positionName || ''}</div>
                                        </td>
                                        <td>
                                            <div className="status">{recommend.rewardFlag === 1 ? '已' : '未'}上岗</div>
                                        </td>
                                    </tr>
                                </React.Fragment>
                                )
                            })
                            }
                        </tbody></table>
                        <div className="more">
                            {(recommendList && recommendList.length > 0) &&
                                <div onClick={() => {
                                    this.props.history.push(
                                        '/sharedInfo?status=0',
                                    )
                                }}>查看更多</div>
                            }
                            {(!recommendList || recommendList.length === 0) && <a href="javacript:void(0);">您还没有邀请到其他人</a>}
                        </div>
                    </div>
                    <div className="foot">- 南京高海拔网络科技有限公司 -</div>
                </div>
                }
                {shareImgFlag && <div className="popbg" onClick={this.hideShareImg.bind(this)}>
                    <LazyLoad>
                        <img src="https://cdn.huizhuanvip.com/jianzhi/app/images/share.png" className="share-img" alt="" />
                    </LazyLoad>
                </div>}
                {shareModalFlag &&
                    <ShareModal modal={shareModalFlag} name="chooseModal" onClose={() => { this.setState({ shareModalFlag: false }) }} onShareAppMessage={this.onShareAppMessage.bind(this)} showPosterModal={this.showPosterModal.bind(this)}></ShareModal>
                }
                {sharePosterFlag &&
                    <ShareModal modal={sharePosterFlag} name="gzhPosterModal" onClose={() => { this.setState({ sharePosterFlag: false }) }} onClosePoster={this.onClosePoster.bind(this)} posterUrl={posterUrl}></ShareModal>
                }
            </div>
        )
    }
    onShareAppMessage() {
        this.setState({
            shareImgFlag: !this.state.shareImgFlag,
        });
    }
    showPosterModal() {
        this.setState({
            shareModalFlag: !this.state.shareModalFlag,
            sharePosterFlag: !this.state.sharePosterFlag,
        })
    }
    onClosePoster() {
        this.setState({
            sharePosterFlag: !this.state.sharePosterFlag,
        })
    }
    requestForSharePosition() {
        let userInfo = this.props.userInfo;
        if (!userInfo.token || !userInfo.userId) {
            return;
        }
        let loading = document.getElementById('i-loading');
        loading.style.display = 'inline';
        getApi.getShareInfo({
            token: userInfo.token,
            userId: userInfo.userId,
        }).then((res) => {
            if (res.success) {
                this.setState({
                    shareInfoResponse: res.module,
                    recommendList: res.module.recommendList,
                    loading: true,
                    posterUrl: getApi.doServiceUrl() + "/api/share/getModulePoster.action?token=" + userInfo.token + "&userId=" + userInfo.userId + "&clientType=8",
                })
                // posterUrl: getApi.doUrl() + "https://merchant.level-job.com/api/share/getGzhPoster.action?token="+userInfo.token+"&userId="+userInfo.userId+"&clienType="+this.props.clientType,
            }
        }).catch((res) => {
            console.log(res);
        }).finally((res) => {
            loading.style.display = 'none';
        });
    }

    showShareImg() {
        /**this.setState({
            shareImgFlag: true
        });*/
        this.setState({
            shareModalFlag: true,
        })
    }
    hideShareImg() {
        this.setState({
            shareImgFlag: false
        });
    }
}

const mapState = (state) => ({
    userInfo: state.login.userInfo,
    shareGongZhongHao: state.login.shareGongZhongHao,
    clientType: state.login.clientType,
    rankSetting: state.login.rankSetting
})

const mapDispatch = (dispatch) => ({
})


export default connect(mapState, mapDispatch)(SharePrize);