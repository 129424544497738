import React, { Component } from 'react';
import { connect } from 'react-redux';
import SinglePicker from '../../../components/single-picker/singlePicker';
import MyImagePicker from '../../../components/image-picker/myImagePicker';
import { constants } from '../../login/store';
import { Button, Toast } from 'antd-mobile';
import { BUTTON_PRIMARY } from '../../../assets/style/common';
import Validate from '../../../utils/Validate';
import Api from '../../../utils/Api';
import TopBar from '../../../components/top-bar/TopBar';
import LazyLoad from 'react-lazyload';
const getApi = Api.getInstance();

class Authentication extends Component {

    constructor(props) {
        super(props);
        this.state = {
            authentication: {},
            loading: false,
            loadingState: false,
            loadingChange: false,
        }
        this.validate = this.initValidate();
    }

    render() {
        let { authentication, loading, loadingChange, loadingState } = this.state;
        const { userInfo } = this.props;

        return (
            <div className="bg-fff">
                <TopBar title={"实名认证"}></TopBar>

                {(loadingState && !loadingChange) &&
                    <div className="real-name-status">
                        {userInfo.authenticateStatus === 1 &&
                            <div className="state-info" >
                                <div className="ico">
                                <LazyLoad>
                                    <img src={require('../../../assets/img/icon_shenhe.png')} className="ico-img" alt="" />
                                </LazyLoad>
                                </div>
                                <div className="txt">审核中...</div>
                                <div className="tips">审核将在1~3个工作日内完成，请耐性等待</div>
                            </div>
                        }
                        {userInfo.authenticateStatus === 2 &&
                            <div className="state-info" >
                                <div className="ico">
                                <LazyLoad>
                                    <img src={require('../../../assets/img/icon_tongguo.png')} className="ico-img" alt="" />
                                </LazyLoad>
                                </div>
                                <div className="txt">已通过</div>
                                <div className="tips">您的实名认证已通过</div>
                                <div className="btns" bindtap='clickToAuthentication'>
                                    <a className="btn" onClick={() => { this.setState({ loadingChange: true }) }} href="javacript:void(0);">查看</a>
                                </div>
                            </div>
                        }
                        {userInfo.authenticateStatus === -1 &&
                            <div className="state-info">
                                <div className="ico">
                                <LazyLoad>
                                    <img src={require('../../../assets/img/icon_bohui.png')} className="ico-img" alt="" />
                                </LazyLoad>
                                </div>
                                <div className="txt">被驳回</div>
                                <div className="tips text-left">
                                    <div>您的认证被驳回，原因如下：</div>
                                    <div>根据您提供的材料信息与您填写的不一致</div>
                                </div>
                                <div className="btns">
                                    <a className="btn" onClick={() => { this.setState({ loadingChange: true }) }} href="javacript:void(0);">修改认证信息</a>
                                </div>
                            </div>
                        }
                    </div>
                }
                {(userInfo.authenticateStatus === 0 || (userInfo.authenticateStatus === 2 && loadingChange) || (userInfo.authenticateStatus === -1 && loadingChange)) &&
                    <div className="form-wrap">
                        <div className="item">
                            <div className="mt">姓名<span>*</span></div>
                            <div className="mc input-arrow">
                                <div className="input"><input type="text" className="ipt" maxLength={20} placeholder="请输入姓名" value={authentication.certificateName || ""} onChange={(res) => { authentication.certificateName = res.target.value; this.setState({ authentication: authentication }) }} /></div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="mt">证件类型<span>*</span></div>
                            <div className="mc">
                                <SinglePicker theme={"form"} attribute={"certificateType"} placeholderClass={"picker"} placeholder={"请选择证件类型"} translate={authentication.certificateTypeName} onChange={(res) => { authentication.certificateType = res; this.setState({ authentication: authentication }) }}></SinglePicker>
                            </div>
                        </div>
                        <div className="item">
                            <div className="mt">证件号码<span>*</span></div>
                            <div className="mc input-arrow">
                                <div className="input"><input type="text" value={authentication.certificateNumber || ""} className="ipt" placeholder="请输入证件号码" onChange={(res) => { authentication.certificateNumber = res.target.value; this.setState({ authentication: authentication }) }} /></div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="mt">身份证照片（正面）<span>*</span></div>
                            <div className="mc1">
                                <div className="upload-items">
                                    <MyImagePicker className="upload" url={authentication.certificatePhotoFront} changeImage={(url) => { authentication.certificatePhotoFront = url; this.setState({ authentication: authentication }) }}></MyImagePicker>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="mt">身份证照片（反面）<span>*</span></div>
                            <div className="mc1">
                                <div className="upload-items">
                                    <MyImagePicker className="upload" url={authentication.certificatePhotoBack} changeImage={(url) => { authentication.certificatePhotoBack = url; this.setState({ authentication: authentication }) }}></MyImagePicker>
                                </div>
                            </div>
                        </div>
                        <div className="file-tip">
                            <p>1.上传的身份证照片需清晰可见；</p>
                            <p>2.审核工作一般在1-3个工作日完成；</p>
                        </div>

                        {/* <div className="btns">
                        <a href="" className="btn">保存</a>
                    </div> */}
                        {((userInfo.authenticateStatus === -1 || userInfo.authenticateStatus === 0 || !userInfo.authenticateStatus) && userInfo.authenticateStatus !== 2 && userInfo.authenticateStatus !== 1) &&
                            <Button loading={loading} style={BUTTON_PRIMARY} onClick={this.submitData.bind(this)}>保存</Button>
                        }
                    </div>
                }
            </div>
        )
    };
    componentDidMount() {
        let userInfo = this.props.userInfo;
        getApi.doAuthenticationInfo({
                token: userInfo.token,
                userId: userInfo.userId,
            })
            .then((res) => {
                if (res.success) {
                    if (res.module) {
                        this.props.setResume(res.module);
                        this.setState({
                            authentication: res.module,
                        });
                    }
                }
            }).catch((reason) => {
                console.log(reason);
            }).finally((res) => {
                this.setState({
                    loadingState: true
                })
            });
            // 分享公众号
            let params = { userId: this.props.userInfo.userId,title:this.props.shareGongZhongHao.title, clientType: this.props.clientType }
            document.addEventListener('WeixinJSBridgeReady', getApi.doShareWap(params), false);
    };
    submitData() {
        let userInfo = this.props.userInfo;
        const params = this.state.authentication;
        params.token = userInfo.token;
        params.userId = userInfo.userId;
        params.authenticateTime = null;
        params.submitTime = null;
        if (!this.validate.checkForm(params)) {
            const error = this.validate.errorList[0];
            Toast.fail(error.msg, 1);
            return false;
        }

        this.setState({
            loading: true,
        }, () => {
            getApi.doUserAuthentication(params).then((res) => {
                if (res.success) {
                    userInfo.authenticateStatus = res.module.authenticateStatus;
                    userInfo.name = res.module.certificateName;
                    this.props.setResume(userInfo);
                    Toast.success('保存成功', 1, () => {
                        this.props.history.goBack();
                    });
                }
            }).catch((reason) => {
                console.log(reason);
            }).finally((res) => {
                this.setState({
                    loading: false,
                });
            });
        });
    };
    initValidate() {
        const rules = {
            certificateName: {
                required: true,
                maxLength: 20,
            },
            certificateType: {
                required: true,
            },
            certificateNumber: {
                required: true,
                idcard: true,
            },
            certificatePhotoFront: {
                required: true,
            },
            certificatePhotoBack: {
                required: true
            },
        }

        const messages = {
            certificateName: {
                required: '请输入您的姓名',
                maxLength: '姓名20字以内'
            },
            certificateType: {
                required: '请选择证件类型',
            },
            certificateNumber: {
                required: '请输入证件号码',
                idcard: '身份证需18位',
            },
            certificatePhotoFront: {
                required: '请选择证件照片',
            },
            certificatePhotoBack: {
                required: '请选择证件照片',
            },
        }

        return new Validate(rules, messages);
    };
    componentWillMount() {
        let loading = document.getElementById('i-loading');
        if (loading) {
            setTimeout(() => {
                loading.style.display = 'none'
            }, 1000)
        }
    }
}

const mapState = (state) => ({
    resume: state.login.resume,
    userInfo: state.login.userInfo,
    shareGongZhongHao:state.login.shareGongZhongHao,
    clientType: state.login.clientType,
})

const mapDispatch = (dispatch) => ({
    setResume(item) {
        dispatch({
            type: constants.RESUME,
            payload: item
        })
    },
})

export default connect(mapState, mapDispatch)(Authentication);