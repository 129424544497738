import React, { Component } from 'react';
import { connect } from 'react-redux';
import TopBar from '../../../components/top-bar/TopBar';
import Api from '../../../utils/Api';
const getApi = Api.getInstance();

class PayMarginSuccess extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
        let params = { userId: this.props.userInfo.userId,title:this.props.shareGongZhongHao.title, clientType: this.props.clientType }
        document.addEventListener('WeixinJSBridgeReady', getApi.doShareWap(params), false);
    }

    render() {
        return (
            <div className="bg-fff">
                <TopBar title={"支付成功"}></TopBar>

                <div className="tixian">
                    <div className="status-info">
                        <div className="infos">
                            <div className="ico"></div>
                            <div className="txt">
                                <div className="h">支付成功</div>
                            </div>
                        </div>
                        <div className="btns">
                            <a onClick={() => { this.props.history.goBack(); }} className="btn btn1" href="javacript:void(0);">查看详情</a>
                        </div>

                    </div>

                </div>


            </div>
        )
    }
    componentWillMount() {
        let loading = document.getElementById('i-loading');
        if (loading) {
            loading.style.display = 'none'
        }
    }

}

const mapState = (state) => ({
    userInfo: state.login.userInfo,
    shareGongZhongHao:state.login.shareGongZhongHao,
    clientType: state.login.clientType,
})

const mapDispatch = (dispatch) => ({
});

export default connect(mapState, mapDispatch)(PayMarginSuccess);